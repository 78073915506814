import React, { useEffect, useState } from "react";
import { Autocomplete, Chip, FormControl, Grid, TextField } from "@mui/material";
import { useLocationContext } from "../../LocationContext";
import RichTextArea from "components/RichTextArea";
import useTranslation from "hooks/useTranslation";
import { TabPaneProps } from "views/DataCuration/ProviderProfile/types";

const OtherInfoSection: React.FC<TabPaneProps> = ({
  shouldDisableForm,
  onDisableEditButton,
}: TabPaneProps) => {
  const {
    web: {
      dataGovernance: {
        location: {
          generalInfo: {
            otherInfo: { alertMessageLabel, synonymsLabel },
          },
        },
      },
    },
  } = useTranslation();

  const {
    locationData: {
      generalInfo: { otherInfo },
    },
    currentPayload,
    setCurrentPayload,
  } = useLocationContext();

  const { shortDescription, alertMessage, synonyms } = otherInfo;

  const [formData, setFormData] = useState({
    shortDescription: shortDescription ?? "",
    alertMessage: alertMessage ?? "",
    synonyms: synonyms || [],
  });

  const handleChange = (key: string, newValue: any) => {
    setFormData({
      ...formData,
      [key]: newValue,
    });

    if (newValue !== otherInfo[key]) {
      setCurrentPayload((prevPayload) => ({
        ...prevPayload,
        generalInfo: {
          ...(prevPayload?.generalInfo || {}),
          otherInfo: {
            ...(prevPayload?.generalInfo?.otherInfo || {}),
            [key]: newValue,
          },
        },
      }));
    }
  };

  const handleChangeSynonyms = (newValue: any[]) => {
    const removedItems = formData.synonyms.filter((item) => !newValue.includes(item));
    const addedItems = newValue.filter((item) => !formData.synonyms.includes(item));

    const removedSynonyms = removedItems.map((item) => ({
      name: item,
      delete: true,
    }));
    const addedSynonyms = addedItems.map((item) => ({
      name: item,
      delete: false,
    }));

    const updatedSynonyms = [
      ...(currentPayload?.generalInfo?.otherInfo?.synonyms || []),
      ...addedSynonyms,
      ...removedSynonyms,
    ];

    setFormData((prevFormData) => ({
      ...prevFormData,
      synonyms: newValue,
    }));

    setCurrentPayload((prevPayload) => ({
      ...prevPayload,
      generalInfo: {
        ...(prevPayload?.generalInfo || {}),
        otherInfo: {
          ...(prevPayload?.generalInfo?.otherInfo || {}),
          synonyms: updatedSynonyms,
        },
      },
    }));
  };

  const updateFormData = () => {
    setFormData({
      shortDescription: shortDescription ?? "",
      alertMessage: alertMessage ?? "",
      synonyms: synonyms || [],
    });
  };

  useEffect(() => {
    if (currentPayload === null) {
      updateFormData();
    }
  }, [currentPayload]);

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justifyContent="center"
      data-testid="OtherInfoSection"
      padding="16px"
    >
      <Grid item xs={12} md={12} style={{ position: "relative" }}>
        <FormControl variant="outlined" fullWidth data-testid="short-description-field">
          <RichTextArea
            initialValue={formData.shortDescription}
            onChange={(content) => handleChange("shortDescription", content)}
            placeHolder="Short Description"
            readOnly={shouldDisableForm}
            onDisableEditButton={onDisableEditButton}
            showCharacterCount
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <Autocomplete
          multiple
          data-testid="synonyms-autocomplete-field"
          disabled={shouldDisableForm}
          options={[]}
          freeSolo
          forcePopupIcon
          value={formData.synonyms || []}
          onChange={(event, newValue) => handleChangeSynonyms(newValue)}
          renderTags={(value: readonly any[], getTagProps) =>
            value.map((option: any, index: number) => {
              const { key, ...tagProps } = getTagProps({ index });
              return <Chip variant="filled" label={option} key={key} {...tagProps} />;
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={synonymsLabel}
              placeholder="Add Location Synonyms"
              helperText={synonymsLabel}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            error={false}
            id="alertMessage"
            name="alertMessage"
            size="medium"
            value={formData?.alertMessage}
            variant="outlined"
            label={alertMessageLabel}
            onChange={(e) => handleChange("alertMessage", e.target.value)}
            disabled={shouldDisableForm}
            helperText={false}
            data-cy="Practice-alertMessageInput"
            inputProps={{
              "data-testid": "Practice-alertMessageInput",
            }}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default OtherInfoSection;
