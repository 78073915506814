import React, { Fragment } from "react";
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";
import { Button } from "@mui/material";
import useStyles from "./Numpad.styles";

export enum EType {
  phone = "phone",
  dialPad = "dialPad",
}
interface Props {
  keypadKeys?: Array<{ symbol: string; letters?: string }>;
  type?: EType;
  cellPhone: string;
  setCellPhone?: (digit: string) => void;
  handleButtonClick?: (digit: string) => void;
}

const defaultKeypadKeys = [
  { symbol: "1" },
  { symbol: "2", letters: "abc" },
  { symbol: "3", letters: "def" },
  { symbol: "4", letters: "ghi" },
  { symbol: "5", letters: "jkl" },
  { symbol: "6", letters: "mno" },
  { symbol: "7", letters: "pqrs" },
  { symbol: "8", letters: "tuv" },
  { symbol: "9", letters: "wxyz" },
  { symbol: "" },
  { symbol: "0" },
  { symbol: "deleteKey" },
];

const DELETE_KEY = "<-";

const ButtonWrapper = ({ value, letters, click, disabled }) => {
  const { classes, cx } = useStyles();

  return (
    <Button
      onClick={() => click(value)}
      disabled={value !== DELETE_KEY && disabled}
      className={cx(classes.button, classes.borderStyles)}
    >
      <div className={classes.buttonTextWrap}>
        <span>{value}</span>
        <span className={classes.subText}>{letters ?? ""}</span>
      </div>
    </Button>
  );
};

export const formatValue = (number = "") => {
  return (
    (number.length > 2 ? "(" : "") +
    number.slice(0, 3) +
    (number.length > 2 ? ") " : "") +
    number.slice(3, 6) +
    (number.length > 5 ? "-" : "") +
    number.slice(6, 10)
  );
};

const Numpad: React.FC<Props> = (props) => {
  const { classes, cx } = useStyles();
  const {
    cellPhone,
    setCellPhone,
    keypadKeys = defaultKeypadKeys,
    type = EType.phone,
    handleButtonClick,
  } = props;

  const onButtonClick = (value: string) => {
    if (value === DELETE_KEY) {
      // @ts-expect-error
      setCellPhone((prevValue) => prevValue?.slice(0, -1));
    } else {
      // @ts-expect-error
      setCellPhone((prevValue) => prevValue + value);
    }
    if (typeof handleButtonClick === "function") {
      handleButtonClick(value);
    }
  };

  return (
    <div
      className={cx({
        [classes.wrapper]: true,
      })}
    >
      <div className={classes.panelWrapper}>
        {keypadKeys.map(({ symbol, letters }) => (
          <Fragment key={`button-${symbol}`}>
            {symbol === "deleteKey" ? (
              <button
                type="button"
                className={classes.backspaceIconBtn}
                onClick={() => onButtonClick(DELETE_KEY)}
                key={`button-${symbol}`}
                data-testid="delete-key"
                aria-label="Delete"
              >
                <BackspaceOutlinedIcon className={classes.backspaceIcon} />
              </button>
            ) : (
              <ButtonWrapper
                click={onButtonClick}
                value={symbol}
                letters={letters}
                disabled={type === EType.phone && cellPhone?.length === 10}
              />
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default Numpad;
