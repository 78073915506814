import { makeStyles } from "tss-react/mui";

export const waitingUsersTableStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
    margin: "auto",
    width: "100%",
    backgroundColor: theme.palette.common.white,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      height: `calc(100% - ${theme.headerHeight})`,
    },
  },
  selectedText: {
    margin: 0,
    fontSize: "16px",
  },
  indeterminateCheckbox: {
    "& svg": {
      color: theme.palette.primary.main,
    },
  },

  profileTitle: {
    margin: 0,
    marginBottom: "24px",
    lineHeight: "100%",
    fontSize: "34px",
    textAlign: "left",
    paddingLeft: 16,
  },
  statusCircle: {
    display: "inline-block",
    marginLeft: 5,
    marginRight: 5,
    marginBottom: -2,
    borderRadius: "50%",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: theme.palette.common.black,
    height: 20,
    width: 20,
    backgroundColor: "green",
  },
  queueContainer: {
    flexGrow: 1,
    overflowY: "scroll",
    borderTop: "solid 1px rgba(0, 0, 0, 0.12)",

    [theme.breakpoints.down("md")]: {
      overflowY: "auto",
    },
  },
  removeBorder: {
    borderTop: "none",
  },
  filtersContainer: {
    display: "flex",
    boxSizing: "border-box",
    borderTop: "solid 1px rgba(0, 0, 0, 0.12)",
    padding: "8px",
    gap: "8px",
    alignItems: "center",
  },
  filterContainer: {
    padding: "5px 10px 0px 10px",
    boxSizing: "border-box",
    flex: "1 1 0",
    width: "100%",
    "&:first-child": {
      borderRight: "solid 1px rgba(0, 0, 0, 0.12)",
    },
  },
  label: {
    fontSize: "12px",
    color: "rgba(0, 0, 0, 0.38)",
    transform: "scale(1)",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  formControl: {
    width: "100%",
  },
  arrow: {
    color: "rgba(0, 0, 0, 0.4)",
  },
  topQueueButtons: {
    height: "36px",
    padding: "0",
    textTransform: "uppercase",
  },
  topButtonsWrapper: {
    display: "grid",

    gap: "20px",
    boxSizing: "border-box",
    padding: "30px 10px 20px 10px",
    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "auto",
    },
    [theme.breakpoints.down("lg")]: {
      gridTemplateColumns: "auto auto",
    },
  },
  singleButton: {
    gridTemplateColumns: "auto",
    [theme.breakpoints.down("lg")]: {
      gridTemplateColumns: "auto",
    },
  },
  multipleButtons: {
    gridTemplateColumns: "auto auto",
    [theme.breakpoints.down("lg")]: {
      gridTemplateColumns: "auto auto",
    },
  },
  overrideContainer: {
    borderTop: "solid 1px rgba(0, 0, 0, 0.12)",
    borderBottom: "solid 1px rgba(0, 0, 0, 0.12)",
    padding: "0px 10px",
  },
  newSessionBtn: {
    boxShadow:
      "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
  },
  circularContainer: {
    padding: "60px",
  },
}));

export const usePatientProfileStyles = makeStyles()((theme) => ({
  profileStatusContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  statusCircle: {
    display: "flex",
    justifyContent: "flex-start",
    width: 10,
    height: 10,

    marginRight: 5,
    borderRadius: "50%",
  },
  online: {
    backgroundColor: "#13ae47",
  },
  offline: {
    backgroundColor: theme.palette.grey[600],
  },
  patientDemographics: {
    padding: "1.5rem 1rem",
    marginRight: "auto",
    display: "flex",
    flexDirection: "column",
    maxHeight: `calc(100vh - ${theme.headerHeight})`,
    width: "100%",
    overflowY: "auto",
    position: "relative",
    zIndex: 0,
    [theme.breakpoints.down("md")]: {
      height: "auto",
      overflowY: "auto",
    },
    "& h5": {
      marginBottom: 6,
    },
  },
  fontSizeSmall: {
    [theme.breakpoints.down("md")]: {
      fontSize: "36px",
    },
  },
  optionsContainer: {
    gap: "10px",
    marginBottom: "20px",
    marginTop: "10px",
  },
  select: {
    maxWidth: "250px",
    minWidth: "160px",
    background: theme.palette.common.white,
    margin: 0,
    textAlign: "left",
    flexShrink: 1,

    [theme.breakpoints.down("md")]: {
      maxWidth: "unset",
    },
  },
  selectInput: {
    padding: "13.6px",
  },
  selectLabel: {
    marginTop: "3px",
  },
  profileTitle: {
    margin: 0,
    marginBottom: "24px",
    lineHeight: "100%",
    fontSize: "34px",
    textAlign: "left",
  },
  selectVisitTitle: {
    fontSize: "34px",
    lineHeight: "42px",
    marginTop: "63px",
    marginBottom: "33px",
    textAlign: "center",
    width: "100%",
  },
  ctaContainer: {
    width: "auto",
    gap: "10px",
    marginLeft: "auto",

    [theme.breakpoints.down("md")]: {
      alignItems: "flex-end",
      marginRight: "10px",
      position: "fixed",
      flexDirection: "column",
      bottom: 80,
      right: 0,
      zIndex: 1,
    },
  },
  cta: {
    height: 46,
    width: 105,
    borderRadius: 5,
    color: theme.palette.common.white,
    fontSize: "12px",
    padding: "0 8px",

    [theme.breakpoints.down("md")]: {
      width: 50,
      minWidth: 50,
      maxWidth: 50,
      height: 50,
    },
  },
  closeBtn: {
    backgroundColor: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.error.main,
    },
  },
  transferBtn: {
    backgroundColor: theme.palette.primary.light,
    border: `1px solid ${theme.palette.primary.light}`,
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.light,
    },
  },
  createEncounterBtn: {
    width: "105px",
    lineHeight: "110%",
    backgroundColor: theme.palette.secondary.light,
    border: `1px solid ${theme.palette.secondary.light}`,
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.secondary.light,
    },
  },
  launchVideoBtn: {
    backgroundColor: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.secondary.main,
    },
  },
  chatIconBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary.light,
    border: `1px solid ${theme.palette.primary.light}`,
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.light,
    },
  },
  launchVideoBtnDisabled: {
    backgroundColor: "#828282 !important",
    color: `${theme.palette.common.white} !important`,
    border: "1px solid #828282 !important",
  },
  buttonLabel: {
    fontSize: 12,
    lineHeight: "12px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

// useDrawerStyles was here, but unused anywhere else, and caused a build error.
