import React from "react";
import { Chip } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<{ color: string; bgColor: string }>()((_theme, props) => ({
  chip: {
    color: props.color,
    backgroundColor: props.bgColor,

    marginRight: "6px",
    marginBottom: "7px",
    padding: "0px 8px",
    height: "26px",
    borderRadius: "200px",
    fontSize: "13px",
  },
}));

/* eslint-disable react/jsx-no-useless-fragment */
const Tag = ({ label, bgColor, color, icon = <></> }) => {
  const { classes } = useStyles({ bgColor, color });

  return <Chip className={classes.chip} size="small" label={label} icon={icon} />;
};

export default Tag;
