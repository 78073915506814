import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useProviderContext } from "../../ProviderContext";
import { specialtiesShowCallButtonDefaultNo } from "./Rules/SpecialtiesShowCallButtonDefaultNo";
import useTranslation from "hooks/useTranslation";
import { ProviderGeneralInfoAndLocation, TabPaneProps } from "../../types";

const SchedulingInformationSection: React.FC<TabPaneProps> = ({
  shouldDisableForm,
}: TabPaneProps) => {
  const {
    web: {
      dataGovernance: { providerProfile },
    },
  } = useTranslation();

  const {
    providerGeneralInfoAndLocation: { schedulingInformation },
    providerClinical: {
      clinical: { specialties },
    },
    currentPayload,
    setCurrentPayload,
  } = useProviderContext();

  const {
    generalInformation: {
      schedulingInformation: {
        onlineSchProvider,
        onlinebookUrlNewPatient,
        onlinebookUrlExistingPatient,
        acceptingNewPatients,
        showCTAButton,
        ageGroupSeen,
      },
    },
  } = providerProfile;

  const [formData, setFormData] = useState({
    ...schedulingInformation,
    ageGroupsSeen: schedulingInformation.ageGroupsSeen || [],
  });

  const handleChange = (key: string, e: any) => {
    const newValue = e?.target?.value !== undefined ? e.target.value : e;

    setFormData({
      ...formData,
      [key]: newValue,
    });

    if (newValue !== schedulingInformation[key]) {
      setCurrentPayload((prev: ProviderGeneralInfoAndLocation) => ({
        ...(prev || {}),
        [key]: newValue,
      }));
    }
  };

  const shouldDefaultLocationSpecialtiesToNo = () => {
    const specialtyNamesAndAliases = specialties.flatMap((specialty) => {
      const aliases = specialty.specialty.aliases || [];
      return [specialty.specialty.name, ...aliases];
    });

    return specialtyNamesAndAliases.some((specialty) =>
      specialtiesShowCallButtonDefaultNo.includes(specialty),
    )
      ? "false"
      : "true";
  };

  const handleAgeGroupChange = (ageGroup: string) => {
    const newAgeGroupsSeen = formData.ageGroupsSeen.includes(ageGroup)
      ? formData.ageGroupsSeen.filter((group) => group !== ageGroup)
      : [...formData.ageGroupsSeen, ageGroup];

    handleChange("ageGroupsSeen", newAgeGroupsSeen);
  };

  useEffect(() => {
    if (currentPayload === null) {
      setFormData({
        ...schedulingInformation,
        ageGroupsSeen: schedulingInformation.ageGroupsSeen || [],
      });
    }
  }, [currentPayload]);

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justifyContent="center"
      data-testid="scheduling-information-section"
      style={{ padding: "24px 0 32px 0", width: "100%" }}
    >
      <Grid item xs={12} md={12}>
        <FormControl variant="outlined" fullWidth>
          <Typography color="primary" align="left" style={{ fontWeight: 700, fontSize: "18px" }}>
            {onlineSchProvider}
          </Typography>
          <RadioGroup
            row
            aria-label="onlineSchedulingProvider"
            name="onlineSchedulingProvider"
            value={formData?.onlineBooking ?? schedulingInformation.onlineBooking}
            onChange={(e) => handleChange("onlineBooking", e)}
          >
            <FormControlLabel
              value
              key="yes"
              control={<Radio />}
              label="Yes"
              disabled={shouldDisableForm}
            />
            <FormControlLabel
              key="no"
              value={false}
              control={<Radio />}
              label="No"
              disabled={shouldDisableForm}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            error={false}
            id="onlBookNewPatient"
            name="onlBookNewPatient"
            size="medium"
            value={
              formData.onlineBookingUrlNewPatients ??
              schedulingInformation.onlineBookingUrlNewPatients ??
              ""
            }
            variant="outlined"
            label={onlinebookUrlNewPatient}
            onChange={(e) => handleChange("onlineBookingUrlNewPatients", e)}
            disabled={shouldDisableForm}
            helperText={false}
            data-cy="SchedulingInformation-OnlBookNewPatientInput"
            data-testid="SchedulingInformation-OnlBookNewPatientInput"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            error={false}
            id="onlBookExistingPatient"
            name="onlBookExistingPatient"
            size="medium"
            value={
              formData.onlineBookingUrlExistingPatients ??
              schedulingInformation.onlineBookingUrlExistingPatients ??
              ""
            }
            variant="outlined"
            label={onlinebookUrlExistingPatient}
            onChange={(e) => handleChange("onlineBookingUrlExistingPatients", e)}
            disabled={shouldDisableForm}
            helperText={false}
            data-cy="SchedulingInformation-OnlBookExistingPatientInput"
            data-testid="SchedulingInformation-OnlBookExistingPatientInput"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12} marginTop="24px">
        <FormControl variant="outlined" fullWidth>
          <Typography align="left" color="primary" style={{ fontWeight: 700, fontSize: "18px" }}>
            {acceptingNewPatients}
          </Typography>
          <RadioGroup
            row
            aria-label="acceptingNewPatients"
            name="acceptingNewPatients"
            onChange={(e) => handleChange("acceptingNewPatients", e)}
            value={formData?.acceptingNewPatients ?? schedulingInformation.acceptingNewPatients}
          >
            <FormControlLabel
              value
              key="yes"
              control={<Radio />}
              label="Yes"
              disabled={shouldDisableForm}
            />
            <FormControlLabel
              key="no"
              value={false}
              control={<Radio />}
              label="No"
              disabled={shouldDisableForm}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl variant="outlined" fullWidth>
          <Typography color="primary" align="left" style={{ fontWeight: 700, fontSize: "18px" }}>
            {showCTAButton}
          </Typography>
          <RadioGroup
            row
            aria-label="showCTAButton"
            name="showCTAButton"
            onChange={(e) => handleChange("showCTAButton", e)}
            value={formData.showCTAButton ?? shouldDefaultLocationSpecialtiesToNo()}
          >
            <FormControlLabel
              value="true"
              control={<Radio />}
              label="Yes"
              disabled={shouldDisableForm}
            />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="No"
              disabled={shouldDisableForm}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12} style={{ marginTop: "16px" }}>
        <div style={{ textAlign: "start" }}>
          <h5
            style={{
              fontFamily: "Alegreya sans",
              fontSize: "20px",
              fontWeight: 600,
              color: "#043070",
            }}
          >
            {ageGroupSeen.title}
          </h5>
        </div>
        <FormGroup
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: "0.5rem",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.ageGroupsSeen.includes("Pediatrics")}
                onChange={() => handleAgeGroupChange("Pediatrics")}
                name="Pediatrics"
                disabled={shouldDisableForm}
              />
            }
            label="Pediatrics"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.ageGroupsSeen.includes("Adults")}
                onChange={() => handleAgeGroupChange("Adults")}
                name="Adults"
                disabled={shouldDisableForm}
              />
            }
            label="Adults"
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
};

export default SchedulingInformationSection;
