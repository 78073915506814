import { useQuery } from "react-query";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { QUERY_KEYS } from "util/queryKeys";

interface CredentialedFacilitiesList {
  data: {
    data: [
      {
        id: number;
        name: string;
      },
    ];
    totalRecords: number;
  };
}

export const useGetCredentialedFacilities = (page_no = 0, page_size = 1000, search_text = "") => {
  const app = useApp();

  const {
    web: {
      common: { errorMsg },
    },
  } = useTranslation();

  const fetchQuery = (): Promise<CredentialedFacilitiesList> =>
    axiosClient.get(API_ENDPOINTS.dataGovernanceCredentialedFacilities, {
      params: { page_no, page_size, search_by: "name", search_text },
    });

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: [QUERY_KEYS.credentialedFacilities, page_no, search_text],
    queryFn: fetchQuery,
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: true,
    onError: () => app.addError(errorMsg),
  });

  const credentialedFacilitiesList = data?.data?.data;
  const totalRecords = data?.data?.totalRecords || 0;

  return {
    credentialedFacilitiesList,
    totalRecords,
    refetch,
    isLoading,
    isFetching,
  };
};
