import React, { useEffect, useState } from "react";
import { FormControl, Grid, TextField } from "@mui/material";
import { useLocationContext } from "../../LocationContext";
import CellPhone from "components/shared/CellPhone";
import useTranslation from "hooks/useTranslation";
import { TabPaneProps } from "views/DataCuration/ProviderProfile/types";

const ContactInfoSection: React.FC<TabPaneProps> = ({
  shouldDisableForm,
  onDisableEditButton,
}: TabPaneProps) => {
  const {
    web: {
      dataGovernance: {
        location: {
          generalInfo: {
            contactInfoSection: { phoneLabel, faxLabel },
          },
        },
      },
    },
  } = useTranslation();

  const {
    locationData: {
      generalInfo: {
        contactInfo,
        name: { nameFull },
        address: { streetAddress1, state, city, zip },
      },
    },
    currentPayload,
    setCurrentPayload,
  } = useLocationContext();

  const { phone, fax } = contactInfo;

  const [formData, setFormData] = useState({ phone, fax });
  const [phoneBlankError, setPhoneBlankError] = useState(false);

  const handlePhoneChange = (newPhone: string) => {
    if (onDisableEditButton) {
      onDisableEditButton(newPhone.length === 0);
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: newPhone,
    }));

    if (newPhone !== phone) {
      setCurrentPayload((prevPayload) => ({
        ...prevPayload,
        generalInfo: {
          contactInfo: {
            ...(prevPayload?.generalInfo?.contactInfo || {}),
            phone: newPhone,
          },
          address: {
            streetAddress1,
            state,
            city,
            zip,
          },
          name: {
            nameFull,
          },
        },
      }));
    }
  };

  useEffect(() => {
    setPhoneBlankError(formData.phone.length === 0);
  }, [formData.phone]);

  const handleChange = (key: string, e: any) => {
    const newValue = e?.target?.value;
    setFormData({
      ...formData,
      [key]: newValue,
    });

    if (newValue !== contactInfo[key]) {
      if (key === "fax") {
        setCurrentPayload((prevPayload) => ({
          ...prevPayload,
          generalInfo: {
            ...(prevPayload?.generalInfo || {}),
            contactInfo: {
              ...(prevPayload?.generalInfo?.contactInfo || {}),
              fax: newValue,
            },
          },
        }));
      }
    }
  };

  const updateFormData = () => {
    setFormData({
      phone,
      fax,
    });
  };

  useEffect(() => {
    if (currentPayload === null) {
      updateFormData();
    }
  }, [currentPayload]);

  return (
    <Grid
      container
      spacing={2}
      alignItems="baseline"
      justifyContent="center"
      data-testid="ContactInfoSection"
      padding="24px 16px 32px 16px"
    >
      <Grid item xs={12} md={6}>
        <CellPhone
          cellPhone={formData.phone}
          setCellPhone={handlePhoneChange}
          label={phoneLabel}
          disabled={shouldDisableForm}
          error={phoneBlankError}
          helperText={phoneBlankError ? "Phone number is required" : ""}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            error={false}
            id="fax"
            name="fax"
            size="medium"
            value={formData.fax ?? fax}
            variant="outlined"
            label={faxLabel}
            onChange={(e) => handleChange("fax", e)}
            disabled={shouldDisableForm}
            helperText={false}
            data-cy="ContactInfoSection-FaxInput"
            data-testid="ContactInfoSection-FaxInput"
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default ContactInfoSection;
