import React, { useEffect, useState } from "react";
import { FormControl, Grid, TextField } from "@mui/material";
import { useProviderContext } from "../../ProviderContext";
import useTranslation from "hooks/useTranslation";
import { ProviderGeneralInfoAndLocation, TabPaneProps } from "../../types";

const AdditionalInformationSection: React.FC<TabPaneProps> = ({
  shouldDisableForm,
}: TabPaneProps) => {
  const {
    web: {
      dataGovernance: {
        providerProfile: {
          notesAndAdditionalInfo: { additionalInformationLabels, echoProviderIdLabel, epicIdLabel },
        },
      },
    },
  } = useTranslation();

  const {
    providerNotes: { additionalInformation },
    currentPayload,
    setCurrentPayload,
  } = useProviderContext();

  const [formData, setFormData] = useState(additionalInformation);

  const handleChange = (
    key: string,
    e: React.ChangeEvent<HTMLInputElement | { value: unknown }>,
  ) => {
    const newValue = e?.target?.value;
    setFormData({
      ...formData,
      [key]: newValue,
    });

    if (newValue !== additionalInformation[key]) {
      setCurrentPayload((prev: ProviderGeneralInfoAndLocation) => ({
        ...(prev || {}),
        [key]: newValue,
      }));
    }
  };

  const echoProviderId = additionalInformation.externalIds.filter(
    (item) => item.system === "ECHO",
  )[0]?.externalId;
  const epicId = additionalInformation.externalIds.filter((item) => item.system === "EPIC")[0]
    ?.externalId;

  useEffect(() => {
    if (currentPayload === null) {
      setFormData(additionalInformation);
    }
  }, [currentPayload]);

  return (
    <Grid
      container
      spacing={2}
      alignItems="flex-start"
      justifyContent="flex-start"
      paddingY="16px"
      data-testid="additional-info-section"
    >
      <Grid item xs={12} md={12}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            error={false}
            size="medium"
            value={epicId || ""}
            variant="outlined"
            label={epicIdLabel}
            onChange={(e: any) => handleChange("epicId", e)}
            disabled={shouldDisableForm}
            InputProps={{
              readOnly: true,
            }}
            helperText={false}
            data-testid="AdditionalInformationSection-epicIdInput"
            style={{ marginBottom: "16px" }}
          />
        </FormControl>
        <FormControl variant="outlined" fullWidth>
          <TextField
            error={false}
            size="medium"
            value={echoProviderId || ""}
            variant="outlined"
            label={echoProviderIdLabel}
            onChange={(e: any) => handleChange("echoProvider", e)}
            disabled={shouldDisableForm}
            InputProps={{
              readOnly: true,
            }}
            helperText={false}
            data-testid="AdditionalInformationSection-echoProviderInput"
          />
        </FormControl>
      </Grid>
      {additionalInformationLabels.map(({ label, key }) => (
        <Grid item xs={12} md={12} key={key}>
          <FormControl variant="outlined" fullWidth>
            <TextField
              error={false}
              id={key}
              name={key}
              size="medium"
              value={formData[key] || ""}
              variant="outlined"
              label={label}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(key, e)}
              disabled={shouldDisableForm}
              helperText={false}
              data-cy={`AdditionalInformationSection-${key}Input`}
              data-testid={`AdditionalInformationSection-${key}Input`}
            />
          </FormControl>
        </Grid>
      ))}
    </Grid>
  );
};

export default AdditionalInformationSection;
