export const HOST_DOMAIN = {
  myatlantic: "myatlantic.atlantichealth.org",
  myatlanticTest: "myatlantic-test.atlantichealth.org",
  myatlanticDev: "myatlantic-dev.atlantichealth.org",
  app: "app.atlantichealth.org",
  appTest: "app-test.atlantichealth.org",
  appDev: "app-dev.atlantichealth.org",
  appLocalInt: "dev.app.int.ahsapp.org",
  localhost: "localhost",
};

export enum Host {
  APP = "app.",
  MYATLANTIC = "myatlantic.",
  APP_TEST = "app-test.",
  MYATLANTIC_TEST = "myatlantic-test.",
}

export enum Environment {
  PRODUCTION = "production",
  TEST = "Test Environment",
  TEST_ONLY = "Test Environment Only",
  DEV = "Development Environment",
  DEVPROD = "production&Dev Environment",
  TEST_INT = "test&Int Environment",
  INTEGRATION = "integration", // will be used for integration testing
  INT_ONLY = "Integration Environment Only",
  NOOP = "disabled in all envs",
  PRODUCTION_ONLY = "only production enviroment",
  CYPRESS = "cypress",
}

export const API_HOST_FUNCTION = () => {
  return `https://api.${window.location.host}`;
};

export const API_HOST = {
  myatlanticApi: "https://myatlantic-api.atlantichealth.org",
  myatlanticApiTest: "https://myatlantic-api-test.atlantichealth.org",
  myatlanticDevApi: "https://myatlantic-dev-api.atlantichealth.org",
  appApi: "https://app-api.atlantichealth.org",
  appApiTest: "https://app-api-test.atlantichealth.org",
  appApiDev: "https://app-api-dev.atlantichealth.org",
  appApiInt: "https://api.app.int.ahsapp.org",
  localhost: "http://localhost:5000",
  kyruusApi: "https://api.kyruus.com/",
};

// function return ("api." + window.location.host)

export enum Features {
  PHYSICAL_THERAPY = "PT Inquiry",
  IMMEDIATE_CARE = "Immediate Care",
  CLOSED_CAPTIONS = "Closed captions",
  MFA_ENABLED = "Enable MFA",
  ENABLE_MYAISSISTANT = "Enable My AIssistant",
  DOCTOR_TIME_SLOTS = "Doctor time slots",
  ENABLE_SPECIMEN_TRACKING = "Enable Specimen Tracking",
  ENABLE_SPECIMEN_TRACKING_TEXTRACT_ADAPTER = "Enable Specimen Tracking Textract Adapter",
  ENABLE_DIGITAL_IMPLANTS = "Enable Digital Implants",
  ENABLE_REFERRAL_EPIC = "Enable Referral Epic",
  ENABLE_CAPTCHA = "Enable ReCaptcha",
  FIND_A_DOCTOR = "Find a Doctor",
  ENABLE_DOT_FORM = "Enable DOT Form",
  DOT_ADD_APPOINTMENT = "Enable DOT Staff Add Appointment Button",
  DOT_DOWNLOAD_FORM = "Enable DOT Form PDF Download",
  ENABLE_DYNAMSOFT_BARCODE = "Enable Dynamsoft Barcode",
  ENABLE_AHS_LOCATIONS = "Enable AHS Locations",
  ENABLE_DATA_GOVERNANCE = "Data Governance",
  ENABLE_DATA_GOVERNANCE_JSON_EXPORT = "Data Governance JSON Export",
  ENABLE_LOGIN_FORM = "Enable Login Form",
}

export const releaseStages = {
  [Features.PHYSICAL_THERAPY]: Environment.PRODUCTION,
  [Features.IMMEDIATE_CARE]: Environment.INT_ONLY,
  [Features.CLOSED_CAPTIONS]: Environment.TEST,
  [Features.MFA_ENABLED]: Environment.PRODUCTION_ONLY,
  [Features.ENABLE_MYAISSISTANT]: Environment.TEST,
  [Features.DOCTOR_TIME_SLOTS]: Environment.TEST,
  [Features.ENABLE_SPECIMEN_TRACKING]: Environment.PRODUCTION,
  [Features.ENABLE_SPECIMEN_TRACKING_TEXTRACT_ADAPTER]: Environment.NOOP,
  [Features.ENABLE_REFERRAL_EPIC]: Environment.TEST,
  [Features.ENABLE_DIGITAL_IMPLANTS]: Environment.PRODUCTION,
  [Features.ENABLE_CAPTCHA]: Environment.PRODUCTION,
  [Features.FIND_A_DOCTOR]: Environment.TEST,
  [Features.ENABLE_DOT_FORM]: Environment.PRODUCTION,
  [Features.ENABLE_AHS_LOCATIONS]: Environment.TEST,
  [Features.DOT_ADD_APPOINTMENT]: Environment.TEST,
  [Features.DOT_DOWNLOAD_FORM]: Environment.PRODUCTION,
  [Features.ENABLE_DYNAMSOFT_BARCODE]: Environment.PRODUCTION,
  [Features.ENABLE_DATA_GOVERNANCE]: Environment.TEST_INT,
  [Features.ENABLE_DATA_GOVERNANCE_JSON_EXPORT]: Environment.TEST_INT,
  [Features.ENABLE_LOGIN_FORM]: Environment.PRODUCTION_ONLY,
};

/**
 * This function will return the environment based on the hostname
 * If the environment is not set in the environment variable, it will be determined by the hostname
 * Note: we will need to map the new environment called integration in the future, right now we dont need it
 * @returns {Environment} - The environment based on the hostname
 */
export const APP_ENVIRONMENT: Environment = (() => {
  const env = process.env.REACT_APP_DFD_ENV;

  if (env) {
    switch (env) {
      case "production":
        return Environment.PRODUCTION;
      case "test":
        return Environment.TEST;
      case "integration":
        return Environment.INTEGRATION;
      case "cypress":
        return Environment.CYPRESS;
      default:
        return Environment.DEV;
    }
  } else {
    const { hostname } = window.location;
    switch (true) {
      case hostname.startsWith(Host.APP) || hostname.startsWith(Host.MYATLANTIC):
        return Environment.PRODUCTION;
      case hostname.startsWith(Host.APP_TEST) || hostname.startsWith(Host.MYATLANTIC_TEST):
        return Environment.TEST;
      default:
        return Environment.DEV;
    }
  }
})();

export const epicUrl =
  APP_ENVIRONMENT === Environment.PRODUCTION
    ? "https://mychart.atlantichealth.org/MyChart"
    : "https://mychart-np.atlantichealth.org/MyChartTST";

export const virtualUrgentCareUrl =
  "https://www.atlantichealth.org/conditions-treatments/urgent-care/virtual-urgent-care.html";
