import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Link, useMediaQuery, useTheme } from "@mui/material";
import ImageCarousal from "../ImageCarousal";
import { AiDiagnoseModal } from "../VisitDetails/AiDiagnose";
import ConsultationScheduled from "./ConsultationScheduled";
import { Insurance, User, VirtualSession } from "components/Video/utils/types";
import { Features } from "util/constants/config";
import useTranslation from "hooks/useTranslation";
import { hasFeature } from "util/hasFeature";
import { useProgramType } from "util/programsHelpers";
import { getAddress, getLocalTime, getTimeByMs } from "util/util_functions";
import { useVisitDetails } from "../styles";

type RequestDetailsProps = {
  currentVirtualVisit: VirtualSession & { patient_info: User };
  profile: User & { virtual_session_info: VirtualSession } & {
    insurance_data: Insurance;
  } & { images_data: { name: string; url: string }[] };
  handleClose: () => void;
};

const RequestDetails: React.FC<RequestDetailsProps> = ({
  currentVirtualVisit,
  handleClose,
  profile,
}) => {
  const { classes, cx } = useVisitDetails();
  const [openChat, setOpenChat] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const enableMyAIssistant = hasFeature(Features.ENABLE_MYAISSISTANT);

  const {
    web: {
      requestDetails: {
        requestedLocation,
        requestedCallback,
        reasonForVisit,
        otherReasonForVisit,
        reasonNote,
        startOn,
        primaryCarePhysician,
        emergencyContactNameAndNumber,
        insurancePlan,
        insuranceMember,
        accidentRelated,
        workersCompensation,
        encounterId,
        firstConnectionDate,
        lastConnectionDate,
        inSessionTime,
        previousSurgery,
        currentTreatment,
        clinicalTrials,
        additionalInformation,
        patientImages,
      },
      common: { phoneNumber, email, location: locationTxt },
    },
  } = useTranslation();

  const [open, setOpen] = useState(false);

  const isConsultation = currentVirtualVisit?.type === "consultation";
  const isPastSession = location.pathname.includes("virtual-session-report");
  const hasEmergencyContact =
    currentVirtualVisit.emergency_contact_name || currentVirtualVisit.emergency_contact_phone;
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { isAdhoc } = useProgramType();
  const { patient_info: patientInfo } = currentVirtualVisit;

  const patientLocation = getAddress(
    patientInfo?.city,
    currentVirtualVisit?.visit_place || patientInfo?.state,
    patientInfo?.zip,
  );

  const renderImageButton = (image, i, imagesData, classes, setOpen) => {
    if (i < 4 || i === imagesData.length - 1) {
      return (
        <button
          type="button"
          className={classes.imageItem}
          onClick={() => setOpen(true)}
          key={image.name}
        >
          <img src={image.url} alt={image.name} />
        </button>
      );
    }
    return null;
  };

  return (
    <>
      {isConsultation && (
        <div className={classes.detItem}>
          <span className={classes.detTitle} data-testid="requestedCallback">
            {requestedCallback}:
          </span>
          {currentVirtualVisit?.requested_start_beg ? (
            <ConsultationScheduled
              classes={classes}
              startDate={currentVirtualVisit?.requested_start_beg || undefined}
              endDate={currentVirtualVisit?.requested_start_end || undefined}
              size="medium"
            />
          ) : (
            <>&mdash;</>
          )}
        </div>
      )}
      <div className={classes.detItem}>
        <span className={classes.detTitle}>{phoneNumber}:</span>
        <button
          type="button"
          style={{
            border: "none",
            margin: 0,
            padding: 0,
            height: "auto",
          }}
          onClick={() => {
            if (isPastSession && isAdhoc) {
              handleClose();
              navigate(
                `/console/adhoc-virtual?cell_phone=${
                  currentVirtualVisit?.callback_phone ||
                  currentVirtualVisit?.callback_cell_phone ||
                  patientInfo?.cell_phone
                }`,
              );
            }
          }}
          className={cx({
            [classes.detDescr]: true,
            [classes.cellPhone]: isPastSession,
          })}
        >
          {currentVirtualVisit?.callback_phone ||
            currentVirtualVisit?.callback_cell_phone ||
            patientInfo?.cell_phone || <>&mdash;</>}
        </button>
      </div>
      <div className={classes.detItem}>
        <span className={classes.detTitle}>{email}:</span>
        <span className={classes.detDescr}>
          {patientInfo?.email ? (
            <Link href={`mailto:${patientInfo?.email}`}>{patientInfo?.email}</Link>
          ) : (
            <>&mdash;</>
          )}
        </span>
      </div>
      <div className={classes.detItem}>
        <span className={classes.detTitle}>{locationTxt}:</span>
        <span className={classes.detDescr}>{patientLocation || <>&mdash;</>}</span>
      </div>
      <div className={classes.detItem}>
        <span className={classes.detTitle}>{requestedLocation}:</span>
        <span className={classes.detDescr}>
          {profile.virtual_session_info?.department?.toUpperCase() || <>&mdash;</>}
        </span>
      </div>
      <div className={classes.detItem}>
        <span className={classes.detTitle}>{reasonForVisit}:</span>
        <span className={classes.detDescr}>
          {currentVirtualVisit.patient_reason || <>&mdash;</>}
        </span>
      </div>
      {currentVirtualVisit.patient_other_reason && (
        <div className={classes.detItem}>
          <span className={classes.detTitle}>{otherReasonForVisit}:</span>
          <span className={classes.detDescr}>{currentVirtualVisit.patient_other_reason}</span>
        </div>
      )}
      {currentVirtualVisit.visit_details && (
        <div className={classes.detItem}>
          <span className={classes.detTitle}>{reasonNote}:</span>
          <span className={classes.detDescr}>{currentVirtualVisit.visit_details}</span>
        </div>
      )}
      {profile.virtual_session_info?.start_dttm && (
        <div className={classes.detItem}>
          <span className={classes.detTitle}>{startOn}:</span>
          <span className={classes.detDescr}>
            {getLocalTime(profile.virtual_session_info?.start_dttm)}
          </span>
        </div>
      )}
      <div className={classes.detItem}>
        <span className={classes.detTitle}>{primaryCarePhysician}:</span>
        <span className={classes.detDescr}>
          {profile.virtual_session_info?.pcp_name || <>&mdash;</>}
        </span>
      </div>
      {hasEmergencyContact && (
        <div className={classes.detItem}>
          <span className={classes.detTitle}>{emergencyContactNameAndNumber}:</span>
          <span className={classes.detDescr}>
            {`${currentVirtualVisit.emergency_contact_name || ""} | ${
              currentVirtualVisit.emergency_contact_phone || ""
            }`}
          </span>
        </div>
      )}
      <div className={classes.detItem}>
        <span className={classes.detTitle}>{insurancePlan}:</span>
        <span className={classes.detDescr}>
          {profile.insurance_data?.insurance_company_id ? (
            `${profile.insurance_data?.insurance_company_id}:
            ${profile.insurance_data?.insurance_company_name}`
          ) : (
            <>&mdash;</>
          )}
        </span>
      </div>
      <div className={classes.detItem}>
        <span className={classes.detTitle}>{insuranceMember}:</span>
        <span className={classes.detDescr}>
          {profile.insurance_data?.member_id_number || <>&mdash;</>}
        </span>
      </div>
      <div className={classes.detItem}>
        <span className={classes.detTitle}>{accidentRelated}:</span>
        <span className={classes.detDescr}>
          {profile.virtual_session_info?.accident_related === true ? "YES" : "NO"}
        </span>
      </div>
      <div className={classes.detItem}>
        <span className={classes.detTitle}>{workersCompensation}:</span>
        <span className={classes.detDescr}>
          {profile.virtual_session_info?.workers_compensation === true ? "YES" : "NO"}
        </span>
      </div>

      <div className={classes.detItem}>
        <span className={classes.detTitle}>{encounterId}:</span>
        <span className={classes.detDescr}>
          {currentVirtualVisit.external_emr_encounter_id || <>&mdash;</>}
        </span>
      </div>
      <div className={classes.detItem}>
        <span className={classes.detTitle}>{firstConnectionDate}:</span>
        <span className={classes.detDescr}>
          {getLocalTime(profile.virtual_session_info?.physician_first_connection_date) || (
            <>&mdash;</>
          )}
        </span>
      </div>
      <div className={classes.detItem}>
        <span className={classes.detTitle}>{lastConnectionDate}:</span>
        <span className={classes.detDescr}>
          {getLocalTime(profile.virtual_session_info?.physician_last_disconnection_date) || (
            <>&mdash;</>
          )}
        </span>
      </div>
      <div className={classes.detItem}>
        <span className={classes.detTitle}>{inSessionTime}:</span>
        <span className={classes.detDescr}>
          {profile.virtual_session_info?.physician_in_session_time ? (
            getTimeByMs(profile.virtual_session_info.physician_in_session_time * 1000)
          ) : (
            <>&mdash;</>
          )}
        </span>
      </div>
      {isConsultation && (
        <>
          <div className={classes.detItem}>
            <span className={classes.detTitle} data-testid="previousSurgery">
              {previousSurgery}:
            </span>
            <span className={classes.detDescr}>
              {profile.virtual_session_info?.surgery?.toUpperCase() || <>&mdash;</>}
            </span>
          </div>
          <div className={classes.detItem}>
            <span className={classes.detTitle} data-testid="currentTreatment">
              {currentTreatment}:
            </span>
            <span className={classes.detDescr}>
              {profile.virtual_session_info?.receiving_treatment?.toUpperCase() || <>&mdash;</>}
            </span>
          </div>
          <div className={classes.detItem}>
            <span className={classes.detTitle}>{clinicalTrials}:</span>
            <span className={classes.detDescr}>
              {profile.virtual_session_info?.clinical_trials?.toUpperCase() || <>&mdash;</>}
            </span>
          </div>
          <div className={classes.detItem}>
            <span className={classes.detTitle}>{additionalInformation}:</span>
            <span className={classes.detDescr}>
              {profile.virtual_session_info?.checkout_note || <>&mdash;</>}
            </span>
          </div>
        </>
      )}
      <div className={classes.detItem}>
        {profile?.images_data && (
          <ImageCarousal
            fullScreen={fullScreen}
            open={open}
            handleClose={() => setOpen(false)}
            images={profile.images_data}
          />
        )}
        <div>
          <span className={classes.detTitle}>{patientImages}: </span>
          <span className={classes.detDescr}>
            {!profile?.images_data?.length && "No Images found"}
          </span>
          <div>
            {profile.images_data &&
              profile.images_data?.length > 0 &&
              profile.images_data?.map((image, i) =>
                renderImageButton(image, i, profile.images_data, classes, setOpen),
              )}
          </div>
        </div>
        {enableMyAIssistant && (
          <div className={classes.detItem} style={{ paddingTop: "8px" }}>
            <span className={classes.detTitle}>
              <Button
                type="button"
                variant="contained"
                color="secondary"
                onClick={() => setOpenChat(true)}
              >
                Talk to AHS AIssistant
              </Button>
              {openChat && <AiDiagnoseModal onClose={() => setOpenChat(false)} />}
            </span>
            <span className={classes.detDescr} />
          </div>
        )}
      </div>
    </>
  );
};

export default RequestDetails;
