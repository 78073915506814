import React, { useEffect, useState } from "react";
import { Delete as DeleteIcon } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { useLocationContext } from "../../LocationContext";
import { add, format, parse } from "date-fns";
import { SuccessIcon } from "components/Video/icons/SuccessIcon";
import { API_ENDPOINTS } from "util/Api_Endpoints";
import * as u from "../../utils/adjustedHours";
import { useStyles } from "../../utils/styles";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";

const Hours = ({ disabled, hours, onChange, removeMe, shouldDisableDate, topBorder, validity }) => {
  const {
    adjustedHoursEffectiveStart,
    adjustStart,
    adjustEnd,
    adjustLunchStart,
    adjustLunchEnd,
    adjustedHoursMessaging,
  } = u.makeDatesFromStrings(hours);
  const [isValid, validityMsg] = validity;

  return (
    <Box
      data-testid="hours"
      data-uuid={hours.uuid}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        gap: 2,
        paddingTop: 2,
        borderTopWidth: "1px",
        borderTopStyle: "solid",
        borderTopColor: topBorder ? "ghostwhite" : "transparent",
      }}
    >
      <Box sx={{ display: "flex", gap: 1 }}>
        <DatePicker
          label="Date"
          minDate={new Date(2025, 1, 1)}
          disabled={disabled}
          value={adjustedHoursEffectiveStart}
          shouldDisableDate={shouldDisableDate}
          onChange={(next) => {
            if (!next) {
              onChange("adjustedHoursEffectiveStart", "");
            } else {
              onChange("adjustedHoursEffectiveStart", format(next, "yyyy-MM-dd"));
            }
          }}
          slotProps={{
            textField: { inputProps: { "data-testid": "adjustedHoursEffectiveStart" } },
          }}
        />
        <TimePicker
          label="Opening Time"
          disabled={disabled}
          value={adjustStart}
          minutesStep={5}
          onChange={(next) => {
            if (!next) {
              onChange("adjustStart", "");
            } else {
              onChange("adjustStart", format(next, "HH:mm"));
            }
          }}
          slotProps={{
            textField: { inputProps: { "data-testid": "adjustStart" } },
          }}
        />
        <TimePicker
          label="Closing Time"
          value={adjustEnd}
          disabled={disabled || !hours.adjustStart}
          minutesStep={5}
          onChange={(next) => {
            if (!next) {
              onChange("adjustEnd", "");
            } else {
              onChange("adjustEnd", format(next, "HH:mm"));
            }
          }}
          slotProps={{
            textField: { inputProps: { "data-testid": "adjustEnd" } },
          }}
        />
        <TimePicker
          data-testid="adjustLunchStart"
          label="Lunch Begins"
          value={adjustLunchStart}
          disabled={disabled || !hours.adjustStart || !hours.adjustEnd}
          minutesStep={5}
          onChange={(next) => {
            if (!next) {
              onChange("adjustLunchStart", "");
            } else {
              onChange("adjustLunchStart", format(next, "HH:mm"));
            }
          }}
          slotProps={{
            textField: { inputProps: { "data-testid": "adjustLunchStart" } },
          }}
        />
        <TimePicker
          data-testid="adjustLunchEnd"
          label="Lunch Ends"
          value={adjustLunchEnd}
          disabled={disabled || !hours.adjustStart || !hours.adjustEnd || !hours.adjustLunchStart}
          minutesStep={5}
          onChange={(next) => {
            if (!next) {
              onChange("adjustLunchEnd", "");
            } else {
              onChange("adjustLunchEnd", format(next, "HH:mm"));
            }
          }}
          slotProps={{
            textField: { inputProps: { "data-testid": "adjustLunchEnd" } },
          }}
        />
      </Box>
      <Box sx={{ display: "flex", gap: 1 }}>
        <TextField
          label="Messaging"
          onChange={(ev) => onChange("adjustedHoursMessaging", ev.target.value)}
          value={adjustedHoursMessaging}
          disabled={disabled}
        />
        <Box
          sx={{
            minWidth: "30%",
            maxWidth: "30%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {!disabled && (
            <Typography
              variant="body2"
              color={isValid ? "primary.main" : "warning.main"}
              data-testid="validityMessage"
              data-validity={isValid}
            >
              {validityMsg}
              {isValid && <SuccessIcon />}
            </Typography>
          )}
        </Box>
        <Button
          color="primary"
          endIcon={<DeleteIcon />}
          disabled={disabled}
          onClick={() => removeMe()}
          data-testid="removeButton"
          data-uuid={hours.uuid}
        >
          Remove
        </Button>
      </Box>
    </Box>
  );
};

const AdjustedHours = ({ disableEditButton, isEditing, locationId }) => {
  const { classes } = useStyles();
  const app = useApp();
  const {
    locationData: { hoursAndSchedule },
    setCurrentlyEditing,
    refetch,
  } = useLocationContext();
  const { adjustedHours = [] } = hoursAndSchedule;

  const [loading, setLoading] = useState(false);
  const [hours, setHours] = useState(u.makeHoursFromList(adjustedHours));
  const resetState = () => setHours(u.makeHoursFromList(adjustedHours));
  useEffect(() => {
    resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adjustedHours]);

  const selectedDates = Object.values(hours).map((h) => h.adjustedHoursEffectiveStart);
  const validationState = Object.fromEntries(
    Object.keys(hours).map((k) => [k, u.validateEntry(hours[k])]),
  );
  const hasInvalidEntries = Object.values(validationState).some(([valid, _msg]) => !valid);

  const updateHours = (uuid) => (key, time) => {
    setHours((prev) => ({
      ...prev,
      [uuid]: {
        ...prev[uuid],
        [key]: time,
      },
    }));
  };
  const addNewHoursEntry = () => {
    const newEntry = u.makeNewHoursEntry();
    setHours((prev) => ({
      ...prev,
      [newEntry.uuid!]: newEntry,
    }));
  };
  const removeHoursEntry = async (uuid) => {
    // if uuid exists in data from backend, send the DELETE
    if (adjustedHours.some(({ uuid: inBackend }) => uuid === inBackend)) {
      try {
        setLoading(true);
        await axiosClient.delete(
          `${API_ENDPOINTS.dataGovernanceLocations}/adjusted-hours/${locationId}`,
          {
            data: {
              adjustedHours: {
                uuid,
              },
            },
          },
        );
        await refetch();
      } catch (err) {
        app.addError("An error occurred while deleting this entry");
      } finally {
        setLoading(false);
      }
    } else {
      // just remove it from our list here
      setHours((prev) => ({
        ...prev,
        [uuid]: undefined,
      }));
    }
  };
  const saveAdjustedHours = async () => {
    try {
      setLoading(true);
      const adjustedHours = Object.values(hours).map((h) => ({
        ...h,
        adjustedHoursEffectiveEnd: format(
          add(parse(h.adjustedHoursEffectiveStart!, "yyyy-MM-dd", new Date()), { days: 1 }),
          "yyyy-MM-dd",
        ),
      }));
      await axiosClient.patch(
        `${API_ENDPOINTS.dataGovernanceLocations}/adjusted-hours/${locationId}`,
        {
          adjustedHours,
        },
      );
      await refetch();
      setCurrentlyEditing(null);
    } catch (err) {
      app.addError("An error occurred while updating the adjusted hours");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 2, position: "relative" }}>
      <Box className={classes.scheduleHeader} sx={{ gap: 1 }}>
        <Typography variant="h6">Adjusted Hours</Typography>
        <Box sx={{ display: "flex", gap: 1 }}>
          {isEditing ? (
            <>
              <Button
                onClick={() => {
                  resetState();
                  setCurrentlyEditing(null);
                }}
                variant="outlined"
                disabled={loading}
              >
                Cancel
              </Button>
              <Button
                onClick={() => saveAdjustedHours()}
                variant="contained"
                color="primary"
                disabled={hasInvalidEntries || loading}
              >
                Save
              </Button>
            </>
          ) : (
            <Button
              onClick={() => setCurrentlyEditing("AdjustedHours")}
              variant="outlined"
              disabled={disableEditButton}
            >
              Edit
            </Button>
          )}
        </Box>
      </Box>
      {Object.entries(hours).map(([id, hours], index) => (
        <Hours
          key={id}
          onChange={updateHours(id)}
          hours={hours}
          disabled={!isEditing}
          topBorder={index > 0}
          validity={validationState[id]}
          removeMe={() => removeHoursEntry(id)}
          shouldDisableDate={(date) => {
            const fd = format(date, "yyyy-MM-dd");
            // disable date if some other entry has it, excluding this entry
            return hours.adjustedHoursEffectiveStart !== fd && selectedDates.includes(fd);
          }}
        />
      ))}
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          onClick={() => addNewHoursEntry()}
          disabled={!isEditing}
          endIcon={<AddIcon />}
          data-testid="addAdjustedHoursEntry"
        >
          Add
        </Button>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          visibility: loading ? "visible" : "hidden",
          backgroundColor: "rgba(256, 256, 256, 0.3)",
        }}
      >
        <CircularProgress />
      </Box>
    </Box>
  );
};

export { AdjustedHours, Hours };
