import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import theme from "Theme";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as Sentry from "@sentry/react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import TwillioAppStateProvider from "./components/Video/state";
import { Environment, Host } from "util/constants/config";
import "./index.css";

function resolveSentryEnvironment(hostname) {
  const webAnalyticsEnv = process.env.REACT_APP_WEB_ANALYTICS_ENV;
  // checks if the web analytics environment is set and is a valid environment
  if (webAnalyticsEnv && Object.values(Environment).includes(webAnalyticsEnv)) {
    return process.env.REACT_APP_WEB_ANALYTICS_ENV;
  }
  switch (true) {
    case hostname.startsWith(Host.APP) || hostname.startsWith(Host.MYATLANTIC):
      return Environment.PRODUCTION;
    case hostname.startsWith(Host.APP_TEST) || hostname.startsWith(Host.MYATLANTIC_TEST):
      return Environment.TEST;
    default:
      return Environment.DEV;
  }
}
const sentryEnv = resolveSentryEnvironment(window.location.host);

if (sentryEnv === Environment.PRODUCTION) {
  Sentry.init({
    dsn: "https://7d611d8aa48447b9b0efbf318061ba5a@o1175150.ingest.sentry.io/6271802",
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        // Additional SDK configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
        maskAllInputs: true,
      }),
    ],
    environment: sentryEnv,
    tracesSampleRate: 0.5,
    release:
      process.env.REACT_APP_AHS_DFD_VERSION && process.env.REACT_APP_AHS_AUDIENCE
        ? `${sentryEnv}-${process.env.REACT_APP_AHS_AUDIENCE}-${process.env.REACT_APP_AHS_DFD_VERSION}`
        : sentryEnv,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const cache = createCache({ key: "css", prepend: true });
cache.compat = true;

ReactDOM.render(
  <React.StrictMode>
    <TwillioAppStateProvider>
      <StyledEngineProvider injectFirst>
        <CacheProvider value={cache}>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <App />
            </LocalizationProvider>
          </ThemeProvider>
        </CacheProvider>
      </StyledEngineProvider>
    </TwillioAppStateProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
