import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import TableCells from "./TableCells";
import TableHeader from "./TableHeader";
import TableToolbar from "./TableToolbar";
import { Features } from "util/constants";
import { getComparator, stableSort } from "./utils";
import { useStyles } from "./utils/styles";
import { Order, TableProps } from "./utils/types";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";
import { hasFeature } from "util/hasFeature";
import { exportCSV } from "util/util_functions";

const DataCurationTable: React.FC<TableProps> = (props: TableProps) => {
  const {
    rows,
    colDefs,
    dataUrl,
    linkTo,
    showLocationsFilter,
    id,
    showAddNewButton,
    allowSelection,
    defaultOrderBy = "",
    showExportCsvButton,
    cellWidth,
    newButtonUrl,
    enableToolbar = true,
    enableFooter = true,
    filterByStatus,
    setSelectedRow,
    forceReload = false,
    exportJsonInChunks = false,
    exportJsonUrl = null,
    exportJsonFilename = null,
  } = props;

  const app = useApp();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = Number(searchParams.get("page")) || 0;
  const sizeParam = Number(searchParams.get("size"));
  const orderParam = searchParams.get("order") as Order;
  const orderByParam = searchParams.get("orderBy");
  const searchByParam = searchParams.get("searchBy");
  const searchTextParam = searchParams.get("searchText");

  const initialValues = {
    searchBy: searchByParam || "",
    searchText: searchTextParam || "",
  };

  const [searchByText, setSearchByText] = useState({
    searchBy: initialValues.searchBy,
    searchText: initialValues.searchText,
  });

  const { classes } = useStyles();
  const [order, setOrder] = useState<Order>(orderParam || "asc");
  const [orderBy, setOrderBy] = useState(orderByParam || defaultOrderBy);
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(pageParam);
  const [rowsPerPage, setRowsPerPage] = React.useState(sizeParam || 25);
  const [rowsData, setRowsData] = useState<any>([]);
  const [dataCount, setDataCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);

  useEffect(() => {
    setSearchParams((searchParams) => {
      searchParams.set("searchBy", searchByText.searchBy);
      searchParams.set("searchText", searchByText.searchText);
      if (
        searchByText.searchBy === initialValues.searchBy &&
        searchByText.searchText === initialValues.searchText
      ) {
        return searchParams;
      }
      setPage(0);
      searchParams.set("page", "0");
      return searchParams;
    });
  }, [searchByText]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      let finalUrl = dataUrl;
      let filtersUsed = false;

      const needsReviewFilter =
        filterByStatus === "NEEDS_REVIEW" ? `&needsReview=true` : "&needsReview=false";

      if (searchByText.searchBy && searchByText.searchText) {
        filtersUsed = true;
        const searchQuery = `search_by=${encodeURIComponent(
          searchByText.searchBy,
        )}&search_text=${encodeURIComponent(searchByText.searchText)}`;
        finalUrl = `${dataUrl}?${searchQuery}`;
      }

      finalUrl = `${finalUrl}${
        filtersUsed ? "&" : "?"
      }page_no=${page}&page_size=${rowsPerPage}&order=${order}&order_by=${orderBy}${needsReviewFilter}`;

      localStorage.setItem(
        `${id}-pageParams`,
        `page=${page}&size=${rowsPerPage}&order=${order}&orderBy=${orderBy}&searchBy=${searchByText.searchBy}&searchText=${searchByText.searchText}${needsReviewFilter}`,
      );

      try {
        const { data } = await axiosClient.get(finalUrl);
        setRowsData(data?.data);
        setDataCount(data?.totalRecords);
      } catch (error) {
        console.error("Error fetching data:", error);
        setRowsData([]);
      } finally {
        setLoading(false);
      }
    };
    if (rows) {
      setRowsData(rows);
    } else {
      fetchData();
    }
  }, [dataUrl, rows, searchByText, page, rowsPerPage, order, orderBy, forceReload]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property) => {
    const isAsc = orderBy === property && order === "asc";

    setSearchParams((searchParams) => {
      searchParams.set("order", String(isAsc ? "desc" : "asc"));
      searchParams.set("orderBy", property);
      return searchParams;
    });

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rowsData.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setSearchParams((searchParams) => {
      searchParams.set("page", String(newPage));
      return searchParams;
    });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchParams((searchParams) => {
      searchParams.set("page", "0");
      searchParams.set("size", event.target.value);
      return searchParams;
    });
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleModalOpen = (selectedRow: any) => {
    const { nameFull, type, parentLocationName, address, phone, providers, id } = selectedRow;
    const rowSelected = {
      pageTitle: nameFull,
      type,
      parentLocationName,
      address,
      phone,
      providers,
      id,
    };
    if (setSelectedRow) {
      setSelectedRow([rowSelected]);
    }
  };

  const handleExportJson = useCallback(async () => {
    setLoadingExport(true);
    let jsonData;

    try {
      if (!exportJsonUrl) {
        return;
      }

      if (exportJsonInChunks) {
        const pageSize = 100;
        let res;
        let records = [];
        let pageNo = 0;
        do {
          res = await axiosClient.get(`${exportJsonUrl}?page_no=${pageNo}&page_size=${pageSize}`);
          records = records.concat(res.data.data);
        } while (res.data.totalRecords > ++pageNo * pageSize);
        jsonData = JSON.stringify(records);
      } else {
        const res = await axiosClient.get(exportJsonUrl, { responseType: "blob" });
        jsonData = res.data;
      }

      const url = window.URL.createObjectURL(new Blob([jsonData]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", exportJsonFilename || "export.zip");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch {
      app.addError("Error exporting data, please try again later.");
    } finally {
      setLoadingExport(false);
    }
  }, [exportJsonUrl, exportJsonFilename, exportJsonInChunks]);

  const enableExportJson = hasFeature(Features.ENABLE_DATA_GOVERNANCE_JSON_EXPORT);

  const emptyRows =
    Number(rowsPerPage) -
    Math.min(Number(rowsPerPage), (rowsData?.length || 0) - Number(page) * Number(rowsPerPage));

  return (
    <div data-testid={id}>
      <Paper className={classes.paper} elevation={0}>
        {enableToolbar && (
          <TableToolbar
            numSelected={selected.length}
            colDefs={colDefs}
            showLocationsFilter={showLocationsFilter}
            showAddNewButton={showAddNewButton}
            showExportCsvButton={showExportCsvButton}
            showExportJsonButton={enableExportJson && !!exportJsonUrl}
            handleExportJson={handleExportJson}
            loadingExport={loadingExport}
            setSearchParams={setSearchByText}
            exportToCSVChangeLog={() => exportCSV(rowsData, "change-log-data-report")}
            newButtonUrl={newButtonUrl}
            searchParams={searchByText}
          />
        )}
        <TableContainer
          style={{ height: rowsData.length > 10 ? "50vh" : "auto", marginTop: "16px" }}
        >
          {loading ? (
            <CircularProgress style={{ position: "relative", top: "10em" }} />
          ) : (
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
              data-testid="data-governance-table"
              stickyHeader
            >
              <TableHeader
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rowsData?.length}
                rows={rowsData}
                colDefs={colDefs}
                allowSelection={allowSelection}
              />
              <TableBody className={classes.tableBody}>
                {stableSort(rowsData, getComparator(order, orderBy)).map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableCells
                      key={row.id}
                      row={row}
                      colDefs={colDefs}
                      isItemSelected={isItemSelected}
                      labelId={labelId}
                      handleClick={handleClick}
                      linkTo={linkTo}
                      allowSelection={allowSelection}
                      cellWidth={cellWidth}
                      handleModalOpen={handleModalOpen}
                    />
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        {enableFooter && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={dataCount}
            rowsPerPage={Number(rowsPerPage)}
            page={Number(page)}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </div>
  );
};

export default DataCurationTable;
