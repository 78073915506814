import React, { useEffect, useState } from "react";
import { dataGovernanceStyle } from "./dataGovernanceStyles";
import PatientDetails from "components/WaitingList/PatientDetails/PatientDetails";
import WaitingUsersTable from "components/WaitingList/WaitingUserTable";
import { useGetAwaitingUsers } from "hooks/useGetPatientVS/useGetAwaitingUsers";

const GenericWaitingList = ({ baseUrl, urlParams }) => {
  const { classes } = dataGovernanceStyle();
  const [awaitingListData, setAwaitingListData] = useState([]);
  const [urlOptions, setUrlOptions] = useState(urlParams);
  const { data, isLoading, isFetching, refetch } = useGetAwaitingUsers({ baseUrl, urlOptions });

  useEffect(() => {
    if (data && !isLoading) {
      setAwaitingListData(data?.data?.data || []);
    }
  }, [data, isLoading]);

  useEffect(() => {
    setAwaitingListData([]);
    refetch();
  }, [urlOptions, refetch]);

  return (
    <div className={classes.physicianQueue} data-testid="genericWaitingList">
      <div className={classes.waitingRoomCont}>
        <WaitingUsersTable
          awaitingListData={awaitingListData}
          isLoading={isLoading || isFetching}
          urlOptions={urlOptions}
          setUrlOptions={setUrlOptions}
        />
      </div>
      <div className={classes.smScreenScroll}>
        <PatientDetails />
      </div>
    </div>
  );
};

export default GenericWaitingList;
