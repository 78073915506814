/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import {
  Autocomplete,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { useLocationContext } from "../../LocationContext";
import { useGetAffiliations } from "hooks/dataCuration/useGetAffiliations";
import { useGetLocationTypes } from "hooks/dataCuration/useGetLocationTypes";
import {
  ParentLocation,
  useSearchParentLocations,
} from "hooks/dataCuration/useSearchParentLocations";
import useTranslation from "hooks/useTranslation";
import { autoGenerateWebUrl } from "../../utils/shareableMethods";
import { NetworkAffiliations } from "../../utils/types";
import { TabPaneProps } from "views/DataCuration/ProviderProfile/types";
import useStyles from "views/DOTForm/styles/forms.styles";

const NameSection: React.FC<TabPaneProps> = ({
  shouldDisableForm,
  onDisableEditButton,
}: TabPaneProps) => {
  const { classes } = useStyles({ alignLeft: true });
  const { locationTypesList } = useGetLocationTypes();
  const locationUrl = "/location";
  const {
    web: {
      dataGovernance: {
        location: {
          generalInfo: {
            nameSection: {
              locationIdTitle,
              parentLocationLabel,
              locationTypeTitle,
              shortName,
              locationName,
              locationPageTitle,
              webUrlField,
              affiliationField,
            },
          },
        },
      },
    },
  } = useTranslation();

  const {
    locationData: {
      generalInfo: {
        name,
        address: { streetAddress1, state, city, zip },
        contactInfo: { phone, webUrl },
      },
    },
    currentPayload,
    setCurrentPayload,
  } = useLocationContext();

  const { data: parentLocations = [], isLoading, isError, error } = useSearchParentLocations();
  const { affiliationsList } = useGetAffiliations();

  const locationList = [
    {
      label: locationName.title,
      key: "name",
      helperText: locationName.helperText,
      required: false,
      error: false,
    },
    {
      label: locationPageTitle.title,
      key: "nameFull",
      helperText: locationPageTitle.helperText,
      required: false,
      error: false,
    },
    {
      label: shortName.title,
      key: "nameShort",
      helperText: shortName.helperText,
      required: true,
      error: false,
    },
  ];

  const initialData = locationList.reduce((acc, { key }) => {
    acc[key] = name[key] || "";
    return {
      ...acc,
      webUrl,
    };
  }, {} as Record<string, string>);

  const [formData, setFormData] = useState(initialData);
  const [locationType, setLocationType] = useState(name.type ?? "");
  const [affiliation, setAffiliation] = useState<NetworkAffiliations[]>([]);
  const [parentLocation, setParentLocation] = useState<ParentLocation | null>(null);

  const handleChange = (key: string, e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = e?.target?.value;

    if (onDisableEditButton) {
      onDisableEditButton(newValue.length === 0);
    }

    let currentWebUrl = webUrl;
    if (key === "nameShort") {
      currentWebUrl = autoGenerateWebUrl({
        currentShortName: newValue,
        currentParentLocation: parentLocation,
        locationUrl,
      });
      setFormData({
        ...formData,
        [key]: newValue,
        webUrl: currentWebUrl,
      });
    } else {
      setFormData({
        ...formData,
        [key]: newValue,
      });
    }

    if (newValue !== name[key]) {
      if (key === "webUrl") {
        setCurrentPayload((prevPayload) => ({
          ...prevPayload,
          generalInfo: {
            ...(prevPayload?.generalInfo || {}),
            name: {
              ...(prevPayload?.generalInfo?.name || {}),
              nameShort: formData.nameShort,
            },
            contactInfo: {
              ...(prevPayload?.generalInfo?.contactInfo || {}),
              webUrl: newValue,
            },
          },
        }));
      } else {
        setCurrentPayload((prevPayload) => ({
          ...prevPayload,
          generalInfo: {
            ...(prevPayload?.generalInfo || {}),
            name: {
              ...(prevPayload?.generalInfo?.name || {}),
              [key]: newValue,
              nameShort: key === "nameShort" ? newValue : formData.nameShort,
            },
            address: {
              streetAddress1,
              state,
              city,
              zip,
            },
            contactInfo: {
              phone,
              webUrl: currentWebUrl,
            },
          },
        }));
      }
    }
  };

  const handleLocationTypeChange = (e: SelectChangeEvent<string>) => {
    const newType = e.target.value;
    setLocationType(newType);
    setCurrentPayload((prevPayload) => ({
      ...prevPayload,
      generalInfo: {
        ...(prevPayload?.generalInfo || {}),
        name: {
          ...(prevPayload?.generalInfo?.name || {}),
          type: newType,
          nameShort: formData.nameShort,
        },
      },
    }));
  };

  const handleParentLocationChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: ParentLocation | null,
  ) => {
    setParentLocation(newValue || null);
    const webUrl = autoGenerateWebUrl({
      currentShortName: formData.nameShort,
      currentParentLocation: newValue,
      locationUrl,
    });
    setFormData({
      ...formData,
      webUrl,
    });
    setCurrentPayload((prevPayload) => ({
      ...prevPayload,
      generalInfo: {
        ...(prevPayload?.generalInfo || {}),
        contactInfo: {
          ...(prevPayload?.generalInfo?.contactInfo || {}),
          webUrl,
        },
        name: {
          ...(prevPayload?.generalInfo?.name || {}),
          parent_location: newValue ? { id: newValue.id } : { id: null },
          nameShort: formData.nameShort,
        },
      },
    }));
  };

  const handleChangeAffiliation = (event, newValue) => {
    setAffiliation(newValue);

    setCurrentPayload((prevPayload) => ({
      ...prevPayload,
      generalInfo: {
        ...(prevPayload?.generalInfo || {}),
        name: {
          ...(prevPayload?.generalInfo?.name || {}),
          networkAffiliations: newValue?.map((n) => ({ id: n.id, active: n.active })),
        },
      },
    }));
  };

  const filterOptions = (options: any[], selectedValues: any[], key = "id") => {
    const selectedKeys = new Set(selectedValues.map((item) => item[key]));
    return options.filter((option) => !selectedKeys.has(option[key]));
  };

  useEffect(() => {
    if (name.parent_location) {
      const selectedParentLocation = parentLocations.find(
        (location) => location.id === (name.parent_location?.id || ""),
      );
      setParentLocation(selectedParentLocation || null);
    }
  }, [name.parent_location, parentLocations]);

  useEffect(() => {
    if (!currentPayload) {
      setFormData(initialData);
      setLocationType(name.type ?? "");
      setParentLocation(
        name.parent_location
          ? {
              address: "",
              name: name.parent_location.nameFull,
              parentLocationId: "",
              parentLocationName: "",
              parentLocationType: "",
              parentLocationWebpageUrl: "",
              source: "",
              status: "",
              type: "",
              uuid: "",
              ...name.parent_location,
            }
          : null,
      );
      setAffiliation(name.networkAffiliations || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPayload]);

  return (
    <Grid
      container
      spacing={2}
      alignItems="flex-start"
      justifyContent="flex-start"
      className={classes.personalInformation_wrapper}
      data-testid="NameSection"
    >
      <Grid item xs={12} md={12} style={{ margin: "16px 0" }}>
        <FormControl variant="outlined" fullWidth style={{ textAlign: "start" }}>
          <h6 style={{ fontFamily: "Alegreya sans", fontSize: "16px", fontWeight: "400" }}>
            {locationIdTitle}
          </h6>
          <span style={{ color: "gray" }}>{name.ahsId}</span>
        </FormControl>
      </Grid>
      {locationList.map(({ label, key, helperText, required, error }, index) => (
        <Grid item xs={12} md={12} key={key + index.toString()}>
          <FormControl variant="outlined" fullWidth>
            <TextField
              error={error}
              id={key}
              name={key}
              size="medium"
              value={formData[key]}
              variant="outlined"
              label={label}
              onChange={(e) => handleChange(key, e)}
              disabled={shouldDisableForm}
              helperText={helperText}
              data-cy={`NameSection-${key}Input`}
              data-testid={`NameSection-${key}Input`}
              required={required}
            />
          </FormControl>
        </Grid>
      ))}

      <Grid item xs={12} md={12}>
        <FormControl variant="outlined" fullWidth style={{ marginBottom: "24px" }} error={isError}>
          <Autocomplete
            id="parentLocation"
            options={parentLocations}
            getOptionLabel={(option) => option?.nameFull || ""}
            renderInput={(params) => <TextField {...params} label={error || parentLocationLabel} />}
            onChange={handleParentLocationChange}
            loading={isLoading}
            disabled={shouldDisableForm}
            value={parentLocation || null}
            clearOnEscape
            data-cy="NameSection-parentLocationInput"
            data-testid="NameSection-parentLocationInput"
          />
        </FormControl>
        <FormControl variant="outlined" fullWidth style={{ marginBottom: "24px" }}>
          <TextField
            id="webUrl"
            name="webUrl"
            size="medium"
            value={
              formData?.nameShort === null || formData?.nameShort === "" ? "" : formData.webUrl
            }
            variant="outlined"
            label={webUrlField.title}
            onChange={(e) => handleChange("webUrl", e)}
            disabled={shouldDisableForm}
            data-cy="NameSection-webUrlInput"
            data-testid="NameSection-webUrlInput"
            InputLabelProps={{ shrink: true }}
            error={formData?.nameShort === null || formData?.nameShort === ""}
            helperText={webUrlField.helperText}
          />
        </FormControl>
        <FormControl variant="outlined" fullWidth style={{ marginBottom: "24px" }}>
          <InputLabel id="LocationType-label" disabled={shouldDisableForm}>
            {locationTypeTitle}
          </InputLabel>
          <Select
            labelId="locationType-label"
            id="locationType"
            value={locationType}
            label={locationTypeTitle}
            disabled={shouldDisableForm}
            style={{ textAlign: "start" }}
            onChange={handleLocationTypeChange}
          >
            {locationTypesList?.map((type, index) => (
              <MenuItem value={type} key={type + index.toString()}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            multiple
            id="network-affiliations-autocomplete"
            data-testid="network-affiliations-autocomplete"
            disabled={shouldDisableForm}
            options={filterOptions(affiliationsList || [], name.networkAffiliations || [])}
            getOptionLabel={(option) => option.name}
            value={affiliation}
            onChange={handleChangeAffiliation}
            renderInput={(params) => (
              <TextField
                {...params}
                label={affiliationField.title}
                placeholder={affiliationField.helperText}
              />
            )}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default NameSection;
