import React, { useCallback, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import { usePatientProfileStyles } from "../waitingUserTableStyles";
import { FETCH_NEW_VIRTUAL_VISITS, UPDATE_AND_SELECT_VIRTUAL_VISIT } from "./Actions";
import TextMessage from "./TextMessage/TextMessage";
import { useUserType } from "hooks/useUserType/useUserType";
import { useApp } from "util/AppContext";
import { useAuth } from "util/Security";
import { axiosClient } from "util/api_helper";
import { useProgramType } from "util/programsHelpers";

const AssignPatientDepartment = ({
  departmentList,
  currentVirtualVisit,
  programId,
  setProfile,
  profile,
  urgency,
  setUrgency,
  assignUsersList,
}) => {
  const { classes } = usePatientProfileStyles();
  const app = useApp();
  const auth = useAuth();
  const queryClient = useQueryClient();
  const { isPhysician, isNurse } = useUserType();
  const { isOnDemand } = useProgramType();
  const [lastMessage, setLastMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isReschheduleBtnDisabled, setIsReschheduleBtnDisabled] = useState(false);
  const isConsultation = currentVirtualVisit?.type === "consultation";
  const isDepartmentAssigned = Boolean(currentVirtualVisit?.department_id);
  const currentUserId = Number(auth?.user.userId);

  const invalidateQuery = () => {
    queryClient.invalidateQueries("fetch-awaiting-vs-visit", programId);
  };

  const assignDepartment = async (departmentId) => {
    try {
      await axiosClient
        .post("/api/s/console/virtualvisitconsole/assigned-department", {
          virtual_session_id: currentVirtualVisit?.id,
          assigned_department_id: departmentId,
        })
        .then(() => {
          app.dispatch({ type: FETCH_NEW_VIRTUAL_VISITS });
          invalidateQuery();
        });

      app.dispatch({
        type: UPDATE_AND_SELECT_VIRTUAL_VISIT,
        payload: { ...currentVirtualVisit, department_id: departmentId },
      });
      setProfile({
        ...profile,
        virtual_session_info: {
          ...profile?.virtual_session_info,
          department_id: departmentId,
        },
      });
      app.addInfoMsg("Department has been assigned successfully.");
    } catch (e) {
      app.addError("Problem assigning department.");
    }
  };

  useEffect(() => {
    setIsReschheduleBtnDisabled(!currentVirtualVisit?.requested_start_beg);
  }, [currentVirtualVisit?.requested_start_beg]);

  const updateUrgency = async (urgency) => {
    try {
      setUrgency(urgency);

      const url = "/api/s/console/virtualvisitconsole/update-urgency";
      await axiosClient
        .post(url, {
          virtual_session_id: currentVirtualVisit?.id,
          urgency,
        })
        .then(() => {
          app.dispatch({ type: FETCH_NEW_VIRTUAL_VISITS });
          invalidateQuery();
        });

      app.dispatch({
        type: UPDATE_AND_SELECT_VIRTUAL_VISIT,
        payload: {
          ...currentVirtualVisit,
          urgency,
        },
      });
      setProfile({
        ...profile,
        virtual_session_info: {
          ...profile?.virtual_session_info,
          urgency,
        },
      });
      app.addInfoMsg("Urgency updated successfully.");
    } catch (error) {
      app.addError("Problem updating urgency.");
    }
  };

  const assignStaff = (userId) => {
    try {
      const url = "api/s/console/virtualvisitconsole/currently-assigned-staff-user";

      axiosClient
        .post(url, {
          virtual_session_id: currentVirtualVisit?.id,
          currently_assigned_staff_user_id: userId,
        })
        .then(() => {
          app.dispatch({ type: FETCH_NEW_VIRTUAL_VISITS });
          invalidateQuery();
        });

      app.dispatch({
        type: UPDATE_AND_SELECT_VIRTUAL_VISIT,
        payload: {
          ...currentVirtualVisit,
          currently_assigned_staff_user_id: userId,
        },
      });
      setProfile({
        ...profile,
        virtual_session_info: {
          ...profile?.virtual_session_info,
          currently_assigned_staff_user_id: userId,
        },
      });
      app.addInfoMsg("Staff has been assigned successfully.");
    } catch (e) {
      app.addError("Something went wrong. Please contact support.");
    }
  };

  const handleGetMessages = useCallback(async () => {
    try {
      const url = `/api/s/console/virtualvisit/load_messages?vv_id=${currentVirtualVisit?.id}`;
      const { data } = await axiosClient.get(url);
      const lastMessage = data.data[data.data.length - 1];
      setLastMessage(lastMessage?.message);
      setIsOpen(true);
    } catch (err: any) {
      app.addError(err?.response?.data?.description);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVirtualVisit?.id]);

  const handleSendRescheduleLink = async () => {
    try {
      const url = `/api/s/console/virtualvisit/sessions/${currentVirtualVisit?.id}/time-slots/selected`;
      await axiosClient.delete(url);
      handleGetMessages();
      app.dispatch({
        type: UPDATE_AND_SELECT_VIRTUAL_VISIT,
        payload: {
          ...app.vs_visits_state.currentVirtualVisit,
          requested_start_beg: null,
        },
      });
    } catch (err: any) {
      // @ts-ignore
      app.addError(err.response.data.description);
    }
  };

  const handleModalClose = () => {
    setIsOpen(false);
  };

  return (
    <Grid container alignItems="flex-start" className={classes.optionsContainer}>
      {isOpen && lastMessage && (
        <Dialog open={isOpen} onClose={handleModalClose} aria-labelledby="help-dialog-title">
          <DialogTitle id="help-dialog-title">Help?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <TextMessage body={lastMessage} isLocalParticipant={false} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleModalClose} color="primary" data-cy="closeBtn">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {!!departmentList?.length && (
        <TextField
          data-cy="assignToBtnDepartmentVUC"
          label="Assign Department"
          id="AssignDepartment"
          variant="outlined"
          className={classes.select}
          InputProps={{ classes: { input: classes.selectInput } }}
          InputLabelProps={{ classes: { root: classes.selectLabel } }}
          size="small"
          value={currentVirtualVisit.department_id}
          onChange={(e) => assignDepartment(e.target.value)}
          select
          disabled={isOnDemand && (isPhysician || isNurse)}
        >
          {departmentList.map((department, index) => (
            <MenuItem
              data-cy={`VUCDep-${index.toString()}`}
              key={department.id}
              value={department.id}
            >
              {department.name}
            </MenuItem>
          ))}
        </TextField>
      )}
      {!isConsultation && (isDepartmentAssigned || departmentList.length < 1) && (
        <TextField
          label="Urgency"
          id="Urgency"
          variant="outlined"
          className={classes.select}
          InputProps={{ classes: { input: classes.selectInput } }}
          InputLabelProps={{ classes: { root: classes.selectLabel } }}
          size="small"
          value={urgency}
          onChange={(e) => updateUrgency(e.target.value)}
          select
        >
          <MenuItem value="routine">Routine</MenuItem>
          <MenuItem value="urgent">Urgent</MenuItem>
          <MenuItem value="low_priority">Low Priority</MenuItem>
        </TextField>
      )}
      {(isDepartmentAssigned || departmentList.length < 1 || isConsultation) && (
        <TextField
          data-cy="assignBtn"
          label="Assign to"
          id="AssignTo"
          variant="outlined"
          className={classes.select}
          InputProps={{ classes: { input: classes.selectInput } }}
          InputLabelProps={{ classes: { root: classes.selectLabel } }}
          size="small"
          value={currentVirtualVisit.currently_assigned_staff_user_id}
          onChange={(e) => assignStaff(e.target.value)}
          select
        >
          <MenuItem value={currentUserId} data-cy="Me">
            Me
          </MenuItem>
          {assignUsersList?.map((user) => {
            const name = [user.first_name, user.last_name].filter(Boolean).join(" ");

            return (
              user.id !== currentUserId && <MenuItem value={user.id}>{name || user.email}</MenuItem>
            );
          })}
        </TextField>
      )}
      {isConsultation && (
        <Button
          data-cy="RescheduleVisit"
          className={classes.launchVideoBtn}
          onClick={handleSendRescheduleLink}
          disabled={isReschheduleBtnDisabled}
        >
          Reschedule Appointment
        </Button>
      )}
    </Grid>
  );
};

export default AssignPatientDepartment;
