import React, { useEffect } from "react";
import { AxiosResponse } from "axios";
import { enqueueSnackbar } from "notistack";
import DOTForm from "..";
import { Dashboard } from "./Steps/Dashboard";
import GenerateReportStep from "./Steps/GenerateReportStep";
import MedicalDeterminationStep from "./Steps/MedicalDeterminationStep";
import { DOT_MEDICALEXAMINER_STEPS, IData, StepChangeProps } from "../types/constants";
import BackButton from "views/console/components/BackButton";
import useTranslation from "hooks/useTranslation";
import { axiosClient } from "util/api_helper";

function getSteps() {
  return DOT_MEDICALEXAMINER_STEPS;
}

function getStepContent(
  step: number,
  data: IData,
  medicalRecords: Set<string> | undefined,
  onChange: (name: string, event: any) => void,
  handleStepChange?: (stepInfo: StepChangeProps) => void,
  formRef?: React.RefObject<HTMLFormElement>,
  loading?: boolean,
  setShouldDisableContinueButton?: (shouldDisableContinueButton: boolean) => void,
) {
  switch (step) {
    case 0:
      return (
        <Dashboard
          formData={data}
          medicalRecords={medicalRecords}
          handleChange={onChange}
          handleStepChange={handleStepChange}
          formRef={formRef}
          isLoading={loading}
        />
      );
    case 1:
      return (
        <MedicalDeterminationStep
          formData={data}
          handleChange={onChange}
          handleStepChange={handleStepChange}
          setShouldDisableContinueButton={setShouldDisableContinueButton}
        />
      );
    case 2:
      return <GenerateReportStep formData={data} handleChange={onChange} />;
    default:
      return "Unknown step";
  }
}

const DOTSubmissionPage = () => {
  const steps = getSteps();

  const {
    web: {
      common: { progressSaved },
    },
  } = useTranslation();

  useEffect(() => {
    const toastSuccessfulPatch = (response: AxiosResponse) => {
      // this only fires for successful requests, no need to check status code
      if (
        response.config.method === "patch" &&
        /\/api\/s\/console\/dot-form(\/)?/.test(response.config.url || "")
      ) {
        enqueueSnackbar(progressSaved, { variant: "info" });
      }
      return response;
    };
    const toastInterceptorId = axiosClient.interceptors.response.use(toastSuccessfulPatch);
    return () => {
      axiosClient.interceptors.response.eject(toastInterceptorId);
    };
  }, [progressSaved]);

  return (
    <>
      <BackButton url="/console/dot-form/dot-form-staff" />
      <DOTForm getSubmissionComponentsSteps={getStepContent} getSubmissionSteps={steps} />
    </>
  );
};

export default DOTSubmissionPage;
