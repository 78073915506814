import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  rootTableToolbar: {
    padding: "1em 8px 0 8px !important",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    gap: "16px",
    minHeight: "auto",
  },
  searchBar_wrapper: {
    display: "flex",
    alignItems: "end",
    flexDirection: "row",
    width: "100%",
    flexWrap: "nowrap",
    gap: 0,
  },
  searchBy_wrapper: {
    width: "160px",
    "@media (max-width: 768px)": {
      width: "145px",
    },
  },
  searchBy_select: {
    width: "100%",
    textAlign: "left",
    backgroundColor: "#e0e0e06e",
    borderRadius: "4px 0 0 4px",
    borderRight: "none",
    "& fieldset": { borderRight: "none" },
    "&.Mui-focused": {
      outline: "none",
      border: "none",
    },
    "&.Mui-focused fieldset": {
      outline: "none",
      border: "1px solid gray !important",
      borderRight: "none !important",
      borderBottom: "2px solid #043070 !important",
    },
  },
  textField_wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    width: "100%",
    maxWidth: "400px",
    position: "relative",
  },
  textField_searchText: {
    borderRadius: "0 4px 4px 0",
    borderLeft: "none",
    "& fieldset": { borderLeft: "none", borderRadius: "0 4px 4px 0" },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          border: "1px solid gray",
          borderLeft: "none",
          borderBottom: "2px solid #043070",
        },
      },
    },
  },
  clearIcon: {
    position: "absolute",
    right: "80px",
    "@media (max-width: 768px)": {
      right: "60px",
    },
  },
  actionButtons_wrapper: {
    width: "100%",
    background: "rgba(4, 48, 112, 0.08)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "8px",
    padding: "8px 16px",
    height: "56px",
  },
  datePickerAdornment: {
    paddingRight: "28px",
  },
  arrowIcon: {
    color: "white",
    height: "20px",
    width: "20px",
  },
  arrowIconDisabled: {
    color: "black",
    height: "20px",
    width: "20px",
  },
}));
