import React, { FormEvent, useState } from "react";
import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import ConfirmationDialog from "components/ConfirmationDialog";
import CellPhone from "components/shared/CellPhone";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { useIsMobile } from "util/deviceUtils";

const useStyles = makeStyles()((theme) => ({
  titleContainer: {
    margin: 0,
    padding: "16px",
  },
  title: {
    margin: 0,
  },
  closeButton: {
    position: "absolute",
    right: "8px",
    top: "8px",
  },
  textField: {
    marginTop: "24px",
  },
  error: {
    color: theme.palette.error.main,
    marginTop: "4px",
    fontSize: "12px",
  },
  confirmationModal: {
    width: "80%",
    maxHeight: 300,
  },
}));

type EditDriverPhoneNumberModalProps = {
  open: boolean;
  onClose: () => void;
  handleSaved: () => void;
  userData: { userId: number; phoneNumber: string; driverFullName: string };
};

const EditDriverPhoneNumberModal: React.FC<EditDriverPhoneNumberModalProps> = ({
  open,
  onClose,
  handleSaved,
  userData: { userId, phoneNumber, driverFullName },
}) => {
  const { classes } = useStyles();
  const isMobile = useIsMobile();
  const { addError, addInfoMsg } = useApp();
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>(phoneNumber);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    setConfirmationDialogOpen(true);
  };

  const handleSaveChanges = async () => {
    try {
      if (!phone) {
        return;
      }

      const url = `${API_ENDPOINTS.dotPatchUser}${userId}`;
      const body = { cellPhone: phone };
      await axiosClient.patch(url, body);
      handleSaved();
      addInfoMsg("Phone has been updated successfully.");
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    } catch (error: any) {
      addError("Problem updating phone, please contact support.");
      onClose();
    }
  };

  const handleActionConfirmationOk = () => {
    handleSaveChanges();
    setConfirmationDialogOpen(false);
  };

  const handleActionConfirmationCancel = () => {
    setConfirmationDialogOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullScreen={isMobile} maxWidth="sm">
        <DialogTitle className={classes.titleContainer}>
          <Typography className={classes.title} variant="h6">
            Update Phone
          </Typography>
          <IconButton
            className={classes.closeButton}
            onClick={onClose}
            data-testid="close-button"
            size="large"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form id="form" onSubmit={handleSubmit}>
            <span>You are editing</span>
            <Typography
              variant="h6"
              style={{ paddingBottom: "15px" }}
              data-testid="patient-title-with-name"
            >
              {driverFullName}'s phone number
            </Typography>
            <div className={classes.textField}>
              <CellPhone
                error={false}
                cellPhone={phone}
                setCellPhone={(value) => setPhone(value)}
                data-cy="PersonalInformation-CellPhoneInput"
              />
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            form="form"
            data-testid="save-changes-button"
            fullWidth
            disabled={!phone || phone.length < 10}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        classes={{ paper: classes.confirmationModal }}
        id="confirmation-update-phone"
        title="Confirm Update Phone Number"
        msg="Are you sure you want to update the phone number?"
        open={confirmationDialogOpen}
        onCancel={handleActionConfirmationCancel}
        onOk={handleActionConfirmationOk}
      />
    </>
  );
};

export default EditDriverPhoneNumberModal;
