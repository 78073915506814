import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AddOutlined, ArrowBack } from "@mui/icons-material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import SpecimenTrackingFilters from "../Filters";
import { SpecimenCardMobile } from "./SpecimenCardMobile";
import { format } from "date-fns";
import { useProgramsLoading } from "views/console/components/useProgramsLoading";
import { useGetSpecimenLast } from "hooks/specimenTracking/useGetSpecimenLast/useGetSpecimenLast";
import useTranslation from "hooks/useTranslation";
import { useAuth } from "util/Security";
import { useSpecimenMobileListStyles } from "../../styles";

export const SpecimenTrackingListMobile = ({ addSpecimen }) => {
  const auth = useAuth();
  const { classes } = useSpecimenMobileListStyles();
  const userId = auth?.user.userId.toString();
  const navigate = useNavigate();
  const currentDate = format(new Date(), "MM/dd/yyyy");

  const initialSearchBy = sessionStorage.getItem("searchBy") ?? "collectedDatetime";
  const initialSearchValue = sessionStorage.getItem("searchValue") ?? currentDate;
  const initialCollectedBy = sessionStorage.getItem("collectedBy") ?? userId;

  const [searchBy, setSearchBy] = useState<string>(initialSearchBy);
  const [searchValue, setSearchValue] = useState<string>(initialSearchValue);
  const [expanded, setExpanded] = useState({});
  const [collectedBy, setCollectedBy] = useState(initialCollectedBy);
  const {
    web: { specimenTracking, specimenReportOrderId },
  } = useTranslation();

  const { isProgramsLoading } = useProgramsLoading();
  const { refetch, isFetching, data, transformedDataForMobile, clearSearchParams } =
    useGetSpecimenLast(searchBy, searchValue, collectedBy);
  const isLoading = isFetching || isProgramsLoading;

  const renderPatientName = (specimenData) => {
    if (specimenData && specimenData.length > 0) {
      const { specimenOrderPatientLastName, specimenOrderPatientFirstName } = specimenData[0];
      if (specimenOrderPatientLastName || specimenOrderPatientFirstName) {
        const lastName = specimenOrderPatientLastName || "";
        const firstName = specimenOrderPatientFirstName || "";
        return `${lastName}${lastName && firstName ? ", " : ""}${firstName}`;
      }
    }
    return "NA";
  };

  const handleChangeUserSpecimens = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;
    setCollectedBy(target.innerText === specimenReportOrderId.mySpecimens ? userId : "all");
  };

  const handleExpand = (itemId) => {
    setExpanded((prevStates) => ({
      ...prevStates,
      [itemId]: !prevStates[itemId],
    }));
  };

  const handleSaved = () => {
    refetch();
  };

  const handleSearch = () => {
    sessionStorage.setItem("searchValue", searchValue);
    refetch();
  };

  const clearFilters = () => {
    clearSearchParams();
    setSearchValue("");
    sessionStorage.setItem("searchBy", "");
    sessionStorage.setItem("searchValue", "");
    refetch();
  };

  const toggleButtons = [
    {
      label: specimenReportOrderId.mySpecimens,
      value: userId,
      testId: "mySpecimensBtn",
    },
    {
      label: specimenReportOrderId.allSpecimens,
      value: "all",
      testId: "allSpecimensBtn",
    },
  ];

  useEffect(() => {
    sessionStorage.setItem("searchBy", searchBy);
  }, [searchBy]);

  useEffect(() => {
    sessionStorage.setItem("collectedBy", collectedBy);
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectedBy]);

  return (
    <Grid container className={classes.mobileWrapper} data-testid="specimenTrackingMobile">
      <Box className={classes.titleContainer}>
        <IconButton
          onClick={() => navigate("/console/")}
          className={classes.backButton}
          color="primary"
          size="large"
        >
          <ArrowBack />
        </IconButton>
        <Typography className={classes.listTitle} color="inherit" variant="body1">
          {specimenTracking.listTitle}
        </Typography>
      </Box>
      <SpecimenTrackingFilters
        searchBy={searchBy}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setSearchBy={setSearchBy}
        handleSearch={handleSearch}
        clearFilters={clearFilters}
      />
      <ToggleButtonGroup
        value={collectedBy}
        exclusive
        onChange={handleChangeUserSpecimens}
        data-testid="mySpecimensToggle"
        className={classes.userSpecimensToggleWrapper}
      >
        {toggleButtons.map(({ value, label, testId }) => {
          return (
            <ToggleButton key={label} value={value} data-testid={testId}>
              {label}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
      {isFetching && (
        <CircularProgress
          style={{ margin: "4em auto 0 auto" }}
          data-testid="specimenMobileLoader"
        />
      )}
      {!isFetching && !data?.data.length ? (
        <Typography className={classes.noData}>{specimenTracking.noSpecimens}</Typography>
      ) : (
        <Grid data-testid="specimenCards" style={{ width: "100%", padding: "0 8px" }}>
          {transformedDataForMobile(data?.data).map(
            ({ specimenOrderId, specimenOrderExternalId, specimenData }) => {
              return (
                <Paper key={specimenOrderId} className={classes.specimenCard} elevation={2}>
                  <Typography className={classes.labelSmall}>
                    {specimenTracking.patientName}:{" "}
                    <strong>{renderPatientName(specimenData)}</strong>
                  </Typography>
                  <Typography className={classes.labelSmall}>
                    {specimenTracking.orderId}: <strong> {specimenOrderExternalId}</strong>
                  </Typography>
                  <Typography className={classes.labelSmall}>
                    {specimenTracking.numbersOfSpecimens}: {specimenData.length}
                  </Typography>
                  {expanded[specimenOrderId] &&
                    specimenData.map((props) => (
                      <SpecimenCardMobile
                        key={props.specimenId}
                        {...props}
                        handleSaved={handleSaved}
                      />
                    ))}
                  <Button
                    className={classes.expandBtn}
                    color="primary"
                    startIcon={expanded[specimenOrderId] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    data-cy="expand"
                    data-testid="expandBtn"
                    size="small"
                    onClick={() => handleExpand(specimenOrderId)}
                  />
                </Paper>
              );
            },
          )}
        </Grid>
      )}
      <IconButton
        data-cy="AddSpecimenBtn"
        onClick={addSpecimen}
        className={classes.footerBtn}
        data-testid="AddOrLocationBtn"
        size="large"
        disabled={isLoading}
      >
        <AddOutlined style={{ color: "#fff" }} />
      </IconButton>
    </Grid>
  );
};

export default SpecimenTrackingListMobile;
