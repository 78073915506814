import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, CircularProgress, List } from "@mui/material";
import CreateVirtualSessionModal from "./CreateVirtualSessionModal/CreateVirtualSessionModal";
import OrderBy from "./Filters/OrderBy";
import PatientReason from "./Filters/PatientReason";
import SortBy from "./Filters/SortBy";
import QueueViewItem from "./QueueViewItem";
import SelectListItem from "./SelectListItem";
import { waitingUsersTableStyles } from "./waitingUserTableStyles";
import { useGetDefaultQueueView } from "components/Video/components/PreJoinScreensPhysician/WaitingRoom/hooks";
import { VirtualSessionQueue } from "util/constants";
import { useQuery } from "hooks/useQuery";
import { usePrograms } from "util/ProgramsContext";
import { useProgramTypeByPathname } from "util/programsHelpers";

export default function WaitingUsersTable({
  awaitingListData,
  isLoading,
  urlOptions,
  setUrlOptions,
  newSessionButton = { title: "New Session", visible: true },
  viewReportButton = { title: "View Report", visible: true },
}) {
  const { classes } = waitingUsersTableStyles();
  const query = useQuery();
  const { programId, departmentId } = usePrograms();
  const { isConsultation } = useProgramTypeByPathname();
  const defaultQueueView = useGetDefaultQueueView(isConsultation);
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const queueViewParam = params.get("queueView");
  const [showModal, setShowModal] = useState(false);
  const [mode, setMode] = useState("add");
  const [orderBy, setOrderBy] = useState(urlOptions.order_by || "requested_start_beg");
  const [order, setOrder] = useState(urlOptions.order || "asc");
  const [patientReason, setPatientReason] = useState(urlOptions.patient_reason);
  const [selectedVirtualVisitIds, setSelectedVirtualVisitIds] = useState<number[]>([]);
  const [selectedListItem, setSelectedListItem] = useState<number | null>(null);

  useEffect(() => {
    setUrlOptions((prevOptions) => {
      const newOptions = {
        ...prevOptions,
        order,
        order_by: orderBy,
      };

      if (patientReason === "all" || !patientReason) {
        delete newOptions.patient_reason;
      } else {
        newOptions.patient_reason = patientReason;
      }

      return newOptions;
    });
  }, [order, orderBy, patientReason]);

  const handleSelect = (id) => {
    setSelectedListItem(id);
  };

  const handleViewReports = () => {
    window.open(
      `/console/virtual-session-report?programId=${programId}&departmentId=${departmentId}`,
      "_blank",
      "noopener, noreferrer",
    );
  };

  useEffect(() => {
    const cellPhone = query.get("cell_phone");
    if (cellPhone && !showModal) {
      setShowModal(true);
    }
  }, [query]);

  useEffect(() => {
    const queueValue = queueViewParam ?? defaultQueueView;
    params.set("queueView", queueValue);

    navigate({ search: params.toString() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root} data-testid="waitingUsersTable">
      <div
        className={`
          ${classes.topButtonsWrapper}
          ${classes.multipleButtons}
        `}
      >
        {newSessionButton.visible && (
          <Button variant="contained" color="primary" onClick={() => setShowModal(true)}>
            {newSessionButton.title}
          </Button>
        )}
        {viewReportButton.visible && (
          <Button variant="outlined" color="primary" onClick={() => handleViewReports()}>
            {viewReportButton.title}
          </Button>
        )}
      </div>

      <div className={classes.filtersContainer}>
        <PatientReason patientReason={patientReason} setPatientReason={setPatientReason} />
        <SortBy orderBy={orderBy} setOrderBy={setOrderBy} />
        <OrderBy order={order} setOrder={setOrder} />
      </div>

      {awaitingListData.length ? (
        <SelectListItem
          selectedVirtualVisitIds={selectedVirtualVisitIds}
          setSelectedVirtualVisitIds={setSelectedVirtualVisitIds}
          amountOfVirtualVisits={awaitingListData.length}
          virtualSessoinsList={awaitingListData}
          queue={VirtualSessionQueue.REGISTERING}
        />
      ) : null}

      {isLoading ? (
        <CircularProgress style={{ margin: "auto" }} />
      ) : (
        <List dense className={classes.queueContainer}>
          {awaitingListData.length === 0
            ? "Empty List"
            : awaitingListData.map((row) => (
                <QueueViewItem
                  key={row?.id}
                  virtual_visit={row}
                  isSelected={selectedListItem === row.id}
                  onSelect={() => handleSelect(row.id)}
                  selectedVirtualVisitIds={selectedVirtualVisitIds}
                  setSelectedVirtualVisitIds={setSelectedVirtualVisitIds}
                />
              ))}
        </List>
      )}

      <CreateVirtualSessionModal
        show={showModal}
        setShow={setShowModal}
        mode={mode}
        setMode={setMode}
        modalTitle="New Consult Request"
        isConsultation={isConsultation}
      />
    </div>
  );
}
