import React from "react";
import { useFormik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MuiDialogActions from "@mui/material/DialogActions";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import DataCurationTable from "../..";
import { makeStyles, withStyles } from "tss-react/mui";
import LocationEnabler from "views/DataCuration/common/LocationEnabler";
import useTranslation from "hooks/useTranslation";
import { colDefsNeedsReviewModalTable } from "../../utils/colDefs";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
});

const useStyles = makeStyles()(() => ({
  root: {
    flexGrow: 1,
    margin: "auto",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  titlePaddings: {
    margin: "10px 0px 10px 10px",
  },
  radioSubtitle: { paddingLeft: "2rem", color: "gray", fontSize: 12 },
  radioGroup: {
    display: "flex",
    padding: "16px",
  },
}));

const Title = withStyles(({ children, classes, onClose, ...other }) => {
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton
          size="large"
          onClick={onClose}
          className={classes.closeButton}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}, styles);

const ModalActions = withStyles(MuiDialogActions, (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}));

const ModalContent = withStyles(MuiDialogContent, (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

interface NeedsReviewModalProps {
  readonly show: boolean;
  readonly onClose: (shouldRetrigger: boolean) => void;
  readonly selectedLocation: any;
}

export default function NeedsReviewModal({
  show,
  onClose,
  selectedLocation,
}: NeedsReviewModalProps) {
  const app = useApp();

  const handleClose = (shouldRetrigger: boolean) => {
    onClose(shouldRetrigger);
  };

  const handleSave = async (newValues: any) => {
    const { enabledOnLocationFinder, enabledAsPracticeLocationProvider } = newValues;
    try {
      const response = await axiosClient.patch(
        `${API_ENDPOINTS.dataGovernanceLocations}/${selectedLocation[0].id}`,
        {
          id: selectedLocation.id,
          status: "ACCEPTED",
          locationFinder: enabledOnLocationFinder,
          providerFinder: enabledAsPracticeLocationProvider,
        },
      );
      app.addInfoMsg(response.data.message);
      handleClose(true);
    } catch (error: any) {
      if (error?.response) {
        app.addError(error?.response?.data?.description);
      } else {
        app.addError("Error updating location. Please try again.");
      }
    }
  };

  const { values, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      enabledOnLocationFinder: false,
      enabledAsPracticeLocationProvider: false,
    },
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (newValues) => {
      handleSave(newValues);
    },
  });
  const { classes } = useStyles();

  const {
    web: {
      dataGovernance: {
        location: {
          newLocation: { reviewLocation },
          common: { save, cancel },
        },
      },
    },
  } = useTranslation();

  return (
    <Dialog onClose={handleClose} aria-labelledby="change-request-modal" open={show} maxWidth="xl">
      <Title id="change-request-modal-title" onClose={handleClose}>
        {reviewLocation}
      </Title>
      <ModalContent dividers>
        <DataCurationTable
          rows={selectedLocation}
          colDefs={colDefsNeedsReviewModalTable}
          showLocationsFilter={false}
          showAddNewButton={false}
          id="enableDisableLocation"
          allowSelection={false}
          enableToolbar={false}
          enableFooter={false}
        />
        <form onSubmit={handleSubmit} noValidate>
          <Grid container className={classes.root}>
            <LocationEnabler values={values} setFieldValue={setFieldValue} />
          </Grid>
        </form>
      </ModalContent>
      <ModalActions>
        <Button onClick={() => handleClose(false)} color="secondary" variant="contained">
          {cancel}
        </Button>
        <Button
          onClick={() => handleSubmit()}
          type="submit"
          color="primary"
          variant="contained"
          data-testid="submit"
        >
          {save}
        </Button>
      </ModalActions>
    </Dialog>
  );
}
