import { useQuery } from "react-query";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { QUERY_KEYS } from "util/queryKeys";

interface ClaimedSpecialtiesList {
  data: {
    data: [
      {
        id: number;
        name: string;
      },
    ];
  };
}

export const useGetClaimedSpecialties = () => {
  const app = useApp();

  const {
    web: {
      common: { errorMsg },
    },
  } = useTranslation();

  const fetchQuery = (): Promise<ClaimedSpecialtiesList> =>
    axiosClient.get(API_ENDPOINTS.dataGovernanceClaimedSpecialties);

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: [QUERY_KEYS.claimedSpecialties],
    queryFn: fetchQuery,
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: true,
    onError: () => app.addError(errorMsg),
  });

  const claimedSpecialtiesList = data?.data?.data;

  return {
    claimedSpecialtiesList,
    refetch,
    isLoading,
    isFetching,
  };
};
