import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Theme from "Theme";
import {
  CalendarToday as CalendarIcon,
  Chat as ChatIcon,
  LocalHospital as LocalHospitalIcon,
  LocationOn,
  Timer as TimerIcon,
} from "@mui/icons-material";
import {
  Box,
  Checkbox,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { CONNECT_VIRTUAL_VISIT, TOGGLE_QUEUE_TAB } from "../PatientDetails/Actions";
import Tag from "./Tag";
import { makeStyles } from "tss-react/mui";
import { Urgency, WaitingRoomTab } from "util/constants";
import { useApp } from "util/AppContext";
import { useAuth } from "util/Security";
import {
  getAddress,
  getAgeByDOB,
  getConsultationCalendarLabel,
  getTimeAgo,
} from "util/util_functions";

const getReasonForVisitColor = (urgency, theme) => {
  switch (urgency) {
    case Urgency.ROUTINE: {
      return theme.palette.secondary.main;
    }
    case Urgency.URGENT: {
      return theme.palette.error.main;
    }
    case Urgency.LOW_PRIORITY: {
      return theme.palette.success.main;
    }
    default: {
      return theme.palette.secondary.main;
    }
  }
};

const useStyles = makeStyles<{ isSelected: boolean; urgency: Urgency }>()((theme, props) => ({
  listItem: {
    paddingLeft: 0,
    borderRadius: 0,
    cursor: "pointer",
    backgroundColor: props?.isSelected ? "rgba(4, 48, 112, 0.08)" : theme.palette.common.white,

    "&:hover": {
      backgroundColor: props?.isSelected ? "rgba(4, 48, 112, 0.12)" : "rgba(4, 48, 112, 0.04)",
    },
  },
  listItemIcon: {
    margin: 0,
    minWidth: "unset",
  },
  listItemText: {
    margin: 0,
  },
  ActionBtn: {
    padding: 0,
    float: "right",
    top: 15,
    position: "absolute",
    right: 5,
  },
  patientTitle: {
    fontSize: "16px",
    fontWeight: 700,

    "&:hover": {
      cursor: "pointer",
    },
  },
  visitReasonTitle: {
    textTransform: "capitalize",
    color: getReasonForVisitColor(props.urgency, theme),
  },
  patientTitleSelected: {
    color: theme.palette.common.white,
  },
  chipRoot: {
    height: "26px",
    backgroundColor: "#DDDDDD",
    color: "#212121",
    margin: "0 3px 7px",
  },
  patientDecs: {
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.54)",
  },
  address: {
    marginBottom: "4px",
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.54)",
  },
  timerIcon: {
    color: "rgba(0, 0, 0, 0.54)",
  },
  timerText: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "14px",
    marginLeft: "6px",
  },
}));

export default function QueueViewItem({
  virtual_visit,
  selectedVirtualVisitIds,
  setSelectedVirtualVisitIds,
  isSelected,
  onSelect,
}) {
  const app = useApp();
  const auth = useAuth();
  const navigate = useNavigate();
  const { virtual_session_id: virtualSessionId } = useParams();
  const { classes } = useStyles({ isSelected, urgency: virtual_visit.urgency });

  const { patient_info: patientInfo } = virtual_visit;
  const params = new URLSearchParams(window.location.search);
  const vsId = params.get("vsId");

  const isConsultation = virtual_visit?.type === "consultation";
  const isAssignedToMe =
    parseInt(virtual_visit?.currently_assigned_staff_user_id, 10) ===
    parseInt(auth?.user?.userId, 10);
  const isAssigned = Boolean(virtual_visit?.currently_assigned_staff_user_id);
  const tags = [];
  const mainDesc = {
    fullName: `${[patientInfo?.first_name, patientInfo?.last_name].filter(Boolean).join(" ")}${
      virtual_visit?.patient_reason ? " - " : ""
    }`,
    patientReason: `${
      virtual_visit?.patient_reason ? `${virtual_visit?.patient_reason.split("_").join(" ")}` : ""
    }`,
  };

  const patientDesc = [patientInfo?.dob ? getAgeByDOB(patientInfo?.dob) : "", patientInfo?.gender]
    .filter(Boolean)
    .join(", ");

  const address = getAddress(patientInfo?.city, patientInfo?.state, patientInfo?.zip);

  if (isAssignedToMe || isAssigned) {
    const fullName = `${
      virtual_visit.staff_info?.first_name
        ? `${virtual_visit.staff_info?.first_name[0].toUpperCase()}.`
        : ""
    } ${virtual_visit.staff_info?.last_name || ""}`;
    const renderedFullName = fullName?.length <= 15 ? fullName : `${fullName.slice(0, 15)}...`;
    const assignMessage = `Assigned To ${renderedFullName}`;
    // @ts-ignore
    tags.push({
      name: isAssignedToMe ? "Assigned To Me" : assignMessage,
      bgcolor: Theme.palette.primary.main,
      color: "white",
    });
  }

  if (!isAssigned && !isAssignedToMe) {
    // @ts-ignore
    tags.push({
      name: "Unassigned",
      bgcolor: "#C4C4C4",
      color: Theme.palette.common.black,
    });
  }

  let showChatIcon = false;
  if (virtual_visit.last_message_dttm && virtual_visit.last_message_dttm.length > 0) {
    if (!virtual_visit.staff_last_viewed) {
      showChatIcon = true;
    } else {
      const d1 = Date.parse(virtual_visit.last_message_dttm);
      const d2 = Date.parse(virtual_visit.staff_last_viewed);
      if (d1 > d2) {
        showChatIcon = true;
      }
    }
  }

  useEffect(() => {
    if (Number(vsId) === virtual_visit.id && !virtualSessionId) {
      app.dispatch({
        type: CONNECT_VIRTUAL_VISIT,
        payload: Number(vsId),
      });
    }

    if (virtualSessionId) {
      app.dispatch({
        type: CONNECT_VIRTUAL_VISIT,
        payload: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vsId, virtual_visit.id, virtualSessionId]);

  const handleClickListItem = () => {
    onSelect();
    params.set("vsId", virtual_visit.id);
    params.delete("isConferenceCall");
    navigate({ search: params.toString() });

    app.dispatch({
      type: CONNECT_VIRTUAL_VISIT,
      payload: virtual_visit.id,
    });
    app.dispatch({
      type: TOGGLE_QUEUE_TAB,
      payload: WaitingRoomTab.PATIENT,
    });
  };

  const handleClickCheckbox = (e) => {
    const { checked } = e.target;
    const value = Number(e.target.value);

    if (checked) {
      setSelectedVirtualVisitIds([...selectedVirtualVisitIds, value]);
    } else {
      setSelectedVirtualVisitIds(
        selectedVirtualVisitIds.filter((virtualVisits) => virtualVisits !== value),
      );
    }
  };

  return (
    <div data-cy="awaitingUser">
      <ListItem
        alignItems="flex-start"
        divider
        className={classes.listItem}
        onClick={handleClickListItem}
        data-cy={virtual_visit.id}
      >
        <ListItemIcon onClick={(e) => e.stopPropagation()} className={classes.listItemIcon}>
          <Checkbox
            id={`checkbox-${virtual_visit.id}`}
            color="primary"
            value={virtual_visit.id}
            checked={selectedVirtualVisitIds.includes(virtual_visit.id)}
            onChange={handleClickCheckbox}
          />
        </ListItemIcon>
        <ListItemText
          className={classes.listItemText}
          disableTypography
          primary={
            <Box display="flex" alignItems="center" minHeight="42px">
              <Typography
                variant="body1"
                onClick={(e) => e.stopPropagation()}
                component="label"
                htmlFor={`checkbox-${virtual_visit.id}`}
                className={classes.patientTitle}
              >
                {mainDesc.fullName}
                {!!mainDesc.patientReason && (
                  <Box component="span" className={classes.visitReasonTitle}>
                    {mainDesc.patientReason}
                  </Box>
                )}
                {!mainDesc.fullName && !mainDesc.patientReason && patientInfo?.cell_phone}
              </Typography>
            </Box>
          }
          secondary={
            <>
              <Box display="flex" flexWrap="wrap">
                {tags?.map(({ name, color, bgcolor }) => (
                  <Tag key={name} label={name} color={color} bgColor={bgcolor} />
                ))}
                {virtual_visit?.distance && (
                  <Tag
                    color="rgba(0, 0, 0, 0.87)"
                    bgColor="#FBE1B6"
                    icon={<LocationOn />}
                    label={`${virtual_visit?.distance.toFixed(1)} miles`}
                  />
                )}
                {showChatIcon && (
                  <IconButton aria-label="chat" className={classes.ActionBtn} size="small">
                    <ChatIcon fontSize="medium" style={{ color: Theme.palette.common.black }} />
                  </IconButton>
                )}
                {virtual_visit?.department_short_name && (
                  <Tag
                    color="#212121"
                    bgColor="#DDDDDD"
                    icon={<LocalHospitalIcon />}
                    label={virtual_visit?.department_short_name}
                  />
                )}
                {isConsultation &&
                  !!virtual_visit?.requested_start_beg &&
                  !!virtual_visit?.requested_start_end && (
                    <Tag
                      color="#212121"
                      bgColor="#FBE1B6"
                      icon={<CalendarIcon />}
                      label={getConsultationCalendarLabel(
                        virtual_visit?.requested_start_beg,
                        virtual_visit?.requested_start_end,
                      )}
                    />
                  )}
              </Box>

              <Typography variant="body1" className={classes.patientDecs}>
                {patientDesc}
              </Typography>
              {!!address && (
                <Typography variant="body1" className={classes.address}>
                  {address}
                </Typography>
              )}
              <Box display="inline-flex">
                <TimerIcon className={classes.timerIcon} />
                <Typography variant="body1" component="span" className={classes.timerText}>
                  Joined {getTimeAgo(virtual_visit.start_dttm)}
                </Typography>
              </Box>
            </>
          }
        />
      </ListItem>
    </div>
  );
}
