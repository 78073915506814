const replaceSpaceWithDashes = (name: string) => {
  return name.replace(/\s+/g, "-").toLowerCase();
};

export const autoGenerateWebUrl = ({ currentParentLocation, currentShortName, locationUrl }) => {
  if (currentParentLocation) {
    const parentLocationSlug = replaceSpaceWithDashes(currentParentLocation.nameFull);
    if (currentShortName) {
      const urlWithShortName = `${locationUrl}/${parentLocationSlug}/${replaceSpaceWithDashes(
        currentShortName,
      )}`;
      return urlWithShortName;
    }
  }
  return `${locationUrl}/${replaceSpaceWithDashes(currentShortName)}`;
};
