import React from "react";
import ArrowForward from "@mui/icons-material/ArrowForward";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Box,
  Button,
  FormControl,
  FormGroup,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { isValid } from "date-fns";
import useTranslation from "hooks/useTranslation";
import { useIsMobile } from "util/deviceUtils";
import { TableToolbarProps } from "./types";
import { useStyles } from "./styles";

const SearchToolbar: React.FC<TableToolbarProps> = (props: TableToolbarProps) => {
  const { classes } = useStyles();
  const {
    setSearchBy,
    setSearchText,
    setSelectedDate,
    searchBy,
    searchText,
    selectedDate,
    searchOptions,
    handleSearch,
    handleCleanSearch,
  } = props;

  const {
    web: {
      common: { search, searchBy: searchByLabel },
    },
  } = useTranslation();

  const isMobile = useIsMobile();

  const clearSearch = () => {
    if (handleCleanSearch) {
      handleCleanSearch();
      setSearchText("");
    }
  };

  const handleKeydown = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      handleSearch();
    }
  };

  const isDisabled = (() => {
    if (searchBy === "collectedDatetime") {
      return !selectedDate || !searchBy || !searchText || !isValid(selectedDate);
    }
    return !searchText || !searchBy;
  })();

  return (
    <Toolbar className={classes.rootTableToolbar}>
      <Box
        width="100%"
        position="relative"
        display="flex"
        flexDirection="row"
        alignItems="flex-end"
        style={{ gap: "32px" }}
      >
        <FormGroup className={classes.searchBar_wrapper} data-testid="search-bar-section">
          {searchOptions.length > 0 && (
            <FormControl className={classes.searchBy_wrapper}>
              <Typography textAlign="left" fontSize="14px" color="gray" marginBottom="4px">
                {searchByLabel}
              </Typography>
              <Select
                displayEmpty
                placeholder="Search by"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={searchBy}
                onChange={(e) => setSearchBy(e.target.value)}
                size="small"
                classes={{ root: classes.searchBy_select }}
                data-cy="searchBySelect"
                inputProps={{ "data-testid": "search-by-select" }}
              >
                {searchOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    data-cy={option.dataCy}
                    data-testid={option.dataCy}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <Box className={classes.textField_wrapper}>
            {searchBy === "collectedDatetime" ? (
              <DatePicker
                label="Collected Date"
                format="MM/dd/yyyy"
                value={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                data-testid="filterSpecimensDateInput"
                data-cy="filterSpecimensDateInput"
                disableFuture
                slotProps={{
                  textField: {
                    size: "small",
                    variant: "outlined",
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleSearch();
                      }
                    },
                  },
                  inputAdornment: {
                    className: classes.datePickerAdornment,
                  },
                }}
              />
            ) : (
              <TextField
                id="outlined-basic"
                label="Search"
                variant="outlined"
                size="small"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => handleKeydown(e)}
                classes={{ root: classes.textField_searchText }}
                style={{ width: /* isMobile ? "10px" :  */ "100%" }}
                inputProps={{ "data-testid": "search-text-field" }}
              />
            )}
            <Button
              style={{ height: "39px", minWidth: "48px" }}
              onClick={handleSearch}
              variant="contained"
              color="primary"
              disabled={isDisabled}
              data-testid="searchButton"
            >
              {isMobile ? (
                <ArrowForward
                  data-testid="arrowForward"
                  className={isDisabled ? classes.arrowIconDisabled : classes.arrowIcon}
                />
              ) : (
                search
              )}
            </Button>
            {searchText && (
              <IconButton
                className={classes.clearIcon}
                disabled={!searchText}
                data-testid="clearFiltersBtn"
              >
                <CancelIcon onClick={clearSearch} />
              </IconButton>
            )}
          </Box>
        </FormGroup>
      </Box>
    </Toolbar>
  );
};

export default SearchToolbar;
