import React from "react";
import CalendarIcon from "@mui/icons-material/CalendarToday";
import { Chip } from "@mui/material";
import { getConsultationCalendarLabel } from "../../../../../util/util_functions";

type ConsultationScheduledProps = {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  classes: any;
  startDate?: Date;
  endDate?: Date;
  size?: "small" | "medium";
};
const ConsultationScheduled = ({
  classes,
  startDate,
  endDate,
  size = "small",
}: ConsultationScheduledProps) => {
  return (
    <Chip
      className={classes.chipRoot}
      size={size}
      style={{ background: "#FBE1B6" }}
      icon={<CalendarIcon />}
      label={getConsultationCalendarLabel(startDate, endDate)}
    />
  );
};

export default ConsultationScheduled;
