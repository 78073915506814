import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  button: {
    boxSizing: "border-box",
    padding: "12px 0",
    width: "33%!important",
    fontWeight: 500,
    fontSize: "34px",
    lineHeight: 1,
    color: "rgba(0, 0, 0, 0.87)",
    "@media(max-width: 375px)": {
      fontSize: "30px",
      padding: "6px 0",
    },
  },

  borderStyles: {
    borderRadius: 0,

    "&:nth-child(3n)": {
      borderRight: "none",
    },

    "&:nth-child(n + 10)": {
      borderBottom: "none",
    },
  },

  phoneInput: {
    margin: 0,
    fontSize: "16px",
    letterSpacing: "0.56px",
    lineHeight: "28px",
    fontWeight: 500,
    borderRadius: "3px",
    height: "56px",
    width: "100%",
    boxSizing: "border-box",
    paddingLeft: theme.spacing(1),
  },

  fieldContainer: {
    marginTop: theme.spacing(2),
  },

  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },

  panelWrapper: {
    display: "flex",
    padding: "10px 0",
    flexWrap: "wrap",
    width: "100%",
    maxHeight: "100%",
    gap: "0px",
  },

  inputWrapper: {
    marginBottom: theme.spacing(2),
  },

  textWrapper: {
    margin: 0,
    lineHeight: "28px",
    fontWeight: 500,
    padding: "20px 10px 15px",
    textAlign: "center",
    fontSize: "26px",
    letterSpacing: 2.14,
    minHeight: "28px",
  },

  subText: {
    margin: 0,
    fontWeight: 500,
    fontSize: "10px",
    textTransform: "uppercase",
    letterSpacing: 0.89,
    lineHeight: "20px",
    minHeight: "10px",
  },

  buttonTextWrap: {
    display: "flex",
    flexDirection: "column",
  },

  backspaceIcon: {
    width: "20px",
    height: "20px",
    padding: "0",
    color: "rgba(0, 0, 0, 0.87)",
  },

  backspaceIconBtn: {
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    width: "33%",
    height: "100%",
    boxSizing: "border-box",
    padding: "12px 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default useStyles;
