import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import * as Sentry from "@sentry/react";
import VideoModule from "./VideoModule";
import AddFamilyMember from "./views/AddFamilyMember";
import AdminView from "./views/Admin";
import AdminConfig from "./views/AdminConfig/AdminConfig";
import AuthCodeLogin from "./views/AuthCodeLogin";
import AuthVerification from "./views/AuthVerification";
import AuthVerifyConfirmView from "./views/AuthVerifyConfirmView";
import CellPhoneInstantActivation from "./views/Authorization/CellPhoneInstantActivation";
import LoginView from "./views/Authorization/LoginView";
import CampaignListUpload from "./views/CampaignsManagement";
import ChangePasswordView from "./views/ChangePasswordView";
import ConciergeChat from "./views/ConciergeChat";
import ConfirmPatientPhone from "./views/ContextAwareLinking/ConfirmPatientPhone";
import PhoneNumberAuth from "./views/ContextAwareLinking/Consent/PhoneNumberAuth";
import ThankYouPage from "./views/ContextAwareLinking/Consent/ThankYouPage";
import SmsPhoneVerification from "./views/ContextAwareLinking/SmsPhoneVerification/SmsPhoneVerification";
import DOTDriverLicenseReader from "./views/DOTForm/DOTDriverLicenseReader";
import DOTInitializerProvider from "./views/DOTForm/DOTInitializerProvider";
import DOTSmsPhoneVerification from "./views/DOTForm/DOTSmsPhoneVerification";
import DotFaq from "./views/DOTForm/faq/index";
import DotForm from "./views/DOTForm/index";
import MedicalExaminersView from "./views/DOTForm/med-examiners/MedicalExaminers";
import DOTFormSubmissions from "./views/DOTForm/staff/index";
import DOTSubmissionPage from "./views/DOTForm/submission/index";
import EndocrinologySurveysVerifyPhone from "./views/EndocrinologySurvey/EndocrinologySurveysVerifyPhone";
import EndocrinologyThankYouPage from "./views/EndocrinologySurvey/EndocrinologyThankYouPage";
import EndocrinologyFormSubmitted from "./views/EndocrinologySurvey/forms/EndocrinologyFormSubmitted";
import EndocrinologyFormSubmissionView from "./views/EndocrinologySurvey/forms/FormSubmissionView";
import NotFound from "./views/Error/NotFound";
import HtmlError from "./views/Error/htmlError";
import FamilyMemberInvite from "./views/FamilyMemberInvite";
import FamilyMemberMessages from "./views/FamilyMemberMessages";
// import FamilyMessagingSystem from "./views/FamilyMessagingSystem";
import FamilySelection from "./views/FamilySelection";
import Footer from "./views/Footer";
import ForgotPasswordView from "./views/ForgotPasswordView";
import FormEmbeddedView from "./views/Forms/FormEmbeddedView";
import FormSubmissionView from "./views/Forms/FormSubmissionView";
import FormSubmitted from "./views/Forms/FormSubmitted";
import GetCareView from "./views/GetCareView";
import HeaderBarView from "./views/HeaderBarView";
import HomePageRouter from "./views/HomePageRouter";
import IDVerifyStart from "./views/IDVerifyStart";
import LogoutView from "./views/LogoutView";
import MobileView from "./views/Mobile";
import NewAppointment from "./views/NewAppointment";
import OffHoursCheck from "./views/OffHoursCheck";
import OpenSchBooking from "./views/OpenSchBooking";
import PasswordlessLogin from "./views/PasswordlessLogin";
import ConversationListItemRoute from "./views/PatientConversations/ConversationListItemRoute";
import PatientConversations from "./views/PatientConversations/PatientConversations";
import PatientList from "./views/PatientList";
import ProviderList from "./views/ProviderList/ProviderList";
import QRVerification from "./views/QRVerification";
import ReferralEpicList from "./views/ReferralEpicList";
import ReferralList from "./views/ReferralList";
import VirtualSessionReport from "./views/Report/VirtualSessionReport";
import SendSurveys from "./views/SendSurveys/SendSurveyList";
import SendSurveyVerifyPhone from "./views/SendSurveys/SendSurveyVerifyPhone";
import SurveyFormSubmissionView from "./views/SendSurveys/forms/FormSubmissionView";
import SurveyFormSubmitted from "./views/SendSurveys/forms/FormSubmitted";
import SetUserPwdView from "./views/SetUserPwdView";
import SignupView from "./views/SignupView";
import SpecimenTracking from "./views/SpecimenTracking";
import SpecimenTrackingHistory from "./views/SpecimenTracking/views/History";
import SpecimenTrackingLocation from "./views/SpecimenTracking/views/Location";
import SpecimenTrackingPickFlow from "./views/SpecimenTracking/views/SpecimenPickFlow";
import SsoInit from "./views/SsoInit";
import SurveyConfigurationList from "./views/SurveyConfigurationList/SurveyConfigurationList";
import TeamMembers from "./views/TeamMembers";
import TestToken from "./views/TestToken";
import UserList from "./views/User/UserList";
import UserSearch from "./views/UserSearch";
import VaccineOAuthView from "./views/VaccineOAuthView";
import VaccineQuestionsView from "./views/VaccineQuestionsView";
import VaccineVerificationView from "./views/VaccineVerificationView";
import ConsoleVaccineCardView from "./views/VaccineVerificationView/ConsoleVaccineCardView";
import VouchVerified from "./views/VouchVerified";
import WebsiteSurveysVerifyCaptcha from "./views/WebsiteSurvey/WebsiteSurveysVerifyCaptcha";
import WebsiteThankYouPage from "./views/WebsiteSurvey/WebsiteThankYouPage";
import WebsiteFormSubmissionView from "./views/WebsiteSurvey/forms/WebsiteFormSubmissionView";
import WebsiteFormSubmitted from "./views/WebsiteSurvey/forms/WebsiteFormSubmitted";
import ConsoleHome from "./views/console/ConsoleHome";
import LocationPage from "views/DataCuration/LocationEditPage/index";
import NewLocation from "views/DataCuration/Locations/New/index";
import ProviderPage from "views/DataCuration/ProviderProfile/index";
import DataCurationPage from "views/DataCuration/index";
import DigitalImplantView from "views/DigitalImplant";
import SpecialistWaitingRoomView from "views/SpecialistWaitingRoom/index";
import SpecimenTrackingAddSpecimen from "views/SpecimenTracking/views/AddSpecimen/index";
import SpecimenTrackingHelpCenterView from "views/SpecimenTracking/views/HelpCenter";
import SpecimenTrackingReportAnIssueView from "views/SpecimenTracking/views/HelpCenter/ReportAnIssue";
import ProgramsPreJoinScreens from "./components/Consultation/OnlinePatientFlow";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import FindADoctor from "./components/FindADoctor";
import BookingOnline from "./components/FindADoctor/BookingOnline";
import DoctorBooking from "./components/FindADoctor/DoctorBooking/DoctorBooking";
import PreviousAppointment from "./components/FindADoctor/PreviousAppointment";
import StaffEndCall from "./components/Video/components/ StaffEndCall";
import VirtualSessionChat from "./components/VirtualSessionChat";
import VirtualVisitInvite from "./components/VirtualVisitInvite";
import { permissions } from "./components/shared/permissions";
import Appointments from "./views/Appointments/Appointments";
import AppointmentsDetail from "./views/Appointments/AppoitmentsDetails/AppoitmentsDetail";
import { Features } from "util/constants/config";
import { SpecimenTrackingReports } from "views/SpecimenTracking/views/Reports";
import { CONVERSATION_LINK, NESTED_CONVERSATION_LINK } from "./util/Links";
import { PrivateRoute, useAuth } from "./util/Security";
import { hasFeature } from "util/hasFeature";
import "./App.css";

const AppRoutes = () => {
  const isSpecimenTrackingEnabled = hasFeature(Features.ENABLE_SPECIMEN_TRACKING);
  const isReferralEpicEnabled = hasFeature(Features.ENABLE_REFERRAL_EPIC);
  const isDigitalImplantsEnable = hasFeature(Features.ENABLE_DIGITAL_IMPLANTS);

  const auth = useAuth();

  useEffect(() => {
    if (auth.user) {
      Sentry.setUser({ id: auth.user.userId });
    }
  }, [auth.user]);
  return (
    <>
      <HeaderBarView />
      <ErrorBoundary>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<HomePageRouter />} />
          <Route path="login" element={<LoginView />} />
          <Route path="logout" element={<LogoutView />} />
          <Route path="error/:errorNumber" element={<HtmlError />} />
          <Route path="signup" element={<SignupView />} />
          <Route path="mobile/*" element={<MobileView />} />
          <Route path="forgotpwd" element={<ForgotPasswordView />} />
          <Route path="getcare" element={<GetCareView />} />
          <Route path="vaccinequestions" element={<VaccineQuestionsView />} />
          <Route path="openschbooking" element={<OpenSchBooking />} />
          <Route path="ocsf/:onCallReferenceNumber" element={<SendSurveyVerifyPhone />} />
          <Route path="healthcredentials/verify/" element={<QRVerification />} />
          <Route path="authcode/:loginToken" element={<AuthCodeLogin />} />
          <Route path="activation/:loginToken" element={<AuthCodeLogin />} />
          <Route path="activation" element={<AuthCodeLogin />} />
          <Route path="/console/login" element={<PasswordlessLogin />} />
          <Route
            path="forms/:onCallReferenceNumber/phone/:phone/s/:hash"
            element={<SurveyFormSubmissionView />}
          />
          <Route path="doctors-survery/sent" element={<SurveyFormSubmitted />} />
          <Route path="offhourscheck" element={<OffHoursCheck />} />
          <Route path="instantactivation" element={<CellPhoneInstantActivation />} />
          <Route path="conciergechat" element={<ConciergeChat />} />
          <Route path="family-messaging-system/accept-invite" element={<FamilyMemberInvite />} />
          <Route
            path="/confirm-patient/verify-code/:consentId"
            element={<SmsPhoneVerification />}
          />
          <Route path="/confirm-patient/consent" element={<PhoneNumberAuth />} />
          <Route path="/confirm-patient/thankyou" element={<ThankYouPage />} />
          <Route
            path="/endocrinology/phone-consent"
            element={<EndocrinologySurveysVerifyPhone />}
          />
          <Route path="/endocrinology/thankyou" element={<EndocrinologyThankYouPage />} />
          <Route
            path="/endocrinology/f/:id/s/:token"
            element={<EndocrinologyFormSubmissionView />}
          />
          <Route path="/endocrinology/form-submitted" element={<EndocrinologyFormSubmitted />} />

          <Route path="/website-survey/thankyou" element={<WebsiteThankYouPage />} />
          <Route path="/website-survey" element={<WebsiteSurveysVerifyCaptcha />} />
          <Route
            path="/website-survey-form/f/:id/s/:token"
            element={<WebsiteFormSubmissionView />}
          />
          <Route path="/website-survey/form-submitted" element={<WebsiteFormSubmitted />} />
          <Route path="inquiryrequest/" element={<ProgramsPreJoinScreens />} />
          <Route
            path="inquiryrequest/:programName/:vsId/:page"
            element={<ProgramsPreJoinScreens />}
          />
          <Route
            path="inquiryrequest/:programName/screening"
            element={<ProgramsPreJoinScreens />}
          />
          <Route path="inquiryrequest/:programName/:vsId" element={<ProgramsPreJoinScreens />} />
          <Route path="inquiryrequest/:programName" element={<ProgramsPreJoinScreens />} />
          {/* Protected Routes */}
          <Route element={<PrivateRoute />}>
            <Route path="/console" element={<ConsoleHome />} />
            <Route path="admin" element={<AdminView />} />
            <Route path="oauth/authtorize/vaccine/:name" element={<VaccineOAuthView />} />
            <Route path="admin" element={<AdminView />} />
            <Route path="oauth/authtorize/vaccine/:name" element={<VaccineOAuthView />} />
            <Route path="changepwd" element={<ChangePasswordView />} />
            <Route
              path="console/consultation/:programtype"
              element={<SpecialistWaitingRoomView />}
            />
            <Route
              path="/digitalimplant"
              element={isDigitalImplantsEnable ? <DigitalImplantView /> : <Navigate to="/" />}
            />
            <Route path="form/:submission_id/:campaign_id" element={<FormEmbeddedView />} />
            <Route path="surveysubmitted" element={<FormSubmitted />} />
            <Route path="surveysubmitted/:id" element={<FormSubmitted />} />
            <Route path="setuserpwd" element={<SetUserPwdView />} />
            <Route path="appointments/:id" element={<AppointmentsDetail />} />
            <Route path={CONVERSATION_LINK} element={<PatientConversations />}>
              <Route path={NESTED_CONVERSATION_LINK} element={<ConversationListItemRoute />} />
            </Route>
            <Route path="id/start" element={<IDVerifyStart />} />
            <Route path="id/verify" element={<AuthVerification />} />
            <Route path="idverifyconfirm/:idverifyjobid" element={<AuthVerifyConfirmView />} />
            <Route path="vouchverified" element={<VouchVerified />} />
            <Route path="ssoinit" element={<SsoInit />} />
            <Route path="home" element={<ConciergeChat />} />
            <Route
              path="family-messaging-system/my-messages/:sessionId"
              element={<FamilyMemberMessages />}
            />
            <Route
              path="forms/:form_external_tracking_id/submissions/:submission_id"
              element={<FormSubmissionView />}
            />
            <Route path="console/usersearch" element={<UserSearch />} />
            <Route
              path={`console/${permissions.patient}/vaccinecard`}
              element={<ConsoleVaccineCardView />}
            />
            <Route path="virtual-session/invite/:vsId" element={<VirtualVisitInvite />} />
            <Route path="virtual-session/chat/:vsId" element={<VirtualSessionChat />} />
            <Route path={`/console/${permissions.referral}/list`} element={<ReferralList />} />
            <Route
              path={`/console/${permissions.referral}/epic/list`}
              element={isReferralEpicEnabled ? <ReferralEpicList /> : <Navigate to="/" />}
            />
            <Route
              path={`console/${permissions.immediatecare}/waitingroom`}
              element={<VideoModule />}
            />
            <Route
              path={`console/${permissions.virtualvisit}/waitingroom`}
              element={<VideoModule />}
            />
            <Route path={`console/${permissions.adhocVirtual}`} element={<VideoModule />} />
            <Route
              path={`console/${permissions.virtualvisit}/neuroondemand`}
              element={<VideoModule />}
            />
            <Route path={`console/${permissions.patient}/list`} element={<PatientList />} />
            <Route path={`console/${permissions.config}/list`} element={<AdminConfig />} />
            <Route
              path={`console/${permissions.providerSurvey}/send-survey`}
              element={<SendSurveys />}
            />
            <Route
              path={`console/${permissions.surveyConfig}`}
              element={<SurveyConfigurationList />}
            />
            <Route path={`console/${permissions.user}/list`} element={<UserList />} />
            <Route path={`console/${permissions.providerCellphone}`} element={<ProviderList />} />
            <Route path="console/virtual-session-report" element={<VirtualSessionReport />} />
            <Route
              path={`console/${permissions.campaigns}/mgmt`}
              element={<CampaignListUpload />}
            />

            <Route path="virtual-session/end-call" element={<StaffEndCall />} />
            <Route path="console/test-token" element={<TestToken />} />
            <Route path="patient-selection/:redirectPath" element={<FamilySelection />} />
            <Route path="patient-selection" element={<FamilySelection />} />
            <Route path="team-members" element={<TeamMembers />} />
            <Route path="family-member/create" element={<AddFamilyMember />} />
            <Route path="family-member/update/:user_id" element={<AddFamilyMember />} />
            <Route path="find-a-doctor" element={<FindADoctor />} />
            <Route path="booking-online" element={<BookingOnline />} />
            <Route path="new-appointment/:id" element={<DoctorBooking />} />
            <Route path="prev-appointment" element={<PreviousAppointment />} />
            <Route path="confirm-patient-phone" element={<ConfirmPatientPhone />} />

            <Route
              path={`/console/${permissions.specimenTracking}/history/:specimenId`}
              element={
                isSpecimenTrackingEnabled ? <SpecimenTrackingHistory /> : <Navigate to="/" />
              }
            />
            <Route
              path={`/console/${permissions.specimenTracking}/reports`}
              element={
                isSpecimenTrackingEnabled ? <SpecimenTrackingReports /> : <Navigate to="/" />
              }
            />
            <Route
              path={`/console/${permissions.specimenTracking}/location`}
              element={
                isSpecimenTrackingEnabled ? <SpecimenTrackingLocation /> : <Navigate to="/" />
              }
            />
            <Route
              path={`/console/${permissions.specimenTracking}/add-specimen`}
              element={
                isSpecimenTrackingEnabled ? <SpecimenTrackingAddSpecimen /> : <Navigate to="/" />
              }
            />
            <Route
              path={`/console/${permissions.specimenTracking}`}
              element={isSpecimenTrackingEnabled ? <SpecimenTracking /> : <Navigate to="/" />}
            />
            <Route
              path={`/console/${permissions.specimenTracking}/pick-flow`}
              element={
                isSpecimenTrackingEnabled ? <SpecimenTrackingPickFlow /> : <Navigate to="/" />
              }
            />
            <Route
              path={`/console/${permissions.specimenTracking}/help-center`}
              element={
                isSpecimenTrackingEnabled ? <SpecimenTrackingHelpCenterView /> : <Navigate to="/" />
              }
            />
            <Route
              path={`/console/${permissions.specimenTracking}/report-an-issue`}
              element={
                isSpecimenTrackingEnabled ? (
                  <SpecimenTrackingReportAnIssueView />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            {/* Protected Routes - PatientSelect */}
            <Route path="video" element={<VideoModule />} />
            <Route path="video/:virtual_session_id" element={<VideoModule />} />
            <Route path="videoinvite" element={<VideoModule fromInvite />} />
            <Route path="videoinvite/:virtual_session_id" element={<VideoModule fromInvite />} />
            <Route path="proofofvaccination" element={<VaccineVerificationView />} />
            <Route path="vaccinecard" element={<VaccineVerificationView />} />
            <Route path="appointments" exact element={<Appointments />} />
            <Route path="new-appointment" exact element={<NewAppointment />} />

            <Route path={`/${permissions.patient}/dot-form/faq`} exact element={<DotFaq />} />
            <Route
              path={`/${permissions.patient}/dot-form/form/:formId/:currentUserStep?`}
              exact
              element={<DotForm />}
            />
            <Route
              path={`/console/${permissions.dotForm}/dot-form-staff`}
              exact
              element={<DOTFormSubmissions />}
            />
            <Route
              path={`/console/${permissions.dotForm}/dot-form-staff/submission/:formId/:currentUserStep?`}
              exact
              element={<DOTSubmissionPage />}
            />
            <Route
              path={`/console/${permissions.config}/dot-medical-examiners`}
              element={<MedicalExaminersView />}
            />
            <Route
              path={`/${permissions.patient}/dot-form/sms-phone-verification`}
              exact
              element={<DOTSmsPhoneVerification />}
            />
            <Route
              path="/dot-form/license-verification/:formId"
              exact
              element={<DOTDriverLicenseReader />}
            />
            <Route path="/dot-form/initialize/:formId" exact element={<DOTInitializerProvider />} />
            <Route path={`/console/${permissions.dataGovernance}`} element={<DataCurationPage />} />
            <Route
              path={`/console/${permissions.dataGovernance}/provider/:providerId`}
              element={<ProviderPage />}
            />
            <Route
              path={`/console/${permissions.dataGovernance}/location/:locationId`}
              element={<LocationPage />}
            />
            <Route
              path={`/console/${permissions.dataGovernance}/add-location`}
              element={<NewLocation />}
            />
            {/* Page not found */}
            <Route path="*" element={<NotFound />} />
          </Route>

          {/* Family messaging Program is being deprecated. per ticket sc8497 */}
          {/* <PrivateRoute path={`/console/${permissions.familyJournal}/search`}>
          <FamilyMessagingSystem />
        </PrivateRoute> */}
        </Routes>
      </ErrorBoundary>
      <Footer />
    </>
  );
};

export default AppRoutes;
