import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { CLEAR_VIRTUAL_VISIT } from "../PatientDetails/Actions";
import Numpad from "./Numpad";
import CellPhone from "components/shared/CellPhone";
import SendInviteDialog from "components/shared/SendInviteDialog/SendInviteDialog";
import { useQuery } from "hooks/useQuery";
import { useIsMobile } from "views/Mobile/Hooks/hooks";
import { useApp } from "util/AppContext";
import { usePrograms } from "util/ProgramsContext";
import { getApiHost } from "util/api_helper";
import { GetCurrentProgram } from "util/programsHelpers";
import useStyles from "./DialerScreen.styles";
import camera from "static/svg/camera.svg";
import phoneMob from "static/svg/cellPhone.svg";
import chat from "static/svg/chat.svg";
import phone from "static/svg/phone.svg";

const ADHOC_PROGRAM_TYPE = "adhoc_virtual";

const DialerScreen = ({ handleClose }) => {
  const app = useApp();
  const { classes } = useStyles();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const query = useQuery();
  const { programId, departmentId } = usePrograms();
  const currentProgram = GetCurrentProgram();
  const cellPhoneQuery = query.get("cell_phone");

  const [cellPhone, setCellPhone] = useState("");
  const [showInviteModal, setShowInviteModal] = useState<boolean>(false);

  const scrollToBottom = () => {
    const anchor = document.getElementById("dialer-anchor");
    anchor?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (cellPhoneQuery) {
      setCellPhone(cellPhoneQuery);
    }
  }, [cellPhoneQuery]);

  useEffect(() => {
    if (cellPhone.length === 10) {
      scrollToBottom();
    }
  }, [cellPhone]);

  const phoneActionsDisabled = Boolean(!cellPhone || cellPhone.toString().length < 10);

  const handleInviteSend = () => {
    handleClose();
    setShowInviteModal(true);
  };

  const handleCreateVSession = async (sendInvite) => {
    try {
      const apiHost = getApiHost();
      const apiUrl = `${apiHost}/api/s/console/virtualvisit/create-virtual-session-for-staff`;
      const { data } = await axios.post(
        apiUrl,
        {
          cellPhone,
          send_invite: sendInvite,
          programId,
          queue: "registering",
        },
        {
          withCredentials: true,
        },
      );
      return data.data.id;
    } catch (err) {
      // @ts-ignore
      app.addError(err.response.data.description);
    }
  };

  const handleJoinVideoCall = async () => {
    const id = await handleCreateVSession(currentProgram?.program_type !== ADHOC_PROGRAM_TYPE);

    if (id) {
      handleClose();
      if (isMobile) {
        app.dispatch({
          type: CLEAR_VIRTUAL_VISIT,
        });
        navigate(`/video/${id}?programId=${programId}&departmentId=${departmentId}`);
      } else {
        window.open(
          `/video/${id}?programId=${programId}&departmentId=${departmentId}`,
          "_blank",
          "noopener, noreferrer",
        );
      }
    }
  };

  const handleConferenceCall = async () => {
    try {
      const id = await handleCreateVSession(false);
      if (id) {
        handleClose();
        const params = `?isConferenceCall=true&cell_phone=${cellPhone}&programId=${programId}&departmentId=${departmentId}`;
        if (isMobile) {
          app.dispatch({
            type: CLEAR_VIRTUAL_VISIT,
          });
          navigate(`/video/${id}${params}`);
        } else {
          window.open(`/video/${id}${params}`, "_blank", "noopener, noreferrer");
        }
        // eslint-disable-next-line no-useless-return
        return;
      }
    } catch (err) {
      console.log("Failed to call user to virtual visit, please try again.");
      app.addError(
        "Failed to conference call user in for virtual session, please contact support.",
      );
    }
  };

  return (
    <div>
      <SendInviteDialog
        open={showInviteModal}
        handleClose={() => setShowInviteModal(false)}
        defaultCellPhone={cellPhone}
      />
      <div className={classes.header}>
        <span>Virtual Visit Invite</span>
      </div>
      <div className={classes.container}>
        <div className={classes.textFieldContainer}>
          <CellPhone
            cellPhone={cellPhone}
            setCellPhone={setCellPhone}
            startAdornmentIcon={phone}
            required
          />
        </div>
        <Numpad cellPhone={cellPhone} setCellPhone={setCellPhone} />
        <div className={classes.actionsContainer}>
          <div className={classes.actionBtnContainer}>
            <button
              type="button"
              className={classes.actionBtn}
              disabled={phoneActionsDisabled}
              onClick={handleInviteSend}
              data-testid="invite-button"
              aria-label="Send Invite"
            >
              <img src={chat} alt="" />
            </button>
            <div className={classes.actionBtnLabel}>Send Invite</div>
          </div>
          <div className={classes.actionBtnContainer}>
            <button
              type="button"
              className={classes.actionBtn}
              onClick={handleJoinVideoCall}
              data-testid="video-call-button"
              disabled
              aria-label="Join Video Call"
            >
              <img src={camera} alt="" />
            </button>
            <div className={classes.actionBtnLabel}>Video Call</div>
          </div>
          <div className={classes.actionBtnContainer}>
            <button
              type="button"
              className={classes.actionBtn}
              onClick={handleConferenceCall}
              data-testid="phone-call-button"
              disabled
              aria-label="Phone Call"
            >
              <img src={phoneMob} alt="" />
            </button>
            <div className={classes.actionBtnLabel}>Phone Call</div>
          </div>
        </div>
        <div id="dialer-anchor" />
      </div>
    </div>
  );
};

export default DialerScreen;
