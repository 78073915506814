import React, { useEffect, useState } from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useProviderContext } from "../../ProviderContext";
import RichTextArea from "components/RichTextArea";
import useTranslation from "hooks/useTranslation";
import { ProviderGeneralInfoAndLocation, TabPaneProps } from "../../types";

const AdditionalInformationSection: React.FC<TabPaneProps> = ({
  shouldDisableForm,
}: TabPaneProps) => {
  const {
    providerGeneralInfoAndLocation: { additionalInformation },
    currentPayload,
    setCurrentPayload,
  } = useProviderContext();

  const [formData, setFormData] = useState({
    ...additionalInformation,
    professionalInterests: additionalInformation.professionalInterests || [],
  });

  const {
    web: {
      dataGovernance: { providerProfile },
    },
  } = useTranslation();

  const {
    generalInformation: {
      additionalInformation: { aboutLabel, areasOfFocus, videoUrl },
    },
  } = providerProfile;

  const handleChange = (key: string, e: any) => {
    const newValue = e?.target?.value || e;
    setFormData({
      ...formData,
      [key]: newValue,
    });

    if (newValue !== additionalInformation[key]) {
      setCurrentPayload((prev: ProviderGeneralInfoAndLocation) => ({
        ...(prev || {}),
        [key]: newValue,
      }));
    }
  };

  useEffect(() => {
    if (currentPayload === null) {
      setFormData({
        ...additionalInformation,
        professionalInterests: additionalInformation.professionalInterests || [],
      });
    }
  }, [currentPayload]);

  const [validLink, url] = (() => {
    try {
      const url = new URL(formData.videoUrl);
      return [true, url];
    } catch (err) {
      return [false, undefined];
    }
  })();

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justifyContent="center"
      style={{ padding: "24px 0 32px 0", width: "100%" }}
      data-testid="additional-information-section"
    >
      <Grid item xs={12} md={12}>
        <FormControl variant="outlined" fullWidth>
          <RichTextArea
            initialValue={formData?.about || ""}
            onChange={(content) => handleChange("about", content)}
            placeHolder={aboutLabel}
            readOnly={shouldDisableForm}
            data-cy="AdditionalInformation-aboutInput"
            data-testid="AdditionalInformation-aboutInput"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            multiple
            id="professionalInterests"
            options={[]}
            value={formData?.professionalInterests || []}
            freeSolo
            autoSelect
            renderTags={(value: readonly string[], getTagProps) =>
              value.map((option: string, index: number) => {
                const { key, ...tagProps } = getTagProps({ index });
                return <Chip label={option} key={key} {...tagProps} />;
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={areasOfFocus}
                placeholder="Add Professional Interests by pressing enter"
              />
            )}
            onChange={(_, newValue) => handleChange("professionalInterests", newValue)}
            disabled={shouldDisableForm}
            data-testid="AdditionalInformation-professionalInterestsInput"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            error={false}
            id="videoUrl"
            name="videoUrl"
            size="medium"
            value={
              formData?.videoUrl !== undefined
                ? formData.videoUrl
                : additionalInformation.videoUrl || ""
            }
            variant="outlined"
            label={videoUrl}
            onChange={(e) => handleChange("videoUrl", e.target.value)}
            disabled={shouldDisableForm}
            helperText={false}
            data-cy="AdditionalInformation-videoUrlInput"
            data-testid="AdditionalInformation-videoUrlInput"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12} sx={{ textAlign: "left" }}>
        {validLink ? (
          <a href={url!.toString()} rel="noopener noreferrer" target="_blank">
            <Typography variant="subtitle1">
              {url!.toString()} <OpenInNewIcon fontSize="inherit" />
            </Typography>
          </a>
        ) : (
          <Typography variant="subtitle1">Invalid video URL</Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default AdditionalInformationSection;
