import React, { useEffect, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";

const useStyles = makeStyles()((theme: Theme) => ({
  wrapForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "95%",
    margin: `${theme.spacing(0)} auto`,
  },
  wrapText: {
    width: "100%",
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: "100%",
    maxWidth: "100%",
  },
  flexFormControl: {
    minWidth: "100%",
    maxWidth: "100%",
    flexDirection: "row",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  quickPhrasesMenuItem: {
    maxWidth: "400px",
  },
}));

type Phrase = { phrase: string; id: number };

export const TextInput = ({ askQuestion, isLoading }) => {
  const { classes } = useStyles();
  const [prompt, setPrompt] = useState<string | undefined>("");
  const [quickPhrase] = useState<Phrase | null>(null);
  const [quickPhrasesList, setQuickPhrasesList] = useState<Phrase[]>([]);

  useEffect(() => {
    async function getQuickPhrases() {
      try {
        const url = API_ENDPOINTS.getAIssistantQuickPhrases;
        const { data } = await axiosClient.get(url);
        setQuickPhrasesList(data?.data);
      } catch (error) {
        console.error(error);
      }
    }
    getQuickPhrases();
    // eslint-disable-next-line
  }, []);

  const onPhraseSelected = (e) => {
    const { value } = e.target;
    const selectedPhrase: Phrase | null =
      quickPhrasesList.find((item: Phrase) => item?.id === value) || null;
    let promptValue = selectedPhrase?.phrase;
    if (prompt) {
      promptValue = promptValue?.replace("{diagnosis}", prompt);
    }
    setPrompt(promptValue);
  };

  const submit = (event) => {
    event.preventDefault();
    askQuestion(prompt);
    setTimeout(() => {
      setPrompt("");
    }, 1000);
  };

  return (
    <form className={classes.wrapForm} autoComplete="off" onSubmit={submit}>
      <FormControl className={classes.formControl} variant="outlined">
        <InputLabel id="quick-phrases-label">Quick Phrases</InputLabel>
        <Select
          labelId="quick-phrases-label"
          value={quickPhrase?.id || ""}
          id="phrases-bucket-dropdown"
          data-testid="quickPhrases-select"
          placeholder="Select a quick Phrase"
          data-cy="quickPhrases"
          autoWidth={false}
          label="Quick Phrases"
          onChange={onPhraseSelected}
        >
          {quickPhrasesList?.map((item: { phrase: string; id: number }) => {
            return (
              <MenuItem value={item?.id} key={item?.id} className={classes.quickPhrasesMenuItem}>
                {item?.phrase}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl className={classes.flexFormControl}>
        <TextField
          id="standard-text"
          label="Type patient diagnosis"
          value={prompt}
          className={classes.wrapText}
          variant="outlined"
          onChange={(e) => setPrompt(e.target.value)}
        />
        <Button
          variant="contained"
          type="submit"
          color="primary"
          className={classes.button}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size={24} /> : <SendIcon />}
        </Button>
      </FormControl>
    </form>
  );
};
