import React, { useState } from "react";
import { FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { waitingUsersTableStyles } from "../waitingUserTableStyles";
import { OrderBy } from "util/constants";

export type SortByOptions = {
  requestedStartBeg: { text: string; orderBy: OrderBy };
  startDttm: { text: string; orderBy: OrderBy };
};

export const SORT_BY_OPTIONS: SortByOptions = {
  requestedStartBeg: {
    text: "Call request",
    orderBy: OrderBy.requestedStartBeg,
  },
  startDttm: { text: "Request Submitted", orderBy: OrderBy.startDttm },
};

type SortByProps = {
  orderBy: OrderBy;
  setOrderBy: (orderBy: OrderBy) => void;
  // shouldDisplaySortInsideDropdown?: boolean;
};

const SortBy: React.FC<SortByProps> = ({ orderBy, setOrderBy }) => {
  const { classes } = waitingUsersTableStyles();
  const [open, setOpen] = useState(false);

  const handleClick = (value: OrderBy) => {
    setOpen(true);
    setOrderBy(value);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="sort-by-label" sx={{ color: "palette.text.secondary" }}>
        Sort By
      </InputLabel>
      <Select
        label="Sort By"
        labelId="sort-by-label"
        className="select-form-filter"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        value={orderBy}
        data-cy="sortBy"
        size="small"
      >
        {[SORT_BY_OPTIONS.requestedStartBeg, SORT_BY_OPTIONS.startDttm].map((option) => (
          <MenuItem
            onClick={() => handleClick(option.orderBy)}
            data-cy={option.orderBy}
            value={option.orderBy}
            key={option.orderBy}
          >
            <Typography variant="body1">{option.text}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SortBy;
