import React from "react";
import { values } from "lodash";
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import useTranslation from "hooks/useTranslation";

const useStyles = makeStyles()(() => ({
  radioSubtitle: { paddingLeft: "2rem", color: "gray", fontSize: 12 },
  radioGroup: {
    display: "flex",
    padding: "16px",
  },
}));
const LocationEnabler = ({ values, setFieldValue }) => {
  const { classes } = useStyles();
  const {
    web: {
      dataGovernance: {
        location: {
          common: { enableOnLocationFinder, enableasPracticeLocationProvider },
        },
      },
    },
  } = useTranslation();

  return (
    <>
      <div className={classes.radioGroup}>
        <FormControl variant="outlined" fullWidth>
          <Typography color="primary" align="left" style={{ fontWeight: 700, fontSize: "18px" }}>
            {enableOnLocationFinder.title}
          </Typography>
          <RadioGroup
            row
            aria-label="enableOnLocationFinder"
            name="enableOnLocationFinder"
            value={values?.enabledOnLocationFinder}
            onChange={(e) => setFieldValue("enabledOnLocationFinder", e.target.value)}
          >
            {enableOnLocationFinder.options.length > 0 &&
              enableOnLocationFinder.options.map(({ title, value, subtitle }, index) => (
                <div
                  style={{ display: "flex", flexDirection: "column", marginRight: "6em" }}
                  key={title + index.toString()}
                >
                  <FormControlLabel
                    value={value}
                    key={title}
                    control={<Radio />}
                    label={title}
                    style={{ marginBottom: 0 }}
                  />
                  <span className={classes.radioSubtitle}>{subtitle}</span>
                </div>
              ))}
          </RadioGroup>
        </FormControl>
      </div>
      <div className={classes.radioGroup}>
        <FormControl variant="outlined" fullWidth>
          <Typography color="primary" align="left" style={{ fontWeight: 700, fontSize: "18px" }}>
            {enableasPracticeLocationProvider.title}
          </Typography>
          <RadioGroup
            row
            aria-label="enableasPracticeLocationProvider"
            name="enableasPracticeLocationProvider"
            value={values?.enabledAsPracticeLocationProvider}
            onChange={(e) => setFieldValue("enabledAsPracticeLocationProvider", e.target.value)}
          >
            {enableasPracticeLocationProvider.options.length > 0 &&
              enableasPracticeLocationProvider.options.map(({ title, value, subtitle }, index) => (
                <div
                  style={{ display: "flex", flexDirection: "column" }}
                  key={title + index.toString()}
                >
                  <FormControlLabel
                    value={value}
                    key={title}
                    control={<Radio />}
                    label={title}
                    style={{ marginBottom: 0 }}
                  />
                  <span className={classes.radioSubtitle}>{subtitle}</span>
                </div>
              ))}
          </RadioGroup>
        </FormControl>
      </div>
    </>
  );
};

export default LocationEnabler;
