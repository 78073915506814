import { useQuery } from "react-query";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { QUERY_KEYS } from "util/queryKeys";
import {
  ProviderClinical,
  ProviderData,
  ProviderExperience,
  ProviderGeneralInfoAndLocation,
  ProviderNotes,
} from "views/DataCuration/ProviderProfile/types";

export const useGetProviderById = (providerId: string) => {
  const app = useApp();

  const {
    web: {
      common: { errorMsg },
    },
  } = useTranslation();

  const fetchProviderData = async (): Promise<ProviderData> => {
    const response = await axiosClient.get<ProviderData>(
      `${API_ENDPOINTS.dataGovernanceProviders}${providerId}`,
    );
    return response.data;
  };

  const {
    data: providerData,
    isLoading,
    isFetching,
    refetch,
  } = useQuery<ProviderData>({
    queryKey: [QUERY_KEYS.providerData, providerId],
    queryFn: fetchProviderData,
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: true,
    onError: () => app.addError(errorMsg),
  });

  if (!providerData) {
    return {
      providerData: null,
      providerGeneralInfoAndLocation: null,
      providerExperience: null,
      providerClinical: null,
      providerNotes: null,
      refetch,
      isLoading,
      isFetching,
    };
  }

  /* eslint-disable @typescript-eslint/naming-convention */
  const {
    about,
    firstName,
    middleName,
    lastName,
    suffix,
    gender,
    NPI,
    degrees,
    languages,
    title,
    practiceUrl,
    practiceGroups,
    networks,
    networkAffiliations,
    affiliatedHospitals,
    primaryCareProvider,
    specialtyProvider,
    visitTypes,
    additionalVisitTypes,
    professionalStatement,
    professionalInterests,
    videoUrl,
    insuranceAccepted,
    practiceLocations,
    relatedLocations,
    acceptingNewPatients,
    showInPMC,
    showCTAButton,
    ageGroupsSeen,
    externalId,
    education,
    certificates,
    providerType,
    specialties,
    listingNotes,
    internalNotes,
    external_ids: externalIds,
    scheduleIntervalDays,
    scheduleIntervalSameDayMinuteDelay,
    researchUrl,
    onlineBooking,
    onlineBookingUrlNewPatients,
    onlineBookingUrlExistingPatients,
    featuredHealthNews,
    specialtyServices,
    claimedSpecialties,
    conditionsSymptoms,
  } = providerData;
  /* eslint-enable @typescript-eslint/naming-convention */

  const providerGeneralInfoAndLocation: ProviderGeneralInfoAndLocation = {
    personalInformation: {
      firstName,
      middleName,
      lastName,
      suffix,
      gender,
      NPI,
      degrees,
      languages,
      title,
    },
    practice: {
      practiceUrl,
      practiceGroups,
      networks,
      networkAffiliations,
      affiliatedHospitals,
      practiceType: [primaryCareProvider, specialtyProvider],
      visitTypes,
      additionalVisitTypes,
    },
    additionalInformation: {
      about,
      professionalStatement,
      professionalInterests,
      videoUrl,
    },
    insuranceAccepted,
    practiceLocations,
    relatedLocations,
    schedulingInformation: {
      onlineBooking,
      onlineBookingUrlNewPatients,
      onlineBookingUrlExistingPatients,
      acceptingNewPatients,
      showInPMC,
      showCTAButton,
      ageGroupsSeen,
      clinicalContactInformation: null,
    },
    EHRIdentifiers: {
      sourceProviderId: null,
      sourceSystem: null,
      systemType: null,
    },
    appointmentPurposes: null,
  };

  const providerExperience: ProviderExperience = {
    education,
    certifications: certificates,
  };

  const providerClinical: ProviderClinical = {
    clinical: {
      primaryCareProvider,
      specialtyProvider,
      providerType,
      specialties,
      specialtyServices,
      claimedSpecialties,
      conditionsSymptoms,
    },
    conditionsAndSymptoms: null,
  };

  const providerNotes: ProviderNotes = {
    notes: {
      listingNotes,
      internalNotes,
    },
    additionalInformation: {
      clinicalContactInfo: null,
      externalId,
      echoProvider: null,
      ageValidationMinimun: null,
      ageValidationMaximum: null,
      bookingIntervalDuration: scheduleIntervalDays,
      sameDayLimit: scheduleIntervalSameDayMinuteDelay,
      researchUrl,
      socialMedialLinkUrl: null,
      featuredHealthNews,
      externalIds,
    },
  };

  return {
    providerData,
    providerGeneralInfoAndLocation,
    providerExperience,
    providerClinical,
    providerNotes,
    refetch,
    isLoading,
    isFetching,
  };
};
