import React, { useEffect, useState } from "react";
import { FormikHelpers, useFormik } from "formik";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { FETCH_VIRTUAL_VISITS, UPDATE_AND_SELECT_VIRTUAL_VISIT } from "../PatientDetails/Actions";
import { makeStyles } from "tss-react/mui";
import { User } from "components/Video/utils/types";
import { VirtualSessionQueue } from "util/constants";
import { useApp } from "util/AppContext";
import { usePrograms } from "util/ProgramsContext";
import { useAuth } from "util/Security";
import { UserTypes, axiosClient } from "util/api_helper";
import { useProgramType } from "util/programsHelpers";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  selectedVirtualVisitIds: number[];
  setSelectedVirtualVisitIds: (selectedVirtualVisitIds: number[]) => void;
  // eslint-disable-next-line
  queue: any;
};

const useStyles = makeStyles()(() => ({
  title: {
    fontWeight: 500,
  },
}));

const AssignToModal: React.FC<Props> = ({
  isOpen,
  handleClose,
  selectedVirtualVisitIds,
  setSelectedVirtualVisitIds,
  queue,
}) => {
  const { classes } = useStyles();
  const app = useApp();
  const { user } = useAuth();
  const { programId } = usePrograms();
  const { isVUC } = useProgramType();
  const currentUserId = Number(user.userId);

  const { virtualVisits, currentVirtualVisit } = app.vs_visits_state;

  const [assignStaffList, setAssignStaffList] = useState<User[]>([]);

  const userType = ((): string => {
    switch (queue) {
      case VirtualSessionQueue.REGISTERING:
        return UserTypes.REGISTRATION_STAFF;
      case VirtualSessionQueue.NURSE_TRIAGE:
        return UserTypes.NURSE;
      case VirtualSessionQueue.PHYSICIAN_VISIT:
        return UserTypes.PHYSICIAN;
      case VirtualSessionQueue.CHECKOUT:
        return UserTypes.REGISTRATION_STAFF;
      default:
        return UserTypes.STAFF;
    }
  })();

  type FormValues = {
    physicianId: number;
  };

  const initialValues: FormValues = { physicianId: currentUserId };

  const onSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    try {
      const url = "api/s/console/virtualvisit/sessions";
      const checkedSessions = selectedVirtualVisitIds.map((id) => ({
        id,
        currently_assigned_staff_user_id: values.physicianId,
      }));

      const { data } = await axiosClient.patch(url, checkedSessions);

      const changedSessions = virtualVisits.map(
        (item) => data.find((dataItem) => dataItem.id === item.id) || item,
      );
      app.dispatch({ type: FETCH_VIRTUAL_VISITS, payload: changedSessions });

      const changedCurrentSession = data.find(
        (dataItem) => currentVirtualVisit?.id === dataItem.id,
      );
      if (changedCurrentSession) {
        app.dispatch({ type: UPDATE_AND_SELECT_VIRTUAL_VISIT, payload: changedCurrentSession });
      }

      setSelectedVirtualVisitIds([]);
      handleClose();
    } catch (e) {
      app.addError("Something went wrong. Please contact support.");
    }
    actions.setSubmitting(false);
  };

  const { values, handleChange, handleSubmit, isSubmitting } = useFormik({
    initialValues,
    onSubmit,
  });

  useEffect(() => {
    const getAssignStaffList = async () => {
      try {
        const url = "/api/s/console/virtualvisitconsole/get-users";
        const { data } = await axiosClient.get(url, {
          params: { programId, ...(isVUC && { user_type: userType }) },
        });
        setAssignStaffList(data.data);
      } catch (e) {
        app.addError("Something went wrong. Please contact support.");
      }
    };
    getAssignStaffList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h6" className={classes.title}>
          Assign To
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form id="assignToModalForm" onSubmit={handleSubmit}>
          <TextField
            select
            variant="outlined"
            fullWidth
            name="physicianId"
            onChange={handleChange}
            defaultValue={currentUserId}
            value={values.physicianId}
          >
            <MenuItem value={currentUserId}>Me</MenuItem>
            {assignStaffList.map(({ id, first_name, last_name, email }) => {
              const fullName = [first_name, last_name].filter(Boolean).join(" ");

              return (
                id !== currentUserId && (
                  <MenuItem value={id} key={id}>
                    {fullName || email}
                  </MenuItem>
                )
              );
            })}
          </TextField>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={handleClose}>
          CANCEL
        </Button>
        <Button
          type="submit"
          form="assignToModalForm"
          variant="text"
          color="primary"
          disabled={isSubmitting}
        >
          ASSIGN
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignToModal;
