import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useProviderContext } from "../../ProviderContext";
import { useSearchLocations } from "hooks/dataCuration/useSearchLocations";
import { useStyles } from "../../../utils/styles";
import { PracticeLocation, TabPaneProps } from "../../types";

const ConfirmationDialog = (props) => {
  const { open, onClose, onConfirm, locationName = "" } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to remove {locationName}?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" data-testid="confirm-delete-provider">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const PracticeLocationSection: React.FC<TabPaneProps> = ({ shouldDisableForm = false }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const {
    providerGeneralInfoAndLocation: { practiceLocations },
    currentPayload,
    setCurrentPayload,
  } = useProviderContext();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [locationToDelete, setLocationToDelete] = useState<PracticeLocation | null>(null);

  const [selectedLocations, setSelectedLocations] = useState<PracticeLocation[]>([]);
  const [deletedLocations, setDeletedLocations] = useState<PracticeLocation[]>([]);
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);

  // Debounce effect waiting for user to stop typing
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchText);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);
  const { data, isLoading, isError } = useSearchLocations(debouncedSearchText);

  const locationSelectOptions = useMemo(
    () => data?.filter((location) => !deletedLocations.some((l) => l.id === location.id)) || [],
    [data, deletedLocations],
  );
  const shownLocations: PracticeLocation[] = useMemo(() => {
    const notDeleted = (practiceLocations || []).filter(
      (loc) => !deletedLocations.some((l) => l.id === loc.id),
    );
    const shownMap = new Map<string, PracticeLocation>();
    notDeleted.forEach((loc) => {
      shownMap.set(loc.id, loc);
    });
    selectedLocations.forEach((loc) => {
      shownMap.set(loc.id, loc);
    });
    return [...shownMap.values()].sort((a, b) => a.nameFull?.localeCompare(b.nameFull));
  }, [practiceLocations, deletedLocations, selectedLocations]);
  const updatePayload = useMemo(
    () => [
      ...selectedLocations.map(({ id }) => ({ id })),
      ...deletedLocations.map(({ id }) => ({ id, deleted: true })),
    ],
    [selectedLocations, deletedLocations],
  );

  useEffect(() => {
    if (!updatePayload.length) {
      return;
    }
    setCurrentPayload({
      ...(currentPayload ?? {}),
      practiceLocations: updatePayload,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePayload]);

  useEffect(() => {
    if (currentPayload === null) {
      setSelectedLocations([]);
      setDeletedLocations([]);
    }
  }, [currentPayload]);

  const selectLocation = (loc) => {
    const inDeleted = deletedLocations.findIndex((l) => l.id === loc.id);
    if (inDeleted > -1) {
      const newDeletedLocations = [...deletedLocations];
      newDeletedLocations.splice(inDeleted, 1);
      setDeletedLocations(newDeletedLocations);
    } else {
      setSelectedLocations([...selectedLocations, loc]);
    }
  };
  const deleteLocation = (loc) => {
    const inSelected = selectedLocations.findIndex((l) => l.id === loc.id);
    if (inSelected > -1) {
      const newSelectedLocations = [...selectedLocations];
      newSelectedLocations.splice(inSelected, 1);
      setSelectedLocations(newSelectedLocations);
    } else {
      setDeletedLocations([...deletedLocations, loc]);
    }
  };

  const navigateToLocation = (id: string) => {
    navigate(`/console/data-governance/location/${id}`);
  };

  return (
    <Grid
      container
      rowSpacing={2}
      alignItems="flex-start"
      justifyContent="flex-start"
      data-testid="PracticeLocationsSection"
    >
      <Grid item xs={12} md={12}>
        <FormControl variant="outlined" fullWidth sx={{ marginTop: 1 }} error={isError}>
          <Autocomplete
            id="find-location"
            options={locationSelectOptions}
            getOptionLabel={(option) => option.nameFull}
            value={null}
            loading={isLoading}
            inputValue={searchText}
            renderInput={(params) => <TextField {...params} label="Find Location" />}
            disabled={shouldDisableForm}
            onInputChange={(e, value) => setSearchText(value)}
            onChange={(e, value) => {
              if (!value) {
                return;
              }
              selectLocation(value);
              setSearchText("");
            }}
            data-cy="LocationsSection-SearchLocations"
            data-testid="LocationsSection-SearchLocations"
          />
        </FormControl>
      </Grid>
      {shownLocations?.length ? (
        <>
          {shownLocations.map((loc) => {
            const { id, nameFull, address } = loc;

            return (
              <Grid item xs={12} md={12} key={id}>
                <Card
                  className={classes.practiceLocation_cardRoot}
                  data-testid="practice-location-card"
                >
                  <CardContent>
                    <Typography
                      onClick={() => navigateToLocation(id)}
                      textAlign="left"
                      paddingBottom="8px"
                      className={classes.practiceLocation_cardTitle}
                    >
                      {nameFull}
                    </Typography>
                    <Typography textAlign="left">{address}</Typography>
                    {!shouldDisableForm && (
                      <CardActions sx={{ flexDirection: "row-reverse" }}>
                        <IconButton
                          disabled={shouldDisableForm}
                          onClick={() => {
                            setLocationToDelete(loc);
                            setDialogOpen(true);
                          }}
                          data-testid="delete-location"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </CardActions>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </>
      ) : (
        <Typography textAlign="left" color="textSecondary" style={{ margin: "2em 0" }}>
          No practice locations found
        </Typography>
      )}
      <ConfirmationDialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
          setLocationToDelete(null);
        }}
        onConfirm={() => {
          setDialogOpen(false);
          deleteLocation(locationToDelete);
          setLocationToDelete(null);
        }}
        locationName={locationToDelete?.nameFull}
      />
    </Grid>
  );
};

export default PracticeLocationSection;
