import React from "react";
import GenericWaitingList from "views/DataCuration/GenericWaitingList";
import { API_ENDPOINTS } from "util/Api_Endpoints";
import { usePrograms } from "util/ProgramsContext";

const SpecialistWaitingRoomView = () => {
  const { programId } = usePrograms();
  const urlParams = {
    page_num: 0,
    page_size: 30,
    order: "asc",
    order_by: "requested_start_beg",
    queue_view: "registering",
    filter_by: "all_patients",
    program_id: programId,
  };

  return programId !== 0 ? (
    <GenericWaitingList baseUrl={API_ENDPOINTS.get_awaiting_users_list} urlParams={urlParams} />
  ) : null;
};

export default SpecialistWaitingRoomView;
