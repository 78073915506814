import React from "react";
import Avatar from "@mui/material/Avatar";
import { deepOrange } from "@mui/material/colors";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  messageRow: {
    display: "flex",
  },
  messageRowRight: {
    display: "flex",
    justifyContent: "flex-end",
  },
  messageBlue: {
    position: "relative",
    marginLeft: "20px",
    marginBottom: "10px",
    padding: "10px",
    backgroundColor: "#043070",
    width: "60%",
    textAlign: "left",
    color: "white",
    font: "400 .9em 'Open Sans', sans-serif",
    border: "1px solid #97C6E3",
    borderRadius: "10px",
    "&:after": {
      content: "''",
      position: "absolute",
      width: "0",
      height: "0",
      borderTop: "15px solid #043070",
      borderLeft: "15px solid transparent",
      borderRight: "15px solid transparent",
      top: "0",
      left: "-15px",
    },
    "&:before": {
      content: "''",
      position: "absolute",
      width: "0",
      height: "0",
      borderTop: "17px solid #97C6E3",
      borderLeft: "16px solid transparent",
      borderRight: "16px solid transparent",
      top: "-1px",
      left: "-17px",
    },
  },
  messageOrange: {
    position: "relative",
    marginRight: "20px",
    marginBottom: "10px",
    padding: "10px",
    backgroundColor: "#EB8025",
    width: "60%",
    textAlign: "left",
    font: "400 .9em 'Open Sans', sans-serif",
    border: "1px solid #dfd087",
    borderRadius: "10px",
    "&:after": {
      content: "''",
      position: "absolute",
      width: "0",
      height: "0",
      borderTop: "15px solid #EB8025",
      borderLeft: "15px solid transparent",
      borderRight: "15px solid transparent",
      top: "0",
      right: "-15px",
    },
    "&:before": {
      content: "''",
      position: "absolute",
      width: "0",
      height: "0",
      borderTop: "17px solid #dfd087",
      borderLeft: "16px solid transparent",
      borderRight: "16px solid transparent",
      top: "-1px",
      right: "-17px",
    },
  },
  messageContent: {
    padding: 0,
    margin: 0,
  },
  messageTimeStampRight: {
    position: "absolute",
    fontSize: ".85em",
    fontWeight: 300,
    marginTop: "10px",
    bottom: "-3px",
    right: "5px",
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  avatarNothing: {
    color: "transparent",
    backgroundColor: "transparent",
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  displayName: {
    marginLeft: "20px",
  },
}));

export const MessageLeft = ({ message, timestamp = null, photoURL, displayName }) => {
  const currentMessage = message || "no message";
  const currentTimestamp = timestamp ?? "";
  const currentPhotoURL = photoURL ?? "";
  const currentDisplayName = displayName ?? "";
  const { classes } = useStyles();
  return (
    <div className={classes.messageRow}>
      <Avatar alt={currentDisplayName} className={classes.orange} src={currentPhotoURL} />
      <div>
        <div className={classes.displayName}>{currentDisplayName}</div>
        <div className={classes.messageBlue}>
          <div>
            <p className={classes.messageContent}>{currentMessage}</p>
          </div>
          <div className={classes.messageTimeStampRight}>{currentTimestamp}</div>
        </div>
      </div>
    </div>
  );
};

export const MessageRight = ({ message, timestamp = null }) => {
  const { classes } = useStyles();
  const currentMessage = message ?? "no message";
  const currentTimestamp = timestamp ?? "";
  return (
    <div className={classes.messageRowRight}>
      <div className={classes.messageOrange}>
        <p className={classes.messageContent}>{currentMessage}</p>
        <div className={classes.messageTimeStampRight}>{currentTimestamp}</div>
      </div>
    </div>
  );
};
