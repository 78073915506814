import React from "react";
import { camelCase, upperFirst } from "lodash";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { waitingUsersTableStyles } from "components/WaitingList/waitingUserTableStyles";
import { getQueueValues } from "util/constants";
import { useProgramTypeByPathname } from "util/programsHelpers";

type PatientReasonProps = {
  patientReason: string;
  setPatientReason: (queueView: string) => void;
};

const PatientReason = ({ patientReason, setPatientReason }: PatientReasonProps) => {
  const { classes } = waitingUsersTableStyles();
  const { isConsultation } = useProgramTypeByPathname();
  const queueValues = getQueueValues(isConsultation);

  const handleQueueViewChange = (event) => {
    setPatientReason(event.target.value);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-simple-select-label">Queue View</InputLabel>
      <Select
        label="Queue View"
        labelId="demo-simple-select-label"
        className="select-form-filter"
        id="demo-simple-select"
        data-cy="queueView"
        size="small"
        defaultValue="all"
        value={patientReason}
        onChange={(e) => handleQueueViewChange(e)}
      >
        {queueValues.map(({ value, label }) => (
          <MenuItem key={value} value={value} data-cy={upperFirst(camelCase(value))}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default PatientReason;
