import React from "react";
import { FormikHelpers, useFormik } from "formik";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { FETCH_VIRTUAL_VISITS, UPDATE_AND_SELECT_VIRTUAL_VISIT } from "../PatientDetails/Actions";
import { makeStyles } from "tss-react/mui";
import { Department } from "components/Video/utils/types";
import { useApp } from "util/AppContext";
import { usePrograms } from "util/ProgramsContext";
import { axiosClient } from "util/api_helper";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  selectedVirtualVisitIds: number[];
  setSelectedVirtualVisitIds: (selectedVirtualVisitIds: number[]) => void;
};

const useStyles = makeStyles()(() => ({
  title: {
    fontWeight: 500,
  },
}));

const AssignDepartmentModal: React.FC<Props> = ({
  isOpen,
  handleClose,
  selectedVirtualVisitIds,
  setSelectedVirtualVisitIds,
}) => {
  const { classes } = useStyles();
  const { departmentList }: { departmentList: Department[] } = usePrograms();
  const app = useApp();

  const { virtualVisits, currentVirtualVisit } = app.vs_visits_state;

  type FormValues = {
    departmentId: number;
  };

  const initialValues: FormValues = { departmentId: departmentList[0].id };

  const onSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
    try {
      const url = "api/s/console/virtualvisit/sessions";
      const checkedSessions = selectedVirtualVisitIds.map((id) => ({
        id,
        department_id: values.departmentId,
      }));

      const { data } = await axiosClient.patch(url, checkedSessions);
      const changedSessions = virtualVisits.map(
        (item) => data.find((dataItem) => dataItem.id === item.id) || item,
      );
      app.dispatch({ type: FETCH_VIRTUAL_VISITS, payload: changedSessions });

      const changedCurrentSession = data.find(
        (dataItem) => currentVirtualVisit?.id === dataItem.id,
      );
      if (changedCurrentSession) {
        app.dispatch({ type: UPDATE_AND_SELECT_VIRTUAL_VISIT, payload: changedCurrentSession });
      }

      setSelectedVirtualVisitIds([]);
      handleClose();
    } catch (e) {
      app.addError("Something went wrong. Please contact support.");
    }
    actions.setSubmitting(false);
  };

  const { values, handleChange, handleSubmit, isSubmitting } = useFormik({
    initialValues,
    onSubmit,
  });

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h6" className={classes.title}>
          Assign Department
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form id="assignDepartmentModalForm" onSubmit={handleSubmit}>
          <TextField
            select
            variant="outlined"
            fullWidth
            name="departmentId"
            onChange={handleChange}
            value={values.departmentId}
          >
            {departmentList.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </TextField>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={handleClose}>
          CANCEL
        </Button>
        <Button
          type="submit"
          form="assignDepartmentModalForm"
          variant="text"
          color="primary"
          disabled={isSubmitting}
        >
          ASSIGN
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignDepartmentModal;
