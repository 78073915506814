import React from "react";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { Button, InputLabel, Stack, Typography } from "@mui/material";
import { waitingUsersTableStyles } from "../waitingUserTableStyles";
import { ORDER } from "util/constants";

type OrderByProps = {
  order: ORDER;
  setOrder: (order: ORDER) => void;
};

const OrderBy: React.FC<OrderByProps> = ({ order, setOrder }) => {
  const { classes } = waitingUsersTableStyles();

  return (
    <Stack direction="column" spacing={0}>
      <InputLabel sx={{ fontSize: "10px", marginBottom: 0 }}>Order By</InputLabel>
      {order === ORDER.asc && (
        <Button
          type="button"
          data-testid="order-asc"
          variant="text"
          size="small"
          onClick={() => setOrder(ORDER.desc)}
          sx={{ paddingInlineStart: "10%", paddingInlineEnd: "5%", minWidth: "min-content" }}
        >
          <Typography variant="body2">ASC</Typography>
          <ArrowUpward fontSize="small" className={classes.arrow} />
        </Button>
      )}
      {order === ORDER.desc && (
        <Button
          type="button"
          data-testid="order-desc"
          variant="text"
          size="small"
          onClick={() => setOrder(ORDER.asc)}
          sx={{ paddingInlineStart: "10%", paddingInlineEnd: "5%", minWidth: "min-content" }}
        >
          <Typography variant="body2">DESC</Typography>
          <ArrowDownward fontSize="small" className={classes.arrow} />
        </Button>
      )}
    </Stack>
  );
};

export default OrderBy;
