import React, { Fragment } from "react";
import { Box, Divider, FormControl, Grid, TextField } from "@mui/material";
import { useProviderContext } from "../../../ProviderContext";
import useTranslation from "hooks/useTranslation";

const EducationEditModal: React.FC = () => {
  const {
    web: {
      dataGovernance: { providerProfile },
    },
  } = useTranslation();

  const {
    providerExperience: { education },
  } = useProviderContext();

  const {
    experience: {
      education: { institution, description, fieldOfStudy, degree, year },
    },
  } = providerProfile;

  const institutionLabel = institution;

  const handleChange = (key: string, e: any) => {
    console.log(key, e); // remove this later
  };
  return (
    <Grid
      container
      spacing={2}
      alignItems="flex-start"
      justifyContent="flex-start"
      data-testid="education-section"
      padding="24px 0 42px 0"
    >
      {education?.map(({ id, institution, educationType, otherEducationDesc, details }) => (
        <Fragment key={id}>
          <Grid item xs={12} md={4}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                error={false}
                id="institution"
                name="institution"
                size="medium"
                value={institution?.name}
                variant="outlined"
                label={institutionLabel}
                onChange={(e) => handleChange("", e)}
                disabled
                helperText={false}
                data-cy="EducationSection-InstitutionInput"
                data-testid="EducationSection-InstitutionInput"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                error={false}
                id="description"
                name="description"
                size="medium"
                value={otherEducationDesc || educationType}
                variant="outlined"
                label={description}
                onChange={(e) => handleChange("", e)}
                disabled
                helperText={false}
                data-cy="EducationSection-DescriptionInput"
                data-testid="EducationSection-DescriptionInput"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                error={false}
                id="fieldOfStudy"
                name="fieldOfStudy"
                size="medium"
                value={details?.fieldOfStudy}
                variant="outlined"
                label={fieldOfStudy}
                onChange={(e) => handleChange("", e)}
                disabled
                helperText={false}
                data-cy="EducationSection-FieldOfStudyInput"
                data-testid="EducationSection-FieldOfStudyInput"
              />
            </FormControl>
          </Grid>
          <Box width="100%" />
          {/* Section 2 start */}
          <Grid item xs={12} md={4}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                error={false}
                id="degree"
                name="degree"
                size="medium"
                value={details?.degree}
                variant="outlined"
                label={degree}
                onChange={(e) => handleChange("", e)}
                disabled
                helperText={false}
                data-cy="EducationSection-DegreeInput"
                data-testid="EducationSection-DegreeInput"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                error={false}
                id="year"
                name="year"
                size="medium"
                value={details?.year}
                variant="outlined"
                label={year}
                onChange={(e) => handleChange("", e)}
                helperText={false}
                disabled
                data-cy="PersonalInformation-YearInput"
                data-testid="PersonalInformation-YearInput"
              />
            </FormControl>
          </Grid>
          <Divider style={{ width: "100%", marginLeft: "16px", paddingTop: "16px" }} />
        </Fragment>
      ))}
    </Grid>
  );
};

export default EducationEditModal;
