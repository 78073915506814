import React from "react";
import Theme from "Theme";
import {
  Assignment,
  Assistant,
  ChatBubble,
  FindInPage,
  List,
  ListAlt,
  Notes,
  Search,
  Settings,
  Sms,
  SupervisorAccount,
  VerifiedUser,
  Videocam,
} from "@mui/icons-material";
import { permissions } from "../../../components/shared/permissions";
import { Features } from "util/constants/config";
import { hasFeature } from "util/hasFeature";
import specimenTrackingIcon from "static/svg/specimen-tracking-icon.svg";

export const nav = [
  {
    link: `/console/${permissions.adhocVirtual}`,
    text: "Adhoc Virtual Visits",
    key: "Adhoc Virtual Visits",
    icon: <Videocam htmlColor={Theme.palette.common.white} />,
    global: false,
    permissions: [permissions.adhocVirtual],
  },
  {
    link: `/console/${permissions.consultation}/neuroconsult`,
    text: "Neuroscience Inquiries",
    key: "Neuroscience Inquiries",
    icon: <ChatBubble htmlColor={Theme.palette.common.white} />,
    global: false,
    permissions: [permissions.consultation],
  },
  {
    link: `/console/${permissions.consultation}/physical-therapy`,
    text: "Physical Therapy & Rehab Inquiry",
    key: "Physical Therapy & Rehab Inquiry",
    icon: <ChatBubble htmlColor={Theme.palette.common.white} />,
    global: false,
    permissions: [permissions.consultation],
    hidden: !hasFeature(Features.PHYSICAL_THERAPY),
  },
  {
    link: `/console/${permissions.virtualvisit}/waitingroom`,
    text: "Virtual Urgent Care",
    key: "Waiting Room",
    icon: <Videocam htmlColor={Theme.palette.common.white} />,
    global: false,
    permissions: [permissions.virtualvisit, permissions.virtualvisitconsole],
  },
  {
    link: `/console/${permissions.immediatecare}/waitingroom`,
    text: "Virtual Immediate Care",
    key: "Virtual Immediate Care",
    icon: <Videocam htmlColor={Theme.palette.common.white} />,
    global: false,
    permissions: [permissions.immediatecare, permissions.virtualvisitconsole],
    hidden: !hasFeature(Features.IMMEDIATE_CARE),
  },
  {
    link: `/console/${permissions.patient}/vaccinecard`,
    text: "Vaccine Card Lookup",
    key: "Vaccine Card Lookup",
    icon: <Search htmlColor={Theme.palette.common.white} />,
    global: false,
    permissions: [permissions.patient],
  },
  {
    link: `/console/${permissions.referral}/list`,
    text: "Referral List",
    key: "Referral List",
    icon: <Notes htmlColor={Theme.palette.common.white} />,
    global: false,
    permissions: [permissions.referral],
  },
  {
    link: `/console/${permissions.patient}/list`,
    text: "Patient List",
    key: "Patient List",
    icon: <SupervisorAccount htmlColor={Theme.palette.common.white} />,
    global: false,
    permissions: [permissions.patient],
  },

  {
    link: "https://app.vouched.id/account/jobs",
    text: "ID verify Results",
    key: "Vouched",
    icon: <VerifiedUser htmlColor={Theme.palette.common.white} />,
    global: true,
    permissions: [permissions.patient],
  },

  // Family messaging Program is being deprecated. per ticket sc8497
  // {
  //   link: `/console/${permissions.familyJournal}/search`,
  //   text: "Family Messaging System",
  //   key: "Family Messaging System",
  //   icon: <Sms htmlColor={Theme.palette.common.white} />,
  //   global: false,
  //   permissions: [permissions.familyJournal],
  // },
  // Program is commented till we test integration with virtual urgent care and adhoc

  // {
  //   link: `/console/${permissions.virtualvisit}/neuroondemand`,
  //   text: "Neuro On Call",
  //   key: "Neuro On Call",
  //   icon: <AddIcon htmlColor={Theme.palette.common.white} />,
  //   global: false,
  //   permissions: [permissions.virtualvisit],
  // },
  {
    link: `/console/${permissions.config}/list`,
    text: "Quick Phrases List",
    key: "Quick Phrases List",
    icon: <List htmlColor={Theme.palette.common.white} />,
    global: false,
    permissions: [permissions.config],
  },
  {
    link: `/console/${permissions.user}/list`,
    text: "Staff User Mgmt",
    key: "Staff User Mgmt",
    icon: <SupervisorAccount htmlColor={Theme.palette.common.white} />,
    global: false,
    permissions: [permissions.user],
  },
  {
    link: `/console/${permissions.config}/list`,
    text: "Admin Config",
    key: "Admin Config",
    icon: <Settings htmlColor={Theme.palette.common.white} />,
    global: false,
    permissions: [permissions.config],
  },
  {
    link: `/console/${permissions.campaigns}/mgmt`,
    text: "Campaign Mgmt",
    key: "Campaign Mgmt",
    icon: <Notes htmlColor={Theme.palette.common.white} />,
    global: false,
    permissions: [permissions.campaigns],
  },
  {
    link: `/console/${permissions.providerCellphone}`,
    text: "Provider Cellphones",
    key: "Provider Cellphones",
    icon: <SupervisorAccount htmlColor={Theme.palette.common.white} />,
    global: false,
    permissions: [permissions.providerCellphone],
  },
  {
    link: `/console/${permissions.providerSurvey}/send-survey`,
    text: "Send Survey",
    key: "Send Survey",
    icon: <Sms htmlColor={Theme.palette.common.white} />,
    global: false,
    permissions: [permissions.providerSurvey],
  },
  {
    link: `/console/${permissions.specimenTracking}`,
    text: "Specimen Tracking",
    key: "Specimen Tracking",
    icon: (
      <img
        src={specimenTrackingIcon}
        alt="Specimen Tracking"
        style={{ width: "24px", height: "24px" }}
      />
    ),
    global: false,
    permissions: [permissions.specimenTracking],
    hidden: !hasFeature(Features.ENABLE_SPECIMEN_TRACKING),
  },
  {
    link: null,
    text: "My AIssistant",
    key: "My AIssistant",
    icon: <Assistant htmlColor={Theme.palette.common.white} />,
    global: false,
    permissions: [
      permissions.config,
      permissions.providerSurvey,
      permissions.providerCellphone,
      permissions.campaigns,
    ], // todo: add a permission to all
    hidden: !hasFeature(Features.ENABLE_MYAISSISTANT),
    hasModal: true,
  },
  {
    link: `/console/${permissions.dotForm}/dot-form-staff`,
    text: "DOT Physicals",
    key: "DOT Physicals",
    icon: <Assignment htmlColor={Theme.palette.common.white} />,
    global: false,
    permissions: [permissions.dotForm],
    hidden: !hasFeature(Features.ENABLE_DOT_FORM),
  },
  {
    link: `/console/${permissions.referral}/epic/list`,
    text: "Epic Referral",
    key: "Epic  Referral",
    icon: <ListAlt htmlColor={Theme.palette.common.white} />,
    global: false,
    permissions: [permissions.referral],
    hidden: !hasFeature(Features.ENABLE_REFERRAL_EPIC),
  },
  {
    link: `/console/${permissions.config}/dot-medical-examiners`,
    text: "DOT Medical Examiners",
    key: "DOT Medical Examiners",
    icon: <Assignment htmlColor={Theme.palette.common.white} />,
    global: false,
    permissions: [permissions.config],
    hidden: !hasFeature(Features.ENABLE_DOT_FORM),
  },
  {
    link: `/console/${permissions.dataGovernance}`,
    text: "Data Governance",
    key: "Data Governance",
    icon: <FindInPage htmlColor={Theme.palette.common.white} />,
    global: false,
    permissions: [permissions.location, permissions.dataGovernance],
    hidden: !hasFeature(Features.ENABLE_DATA_GOVERNANCE),
  },
];
