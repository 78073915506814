/* eslint-disable */
import { useCallback, useEffect, useRef } from "react";
import { permissions } from "../../../shared/permissions";
import notificationSound from "./audio/notification.mp3";
import { CONNECT_VIRTUAL_VISIT } from "../useVirtualVisitReducer/Actions";
import { useQuery } from "hooks/useQuery";
import { useApp } from "../../../../util/AppContext";
import { isMobile } from "../../../../util/deviceUtils";

const useChimeNotification = (data, queueView) => {
  const app = useApp();
  const audio = new Audio(notificationSound);
  const currentWaitingListLength = useRef(0);
  const query = useQuery();
  const currentPatients = useRef(app?.vs_visits_state?.virtualVisits);
  const prevWaitingListLength = useRef(0);
  const newPatientsIds = (data?.data || []).map((newPatient) => newPatient.id);
  const patientId = query.get("id");

  const currentPatientsIds = currentPatients.current.map((currPatient) => currPatient.id);
  const newPatientId = newPatientsIds.filter((id) => !currentPatientsIds.includes(id));
  const isOfSameQueue =
    queueView === "all" ||
    (!!currentPatients.current.length &&
      currentPatients.current.every((cp) => cp.queue === queueView));

  if (typeof Notification === "undefined") {
    return { callNotification: () => {} };
  }

  const callNotification = useCallback(async () => {
    if (!isMobile && Notification) {
      if (Notification.permission === "granted") {
        if (currentWaitingListLength.current > prevWaitingListLength.current && isOfSameQueue) {
          if ("serviceWorker" in navigator) {
            navigator.serviceWorker
              .register(
                `${process.env.PUBLIC_URL}/service-workers/waitingRoomNotificationServiceWorker.js`,
              )
              .then((registration) => {
                audio.play();
                registration.showNotification("New patient", {
                  body: `http://${window.location.hostname}/console/${permissions.virtualvisit}/waitingroom?id=${newPatientId[0]}`,
                  tag: "newPatientNotification",
                });
              })
              .catch(function (err) {
                console.log("ServiceWorker registration failed: ", err);
              });
          }
          prevWaitingListLength.current = currentWaitingListLength.current;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isMobile]);

  useEffect(() => {
    if (patientId && app.vs_visits_state.virtualVisits.length > 0) {
      const parsedPatientId = parseInt(patientId);
      app.dispatch({
        type: CONNECT_VIRTUAL_VISIT,
        payload: parsedPatientId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId, app.dispatch, app.vs_visits_state.virtualVisits.length]);

  useEffect(() => {
    if (!isMobile && Notification) {
      if (!("Notification" in window)) {
        app.addError("This browser does not support desktop notification");
      }
      Notification.requestPermission().then((permission) => {
        if (permission !== "granted") {
          app.addError("Notification are blocked");
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    currentPatients.current = data?.data || [];
    if (data?.data?.length > prevWaitingListLength.current) {
      prevWaitingListLength.current = currentWaitingListLength.current;
      currentWaitingListLength.current = data?.data.length;
    } else {
      prevWaitingListLength.current = data?.data?.length;
      currentWaitingListLength.current = data?.data?.length;
    }
  }, [data]);
  return { callNotification };
};

export default useChimeNotification;
