import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import { Button, Paper, Step, StepLabel, Stepper, Typography } from "@mui/material";
import AddressVerificationStep from "./steps/AddressVerificationStep";
import DriverSignatureStep from "./steps/DriverSignatureStep";
import DriverSuccessStep from "./steps/DriverSuccessStep";
import HealthHistoryStep from "./steps/HealthHistoryStep";
import PersonalInformationStep from "./steps/PersonalInformationStep";
import RelationshipToDriverStep from "./steps/RelationshipToDriverStep";
import {
  DEMOGRAPHIC_INITIAL_STATE,
  HEALTH_HISTORY_INITIAL_STATE,
  HealthHistoryQuestions,
  IData,
  StepChangeProps,
} from "./types/constants";
import { permissions } from "components/shared/permissions";
import useTranslation from "hooks/useTranslation";
import { getSteps } from "./utils";
import { useApp } from "util/AppContext";
import { HttpStatusCode } from "util/HttpStatusCode";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { useIsMobile } from "util/deviceUtils";
import useStyles from "./styles/forms.styles";

interface Medication {
  brand_names: string;
  medication_name: string;
  status: string;
}

function getStepContent(
  step: number,
  data: IData,
  onChange: (
    name: string,
    event: React.ChangeEvent<any> | string | boolean | number | undefined,
  ) => void,
  handleStepChange: (stepInfo: StepChangeProps) => void,
  formRef: React.RefObject<HTMLFormElement>,
  loading: boolean,
  formId: string | undefined,
) {
  const commonSteps = [
    <PersonalInformationStep
      key="information-step"
      formData={data}
      handleChange={onChange}
      handleStepChange={handleStepChange}
    />,
    <AddressVerificationStep
      key="addressVerification-step"
      formData={data}
      handleChange={onChange}
      handleStepChange={handleStepChange}
    />,
    <RelationshipToDriverStep
      key="relationshipToDriver-step"
      formData={data}
      handleChange={onChange}
    />,
    <HealthHistoryStep
      key="healthistory-step"
      formData={data}
      handleChange={onChange}
      handleStepChange={handleStepChange}
      formRef={formRef}
      isLoading={loading}
    />,
    <DriverSignatureStep
      key="driverSignature-step"
      formData={data}
      handleChange={onChange}
      handleStepChange={handleStepChange}
      formRef={formRef}
      formId={formId}
    />,
    <DriverSuccessStep key="success-step" formData={data} handleChange={onChange} />,
  ];

  return commonSteps[step] || "Unknown step";
}

interface Props {
  getSubmissionComponentsSteps?: (
    step: number,
    values: IData,
    medicalRecords: Set<string> | undefined,
    onChange: (name: string, event: string) => void,
    handleStepChange?: (stepInfo: StepChangeProps) => void,
    formRef?: React.RefObject<HTMLFormElement>,
    loading?: boolean,
    setShouldDisableContinueButton?: (shouldDisableContinueButton: boolean) => void,
  ) => JSX.Element | "Unknown step";
  getSubmissionSteps?: string[];
}

const DOTForm: React.FC<Props> = ({ getSubmissionComponentsSteps, getSubmissionSteps }) => {
  const { classes } = useStyles({
    alignLeft: false,
  });
  const app = useApp();
  const [activeStep, setActiveStep] = useState(0);
  const [medicalRecords, setMedicalRecords] = useState<Set<string> | undefined>(new Set());
  const [shouldDisableContinueButton, setShouldDisableContinueButton] = useState<boolean>(false);
  const { formId, currentUserStep } = useParams();
  const isStaff = !!getSubmissionSteps;
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");

  useEffect(() => {
    if (isStaff) {
      const url = `${API_ENDPOINTS.fetch_staff_medication_statement}/${userId}?status=active`;

      const fetchMedicationStatement = async () => {
        try {
          setLoading(true);
          const result = await axiosClient.get(url);
          const list: Medication[] = result.data;
          setMedicalRecords(
            new Set(
              list
                .map((medication) => medication.medication_name)
                .sort((a, b) => a.localeCompare(b)),
            ),
          );
        } catch (error) {
          console.error("Error fetching medication statements:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchMedicationStatement();
    }
    const found =
      document.querySelector(`#dot-form input:not(:disabled):not([aria-hidden="true"])`) ||
      document.querySelector(`#dot-form [tabindex="0"]`);
    (found as HTMLInputElement)?.focus?.();
  }, [activeStep]);

  useEffect(() => {
    setActiveStep((current) => (currentUserStep ? Number(currentUserStep) : current));
  }, [currentUserStep]);

  const [personalInformation, setPersonalInformation] = useState(DEMOGRAPHIC_INITIAL_STATE);
  const [driverRelationShip, setDriverRelationShip] = useState("DRIVER");
  const [healthHistory, setHealthHistory] = useState(HEALTH_HISTORY_INITIAL_STATE);
  const [medicalDetermination, setMedicalDetermination] = useState(null);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [unsavedStateWarningId, setUnsavedStateWarningId] = useState<number | null>(null);

  useEffect(() => {
    if (unsavedStateWarningId) {
      return () => {
        app.closeSnackbar(unsavedStateWarningId);
        setUnsavedStateWarningId(null);
      };
    }
  }, [app, unsavedStateWarningId]);

  const navigate = useNavigate();
  const {
    web: {
      common: { continueStep, reset, finish, back, progressSaved, unsavedProgressWarning },
      dotForm: {
        patient: {
          addressVerification: { faqText },
        },
      },
    },
  } = useTranslation();

  const formRef = useRef<HTMLFormElement>(null);

  const steps = getSubmissionSteps ?? getSteps();
  const isMobile = useIsMobile();

  const processHistoryData = (data: HealthHistoryQuestions[]) => {
    if (data && Object.keys(data).length !== 0) {
      return Object.assign(
        {},
        ...data.map((item: HealthHistoryQuestions, index: number) => {
          if (item?.answer) {
            return {
              [index]: {
                answer: item?.answer,
                text: item?.text,
                additional_information_external_id: item?.additional_information_external_id,
              },
            };
          }
          return {};
        }),
      );
    }
    return {};
  };

  const sanitizePersonalInformation = (personalInformation: any) => {
    return {
      ...personalInformation,
      email: personalInformation.email === "" ? null : personalInformation.email,
    };
  };

  const saveStepProgress = async (
    { personalInformation, healthHistory, driverRelation },
    shouldMoveToTheNextStep = true,
  ) => {
    let history = {};
    if (healthHistory) {
      history = Array.isArray(healthHistory?.healthHistory)
        ? processHistoryData(healthHistory?.healthHistory)
        : healthHistory?.healthHistory;
    }
    const driverHistory = { ...healthHistory };
    driverHistory.healthHistory = history || {};

    const sanitizedPersonalInformation = sanitizePersonalInformation(personalInformation);

    const url = isStaff ? API_ENDPOINTS.dotPatchStepsStaff : API_ENDPOINTS.dotPatchSteps;
    const currentStep = steps[activeStep + 1];

    try {
      setLoading(true);
      const result = await axiosClient.patch(url, {
        dotFormId: formId,
        demographicInfo: sanitizedPersonalInformation,
        driverRelation,
        healthHistory: driverHistory,
        currentStep,
      });

      if (unsavedStateWarningId) {
        app.closeSnackbar(unsavedStateWarningId);
        app.enqueueSnackbar(progressSaved, { variant: "success" });
        setUnsavedStateWarningId(null);
      }

      if (result.data?.error?.message) {
        app.enqueueSnackbar(result.data.error.message, { variant: "error" });
        return { success: false };
      }

      if (shouldMoveToTheNextStep) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }

      return { success: true };
    } catch (err) {
      console.error(err);
      if (!unsavedStateWarningId) {
        let msg = unsavedProgressWarning;
        const response = (err as any)?.response;

        if (response?.status === HttpStatusCode.NOT_FOUND) {
          msg = response.data?.description || msg;
        }

        const warningId = app.enqueueSnackbar(msg, {
          variant: "warning",
          persist: true,
        });

        setUnsavedStateWarningId(warningId);
      }

      return { success: false };
    } finally {
      setLoading(false);
    }
  };

  /**
   * This function will be triggered when the user clicks on the next button
   * for the first time healthHistory is empty so we need to initialize with an empty object
   * when we are filling the carrousel the form is an array so we need to transform it to object of objects
   * thats why we need processHistoryData
   * @param formData - object with the data from formik form
   */
  const handleNext = async (formData: any) => {
    if (activeStep === steps.length - 1) {
      if (isStaff) {
        navigate(`/console/${permissions.dotForm}/dot-form-staff`);
      } else {
        navigate(`/`);
      }
    }
    if (activeStep < steps.length - 1) {
      try {
        await saveStepProgress(formData);
      } catch (error) {
        if (isStaff) {
          navigate(`/console/${permissions.dotForm}/dot-form-staff`);
        } else {
          navigate(`/`);
        }
      }
    }
    window.scrollTo({ top: 0 });
  };

  const handleMedicalDetermination = async ({ medicalDetermination }) => {
    try {
      const payload = medicalDetermination;
      if (!payload?.data) {
        payload.data = {};
      }
      await axiosClient.patch(`${API_ENDPOINTS.dotPatchMedicalDetermination}/${formId}`, payload);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } catch (error) {
      app.addError("Error saving medical determination");
    }
  };

  // we condition the   medical determination to be saved only when the active step is 1 and we are in staff side
  const onSaveClicked = (data: any) => {
    if (isStaff && activeStep === 1) {
      handleMedicalDetermination(data);
    } else {
      handleNext(data);
    }
  };

  const { values, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      personalInformation,
      healthHistory,
      medicalDetermination,
      driverRelation: driverRelationShip,
      submissionStatus,
    },
    onSubmit: onSaveClicked,
    enableReinitialize: true,
  });

  const handleBack = () => {
    if (isStaff && activeStep === 0) {
      navigate(`/console/${permissions.dotForm}/dot-form-staff`);
    }
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
    window.scrollTo({ top: 0 });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getPersonalInformation = async () => {
    try {
      setLoading(true);
      const url = isStaff ? `api/s/console/dot-form/${formId}` : `api/s/dot-form/${formId}`;
      const {
        data: { demographicInfo, healthHistory, medicalDetermination, driverRelation, status },
      } = await axiosClient.get(url);

      setPersonalInformation(demographicInfo);
      setHealthHistory({
        ...healthHistory,
      });
      setMedicalDetermination(medicalDetermination);
      setDriverRelationShip(driverRelation || "DRIVER");
      setSubmissionStatus(status);
    } catch (error) {
      console.log(error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPersonalInformation();

    window.scrollTo({
      top: 0,
    });
  }, [activeStep]);

  const onChange = useCallback(
    (name: string, event: React.ChangeEvent<any> | string | boolean | number | undefined) => {
      let value;
      if (
        typeof event === "string" ||
        typeof event === "boolean" ||
        typeof event === "number" ||
        typeof event === "undefined"
      ) {
        value = event;
      } else if (event && typeof event === "object" && "target" in event) {
        value = event.target.value;
      } else {
        value = undefined;
      }
      setFieldValue(name, value);
    },
    [setFieldValue],
  );

  const onHandleStepChange = async ({
    shouldDisableContinueButton = false,
    saveProgress,
    shouldReloadFormData,
    shouldMoveToTheNextStep = true,
  }: StepChangeProps) => {
    setShouldDisableContinueButton(shouldDisableContinueButton);
    let saveResult = { success: true };

    if (saveProgress) {
      shouldMoveToTheNextStep = false;
      saveResult = await saveStepProgress(values, shouldMoveToTheNextStep);
    }

    if (shouldReloadFormData) {
      await getPersonalInformation();
    }

    return saveResult.success;
  };

  return (
    <div className={classes.dotForm_mainWrapper} data-testid="DOTForm">
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        className={`${isMobile ? classes.stepperMobile : classes.stepperDesktop} hide-on-print`}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{isMobile ? null : <h6>{label}</h6>}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <form
        onSubmit={handleSubmit}
        className={classes.dotForm_mainWrapper}
        ref={formRef}
        id="dot-form"
      >
        {getSubmissionComponentsSteps
          ? getSubmissionComponentsSteps(
              activeStep,
              values,
              medicalRecords,
              onChange,
              onHandleStepChange,
              formRef,
              loading,
              setShouldDisableContinueButton,
            )
          : getStepContent(
              activeStep,
              values,
              onChange,
              onHandleStepChange,
              formRef,
              loading,
              formId,
            )}
        {!loading && (
          <div className={classes.actionsButtonsContainer}>
            {activeStep !== steps.length - 1 && (
              <Button
                disabled={!isStaff && activeStep === 0}
                onClick={handleBack}
                data-cy="dot-form-steps-back-button"
                data-testid="dot-form-steps-back-button"
              >
                {back}
              </Button>
            )}
            <Button
              variant={activeStep === steps.length - 1 ? "outlined" : "contained"}
              color={activeStep === steps.length - 1 ? undefined : "primary"}
              type="submit"
              data-cy="dot-form-steps-next-button"
              data-testid="dot-form-steps-next-button"
              disabled={shouldDisableContinueButton}
              className="hide-on-print"
              fullWidth={isMobile}
            >
              {activeStep === steps.length - 1 ? finish : continueStep}
            </Button>
          </div>
        )}

        {activeStep === steps.length && (
          <Paper square elevation={0}>
            <Button onClick={handleReset}>{reset}</Button>
          </Paper>
        )}
      </form>
      {!loading && (
        <Typography variant="body1" className={classes.faqLink}>
          <Link to={`/${permissions.patient}/dot-form/faq`}>{faqText}</Link>
        </Typography>
      )}
    </div>
  );
};

export default DOTForm;
