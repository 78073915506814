/* eslint-disable */
import React, { createContext, useCallback, useContext, useState } from "react";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { isRNApp } from "./RNWebView";
import { useMobileDFDReducer } from "store/reducers/MobileDFD/mobileDFDReducer";
import { usePlaySoundsReducer } from "store/reducers/PlaySounds/PlaySoundsReducer";
import { useSpecimenTrackingReducer } from "store/reducers/SpecimenTracking/specimenTrackingReducer";
import { usePatientStepsReducer } from "../components/Video/hooks/usePatientStepsReducer/usePatientStepsReducer";
import { useVirtualVisitReducer } from "../components/Video/hooks/useVirtualVisitReducer/useVirtualVisitReducer";
import { useIsMobile } from "util/deviceUtils";

// If you're using react-router for navigation

export const AppContext = createContext();
AppContext.displayName = "AppContext";

function useProvideApp() {
  const url = new URL(window.location.href);
  const embeddedParam = url.searchParams.get("embedded");
  let embeddedDefault = false;
  if ((embeddedParam && embeddedParam === "true") || isRNApp) {
    embeddedDefault = true;
  }
  const isMobile = useIsMobile();
  const [videoFinished, setVideoFinished] = useState(false);
  const [embedded, setEmbedded] = useState(embeddedDefault); // indicates app is iframed into outer shell page
  const [vs_visits_state, dispatch] = useVirtualVisitReducer();
  const [patient_steps_state, patient_steps_dispatch] = usePatientStepsReducer();
  const [specimen_tracking_state, specimen_tracking_dispatch] = useSpecimenTrackingReducer();
  const [mobileDFD_data_state, mobileDFD_data_dispatch] = useMobileDFDReducer();
  const [playSounds_state, playSounds_dispatch] = usePlaySoundsReducer();
  const [waitingListTimeoutKey, setWaitingListTimeoutKey] = useState(null);
  const [syncClient, setSyncClient] = useState(null);
  const [dotFormDriversLicenseData, setDotFormDriversLicenseData] = useState(null);

  const addError = useCallback(
    (msg) => enqueueSnackbar(msg, { variant: "error", autoHideDuration: isMobile ? 6000 : 3000 }),
    [],
  );

  const addWarning = useCallback((msg) => enqueueSnackbar(msg, { variant: "warning" }), []);

  const addInfoMsg = useCallback(
    (msg) => enqueueSnackbar(msg, { variant: "info", autoHideDuration: 2000 }),
    [],
  );

  const addSuccessMsg = useCallback(
    (msg) =>
      enqueueSnackbar(msg, {
        variant: "success",
        autoHideDuration: 2000,
        style: { color: "white" },
      }),
    [],
  );

  const setEmbeddedMode = (embeddedMode) => {
    setEmbedded(embeddedMode);
  };

  const isEmbedded = () => {
    if (embedded) {
      return true;
    }
    return false;
  };

  const getDriversLicenseData = () => {
    return dotFormDriversLicenseData;
  };

  const setDriversLicenseData = (data) => {
    setDotFormDriversLicenseData(data);
  };

  return {
    addError,
    addWarning,
    addInfoMsg,
    addSuccessMsg,
    closeSnackbar,
    enqueueSnackbar,
    videoFinished,
    setVideoFinished,
    setEmbeddedMode,
    isEmbedded,
    vs_visits_state,
    dispatch,
    patient_steps_state,
    patient_steps_dispatch,
    specimen_tracking_state,
    specimen_tracking_dispatch,
    mobileDFD_data_state,
    mobileDFD_data_dispatch,
    playSounds_state,
    playSounds_dispatch,
    waitingListTimeoutKey,
    setWaitingListTimeoutKey,
    syncClient,
    setSyncClient,
    getDriversLicenseData,
    setDriversLicenseData,
  };
}

export function ProvideApp({ children }) {
  const app = useProvideApp();
  return <AppContext.Provider value={app}>{children}</AppContext.Provider>;
}

export function useApp() {
  return useContext(AppContext);
}
