/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate } from "react-router-dom";
import { Checkbox, Chip, TableCell, TableRow, Typography } from "@mui/material";
import { useStyles } from "./utils/styles";

const transformStatus = (row, key) => {
  const response = { label: "", bgColor: "" };
  if (!row[key] && (row.status === "ACCEPTED" || row.status === "REJECTED")) {
    response.label = "Disabled";
    response.bgColor = "#fcd9d6";
    return response;
  }
  switch (row.status) {
    case "ACCEPTED":
      response.label = "Enabled";
      response.bgColor = "#C3CDE4";
      break;
    case "NEEDS_REVIEW":
      response.label = "Needs Review";
      response.bgColor = "#fcd9d6";
      break;
    default:
      response.label = "-";
      response.bgColor = "#fcd9d6";
      break;
  }
  return response;
};

const TableCells = ({
  row,
  colDefs,
  isItemSelected,
  labelId,
  handleClick,
  linkTo,
  allowSelection,
  cellWidth,
  handleModalOpen,
}) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  return (
    <TableRow
      hover
      aria-checked={isItemSelected}
      tabIndex={-1}
      key={row.id}
      selected={isItemSelected}
    >
      {allowSelection && (
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            inputProps={{ "aria-labelledby": labelId }}
            onClick={(event) => handleClick(event, row.id)}
          />
        </TableCell>
      )}

      {colDefs.map((column) => {
        return (
          <TableCell
            key={column.id}
            component="th"
            id={labelId}
            scope="row"
            padding="normal"
            width={cellWidth}
          >
            {(() => {
              if (column.isModal) {
                return (
                  <a
                    style={{ cursor: "pointer", color: "#1f248b" }}
                    data-testid="location-id"
                    onClick={() => handleModalOpen(row)}
                    href="#"
                  >
                    {row[column.id]}
                  </a>
                );
              }
              if (column.isLink) {
                if (column.id === "name") {
                  return (
                    <button
                      onClick={() => navigate(`/console/data-governance/${linkTo}/${row.id}`)}
                      className={classes.linkRow}
                      data-testid="linkRow"
                      type="button"
                    >
                      {row.lastName}, {row.firstName}
                      {row.middleName ? ` ${row.middleName}` : ""}
                    </button>
                  );
                }
                return (
                  <button
                    onClick={() => navigate(`/console/data-governance/${linkTo}/${row.id}`)}
                    className={classes.linkRow}
                    data-testid="linkRow-2"
                    type="button"
                  >
                    {row[column.id]}
                  </button>
                );
              }
              if (column.id === "specialties") {
                if (Array.isArray(row.specialties)) {
                  const primarySpecialty = row.specialties
                    .filter((specialty) => specialty.isPrimarySpecialty === true)
                    .map(({ specialty }) => specialty.name);

                  return primarySpecialty.length > 0 ? primarySpecialty : "–";
                }
                return "–";
              }

              if (column.id === "practiceGroups") {
                if (Array.isArray(row.practiceGroups)) {
                  return row.practiceGroups.map((practiceGroup) => practiceGroup.name).join(", ");
                }
                return "–";
              }

              if (column.id === "locationFinder" || column.id === "providerFinder") {
                const result = transformStatus(row, column.id);
                return (
                  <Chip size="medium" style={{ background: result.bgColor }} label={result.label} />
                );
              }

              if (row[column.id] === null) {
                return <Typography>-</Typography>;
              }

              return row[column.id];
            })()}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default TableCells;
