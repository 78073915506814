import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useProviderContext } from "../../ProviderContext";
import { useGetClaimedSpecialties } from "hooks/dataCuration/useGetClaimedSpecialties";
import { useGetSpecialtyServices } from "hooks/dataCuration/useGetSpecialtyServices";
import useTranslation from "hooks/useTranslation";
import { ProviderClinical, TabPaneProps } from "../../types";

const ClinicalSection: React.FC<TabPaneProps> = ({ shouldDisableForm }: TabPaneProps) => {
  const {
    web: {
      dataGovernance: {
        providerProfile: {
          clinical: {
            clinical: {
              practiceTypeTitle,
              providerTypeTitle,
              primarySpecialityTitle,
              primarySpecialityHelperText,
              claimedSpecialityHelperText,
              secondarySpecialityTitle,
              specialtyServicesLabel,
              claimedSpecialityTitle,
            },
          },
        },
      },
    },
  } = useTranslation();

  const {
    providerClinical: { clinical },
    currentPayload,
    setCurrentPayload,
  } = useProviderContext();

  const { specialtyServicesList } = useGetSpecialtyServices();
  const { claimedSpecialtiesList } = useGetClaimedSpecialties();

  const primarySpecialties = clinical.specialties
    .filter((specialty) => specialty.isPrimarySpecialty)
    .map((item) => ({ id: item.specialty.id, name: item.specialty.name }));

  const secondarySpecialties = clinical.specialties
    .filter((item) => !item.isPrimarySpecialty)
    .map((item) => ({ id: item.specialty.id, name: item.specialty.name }));

  const [formData, setFormData] = useState({
    ...clinical,
  });

  const handleAutocompleteChange = (key: string, newValue: any[]) => {
    let transformedValue = newValue;

    if (key === "claimedSpecialties") {
      transformedValue = newValue.map((name) => ({ name }));
    }

    const currentValue = formData[key] || [];
    const removedItems = currentValue.filter(
      (item: any) =>
        !transformedValue.some((newItem: any) =>
          key === "claimedSpecialties" ? newItem.name === item.name : newItem.id === item.id,
        ),
    );

    const addedItems = transformedValue.filter(
      (item: any) =>
        !currentValue.some((oldItem: any) =>
          key === "claimedSpecialties" ? oldItem.name === item.name : oldItem.id === item.id,
        ),
    );

    setFormData((prevData) => ({
      ...prevData,
      [key]: transformedValue,
    }));

    if (removedItems.length > 0) {
      setCurrentPayload((prev: ProviderClinical) => ({
        ...(prev || {}),
        [key]: [
          ...(prev?.[key] || []),
          ...removedItems.map((item: any) => ({
            ...item,
            deleted: true,
          })),
        ],
      }));
    }

    if (addedItems.length > 0) {
      setCurrentPayload((prev: ProviderClinical) => ({
        ...(prev || {}),
        [key]: [...(prev?.[key] || []).filter((item: any) => !item.deleted), ...addedItems],
      }));
    }
  };

  const handleCheckboxChange = (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setFormData({
      ...formData,
      [key]: checked,
    });

    setCurrentPayload((prev: ProviderClinical) => ({
      ...(prev || {}),
      [key]: checked,
    }));
  };

  useEffect(() => {
    if (currentPayload === null) {
      setFormData({
        ...clinical,
      });
    }
  }, [currentPayload]);

  return (
    <Grid
      container
      spacing={2}
      alignItems="flex-start"
      justifyContent="flex-start"
      padding="16px 0 42px 0"
      data-testid="clinical-section"
    >
      <Grid item xs={12} md={12}>
        <div style={{ textAlign: "start" }}>
          <h5
            style={{
              fontFamily: "'Alegreya Sans', sans-serif",
              fontSize: "20px",
              fontWeight: 600,
              color: "rgb(4, 48, 112)",
            }}
          >
            {practiceTypeTitle}
          </h5>
        </div>
        <FormGroup
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: "0.5rem",
          }}
          data-testid="practiceType"
        >
          <FormControlLabel
            control={
              <Checkbox
                data-cy="Primary Care-cyName"
                data-testid="Primary Care"
                checked={formData.primaryCareProvider}
                name="Primary Care"
                disabled={shouldDisableForm}
                onChange={handleCheckboxChange("primaryCareProvider")}
              />
            }
            label="Primary Care"
          />
          <FormControlLabel
            control={
              <Checkbox
                data-cy="Specialty Care-cyName"
                data-testid="Specialty Care"
                checked={formData.specialtyProvider ? formData.specialtyProvider : ""}
                name="Specialty Care"
                disabled={shouldDisableForm}
                onChange={handleCheckboxChange("specialtyProvider")}
              />
            }
            label="Specialty Care"
          />
        </FormGroup>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="systemType-label" disabled={shouldDisableForm}>
            {providerTypeTitle}
          </InputLabel>
          <Select
            data-testid="clinical-section-provider-types-systemType"
            labelId="systemType"
            label={providerTypeTitle}
            value={clinical.providerType || ""}
            disabled={shouldDisableForm}
            onChange={() => {}}
            style={{ textAlign: "start" }}
          >
            <MenuItem value={clinical.providerType}>{clinical.providerType}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            multiple
            options={primarySpecialties.length ? primarySpecialties : []}
            getOptionLabel={(option) => option.name || ""}
            value={primarySpecialties.length ? primarySpecialties : []}
            disabled
            renderInput={(params) => (
              <TextField
                {...params}
                label={primarySpecialityTitle}
                variant="standard"
                helperText={primarySpecialityHelperText}
                inputProps={{ "data-cy": "primarySpecialityInput" }}
              />
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            data-testid="clinical-section-provider-types-autocomplete"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            multiple
            id="clinical-section-provider-types-autocomplete-2"
            data-testid="clinical-section-provider-types-autocomplete-2"
            disabled
            options={secondarySpecialties.length ? secondarySpecialties : []}
            value={secondarySpecialties.length ? secondarySpecialties : []}
            getOptionLabel={(option) => option.name || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={secondarySpecialityTitle}
                placeholder="Select Provider Type"
                inputProps={{ "data-cy": "secondarySpecialityInput" }}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={12}>
        <Autocomplete
          multiple
          id="clinical-section-claimed-specialities-autocomplete"
          data-testid="clinical-section-claimed-specialities-autocomplete"
          disabled={shouldDisableForm}
          options={claimedSpecialtiesList?.map((option) => option.name) || []}
          freeSolo
          forcePopupIcon
          value={(formData.claimedSpecialties || []).map((item) => item.name)}
          onChange={(event, newValue) => handleAutocompleteChange("claimedSpecialties", newValue)}
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => {
              const { key, ...tagProps } = getTagProps({ index });
              return <Chip variant="filled" label={option} key={key} {...tagProps} />;
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={claimedSpecialityTitle}
              placeholder="Create or select Claimed Specialties"
              helperText={claimedSpecialityHelperText}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            multiple
            id="clinical-section-provider-specialtyServices"
            data-testid="clinical-section-provider-specialtyServices"
            disabled={shouldDisableForm}
            options={specialtyServicesList || []}
            value={formData.specialtyServices || []}
            onChange={(event, newValue) => handleAutocompleteChange("specialtyServices", newValue)}
            getOptionLabel={(option) => option.name || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={specialtyServicesLabel}
                placeholder="Select Specialty Services"
              />
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default ClinicalSection;
