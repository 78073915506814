import { Theme, alpha } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const dataGovernanceStyle = makeStyles()((theme: Theme) => ({
  mobileApp: {
    display: "block",
    position: "relative",
    height: "100vh",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  smScreenScroll: {
    boxShadow: "1px 0px 0px rgba(0, 0, 0, 0.14)",
    position: "relative",
    background: alpha(theme.palette.primary.main, 0.1),
    height: `calc(100vh - ${theme.headerHeight})`,
    overflowY: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    flexGrow: 1,
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    [theme.breakpoints.down("md")]: {
      display: "block",
      height: `calc(100vh - ${theme.headerHeight})`,
      overflowY: "scroll",
    },
  },
  tabs: {
    position: "fixed",
    bottom: 0,
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    border: "1px solid rgba(0,0,0,0.11)",
  },
  waitingRoomContainer: {
    boxShadow: "1px 0px 0px rgba(0, 0, 0, 0.14)",
    position: "relative",
    background: theme.palette.common.white,
    height: `calc(100vh - ${theme.headerHeight})`,
    zIndex: 1,
  },
  appBar: {
    boxShadow: "0px -1px 0px rgba(0, 0, 0, 0.12)",
    background: theme.palette.common.white,
    position: "absolute",
    bottom: 0,
  },
  physicianQueue: {
    height: `calc(100vh - ${theme.headerHeight})`,
    display: "grid",
    gridTemplateColumns: "25% 75%",
    position: "relative",
    zIndex: 1,
  },
  waitingRoomCont: {
    boxShadow: "1px 0px 0px rgba(0, 0, 0, 0.14)",
    position: "relative",
    background: theme.palette.common.white,
    height: `calc(100vh - ${theme.headerHeight})`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    height: 140,
    width: 100,
  },
  dashboard: {
    height: `calc(100vh - ${theme.headerHeight})`,
    boxShadow: "0px -1px 0px rgba(0, 0, 0, 0.14)",
    borderTop: "1px solid rgba(0,0,0,0.01)",
  },
  physician: {
    position: "absolute",
    width: "100%",
    bottom: "0",
  },
  avatar: {
    height: "36px",
    width: "36px",
    background: theme.palette.grey[600],
    borderRadius: "50%",
  },
  layer: {
    zIndex: 1,
  },
}));
