import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import AddressNormalizationModal from "../../AddressNormalizationModal";
import { useLocationContext } from "../../LocationContext";
import AddressSection from "./AddressSection";
import ContactInfoSection from "./ContactInfoSection";
import NameSection from "./NameSection";
import OtherInfoSection from "./OtherInfoSection";
import { ProvidersAtThisLocationSection } from "./ProvidersAtThisLocationSection";
import EditableComponent from "views/DataCuration/EditableComponent";
import GenericModal from "views/DataCuration/common/GenericModal";
import { useStyles } from "../../utils/styles";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { ModalComponent } from "views/DataCuration/utils/commontTypes";
import { EditableComponentProps } from "views/DataCuration/ProviderProfile/types";

const LocationPageTab: React.FC = () => {
  const app = useApp();
  const { classes } = useStyles();
  const { locationId } = useParams<{ locationId: string }>();
  const {
    currentPayload,
    setCurrentPayload,
    currentlyEditing,
    setCurrentlyEditing,
    selectedTab,
    refetch,
  } = useLocationContext();
  const [disableEditButton, setDisableEditButton] = useState<boolean>(false);
  const [disableSaveModalButton, setDisableSaveModalButton] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [sectionModalComponentBody, setSectionModalComponentBody] =
    useState<ModalComponent | null>();

  const [sections, setSections] = useState({
    name: true,
    address: true,
    providersAtThisLocation: true,
    contactInfo: true,
    otherInfo: true,
  });

  const [isEditing, setIsEditing] = useState({
    name: false,
    address: false,
    providersAtThisLocation: false,
    contactInfo: false,
    otherInfo: false,
  });

  const [isLoading, setIsLoading] = useState({
    name: false,
    address: false,
    providersAtThisLocation: false,
    contactInfo: false,
    otherInfo: false,
  });

  const leftPanelConfiguration: EditableComponentProps[] = [
    {
      title: "Name",
      slug: "name",
      component: NameSection,
    },
    {
      title: "Address",
      slug: "address",
      showActionButton: true,
      customAction: false,
      component: AddressSection,
      modalComponent: {
        component: (
          <AddressNormalizationModal onNormalizationError={() => setDisableSaveModalButton(true)} />
        ),
        title: "Address Verification",
        subtitle:
          "The address you entered was adjusted to follow standard formatting. Please review the validated address below and save, or cancel to make changes.",
      },
    },
    {
      title: "Contact Info",
      slug: "contactInfo",
      component: ContactInfoSection,
      showActionButton: true,
      customAction: false,
    },
    {
      title: "Other Info",
      slug: "otherInfo",
      component: OtherInfoSection,
      showActionButton: true,
      customAction: false,
    },
  ];

  const rightPanelConfiguration: EditableComponentProps[] = [
    {
      title: "Providers at this Location",
      slug: "providersAtThisLocation",
      component: ProvidersAtThisLocationSection,
    },
  ];

  const handleOpenModal = (slug: string) => {
    const getModalComponent = leftPanelConfiguration.find(
      (config) => config.slug === slug,
    )?.modalComponent;
    if (getModalComponent) {
      setSectionModalComponentBody(getModalComponent);
    }
    setOpenModal(!!getModalComponent);
    return !!getModalComponent;
  };

  const onDisableEditButton = (status: boolean) => {
    setDisableEditButton(status);
  };

  const onCancelSection = () => {
    if (typeof currentlyEditing === "string") {
      setIsEditing((prev) => ({
        ...prev,
        [currentlyEditing]: false,
      }));
      setSections((prev) => ({
        ...prev,
        [currentlyEditing]: true,
      }));
    }
    setCurrentPayload(null);
    setSectionModalComponentBody(null);
    setCurrentlyEditing(null);
    onDisableEditButton(false);
  };

  const sectionRequiredKeysValidations = (
    validations: {
      section: string;
      key: string;
      value: any;
    }[],
  ) => {
    let result = true;
    validations?.forEach(({ section, key, value }) => {
      if (section === "name" && key === "shortName" && !value) {
        app.addError("A short name is required.");
        result = false;
      }
    });
    return result;
  };

  const cleanUp = (slug: string) => {
    onCancelSection();
    setIsLoading((prev) => ({
      ...prev,
      [slug]: false,
    }));
  };

  const doPatch = async (slug: string, customizedPayload?: any) => {
    if (
      // @ts-ignore @typescript-eslint/dot-notation
      sectionRequiredKeysValidations([
        {
          section: slug,
          key: "shortName",
          value: currentPayload?.generalInfo?.name?.nameShort,
        },
      ])
    ) {
      try {
        const response = await axiosClient.patch(
          `${API_ENDPOINTS.dataGovernanceLocations}/${locationId}`,
          customizedPayload ? { ...customizedPayload } : { ...currentPayload },
        );
        if (response?.data?.error) {
          throw new Error(response.data.error);
        }
        app.addInfoMsg(response.data.message);
      } catch (error: any) {
        if (error?.response?.data?.description) {
          app.addError(error.response.data.description);
        } else if (error?.response?.data?.error) {
          app.addError(error?.response?.data?.error);
        } else if (error?.message) {
          app.addError(error.message);
        } else {
          app.addError("Error updating location. Please try again.");
        }
      } finally {
        await refetch();
        cleanUp(slug);
      }
    } else {
      cleanUp(slug);
    }
  };

  const createPayloadForPatch = () => {
    if (!currentPayload?.generalInfo?.address) {
      return null;
    }

    const { buildingName, streetAddress2, ...otherAddressFields } =
      currentPayload.generalInfo.address;

    const hasOnlyBuildingNameOrStreetAddress2 =
      (buildingName || streetAddress2) &&
      Object.keys(otherAddressFields).every((key) => !currentPayload.generalInfo.address[key]);

    if (hasOnlyBuildingNameOrStreetAddress2) {
      return {
        generalInfo: {
          address: {
            ...(buildingName ? { buildingName } : {}),
            ...(streetAddress2 ? { streetAddress2 } : {}),
          },
        },
      };
    }

    return null;
  };

  const handleEdit = async (slug: string) => {
    setIsEditing((prev) => ({
      ...prev,
      [slug]: !prev[slug],
    }));
    setSections((prev) => ({
      ...prev,
      [slug]: !prev[slug],
    }));

    setCurrentlyEditing(slug);

    if (currentPayload) {
      setSections((prev) => ({
        ...prev,
        [slug]: !currentPayload,
      }));
  
      setIsLoading((prev) => ({
        ...prev,
        [slug]: true,
      }));

      const customPayload = createPayloadForPatch();
  
      const performPatch = async () => {
        if (customPayload) {
          return doPatch(slug, customPayload);
        }
        const shouldOpenModal = handleOpenModal(slug);
        if (!shouldOpenModal) {
          return doPatch(slug);
        }
      };
  
      await performPatch().finally(() => {
        setIsLoading((prev) => ({
          ...prev,
          [slug]: false,
        }));
      });
    }
  };

  const onAddressNormalizationModalSave = async () => {
    if (currentPayload?.generalInfo) {
      const payload = Object.keys(currentPayload?.generalInfo)
        .filter((k) => currentPayload?.generalInfo[k] != null)
        .reduce((acc, k) => {
          acc[k] = currentPayload?.generalInfo[k];
          return acc;
        }, {});
      await doPatch("address", { generalInfo: { ...payload } });
      refetch();
    }
  };

  const onCloseGenericModal = () => {
    setOpenModal(false);
    setDisableSaveModalButton(false);

    if (typeof currentlyEditing === "string") {
      setIsEditing((prev) => ({
        ...prev,
        [currentlyEditing]: true,
      }));
      setSections((prev) => ({
        ...prev,
        [currentlyEditing]: false,
      }));
      setIsLoading((prev) => ({
        ...prev,
        [currentlyEditing]: false,
      }));
    }
  };

  useEffect(() => {
    onCancelSection();
  }, [selectedTab]);

  return (
    <main className={classes.locationPageTab_root}>
      <Grid className={classes.locationPageTab_leftColumn}>
        {leftPanelConfiguration.map(
          ({ title, component, slug, customAction, data, showActionButton }) => (
            <EditableComponent
              key={slug}
              title={title}
              onEdit={() => handleEdit(slug)}
              isEditing={isEditing[slug]}
              customAction={customAction}
              showActionButton={showActionButton}
              isLoading={isLoading[slug]}
              disableSection={
                (currentlyEditing !== null && currentlyEditing !== slug) || disableEditButton
              }
              onCancel={onCancelSection}
              currentPayload={currentPayload}
            >
              {component({ shouldDisableForm: sections[slug], data, onDisableEditButton })}
            </EditableComponent>
          ),
        )}
      </Grid>
      <Grid className={classes.locationPageTab_rightColumn}>
        {rightPanelConfiguration.map(
          ({ title, component, slug, customAction, data, showActionButton }) => (
            <EditableComponent
              key={slug}
              title={title}
              onEdit={() => handleEdit(slug)}
              onCancel={onCancelSection}
              isEditing={isEditing[slug]}
              customAction={customAction}
              showActionButton={showActionButton}
              isLoading={isLoading[slug]}
              currentPayload={currentPayload}
              disableSection={
                (currentlyEditing !== null && currentlyEditing !== slug) || disableEditButton
              }
            >
              {component({ shouldDisableForm: sections[slug], data, onDisableEditButton })}
            </EditableComponent>
          ),
        )}
      </Grid>
      {sectionModalComponentBody && (
        <GenericModal
          show={openModal}
          title={sectionModalComponentBody.title}
          subtitle={sectionModalComponentBody.subtitle}
          onClose={onCloseGenericModal}
          onSave={() => onAddressNormalizationModalSave()}
          isError={disableSaveModalButton}
          saveButtonText="SAVE ADDRESS"
        >
          {sectionModalComponentBody.component}
        </GenericModal>
      )}
    </main>
  );
};

export default LocationPageTab;
