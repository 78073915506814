import { useQuery } from "react-query";
import { FETCH_VIRTUAL_VISITS } from "components/WaitingList/PatientDetails/Actions";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";
import { axiosClient } from "util/api_helper";

export const useGetAwaitingUsers = ({ baseUrl, urlOptions }) => {
  const app = useApp();

  const {
    web: {
      common: { errorMsg },
    },
  } = useTranslation();

  const fetchQuery = () => {
    const params = new URLSearchParams(urlOptions).toString();
    const url = `${baseUrl}?${params}`;
    return axiosClient.get(url);
  };

  const { data, isLoading, isFetching, refetch } = useQuery({
    refetchInterval: 60000,
    queryKey: ["GENERIC_WAITING_LIST"],
    queryFn: fetchQuery,
    staleTime: Infinity,
    enabled: true,
    onError: () => app.addError(errorMsg),
    onSuccess: (json) => {
      app.dispatch({
        type: FETCH_VIRTUAL_VISITS,
        payload: json?.data.data,
      });
    },
  });

  return {
    data,
    refetch,
    isLoading,
    isFetching,
  };
};
