import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useProviderContext } from "../../ProviderContext";
import useTranslation from "hooks/useTranslation";
import { languages } from "views/DataCuration/utils";
import { ProviderGeneralInfoAndLocation, TabPaneProps } from "../../types";

const PersonalInformationSection: React.FC<TabPaneProps> = ({
  shouldDisableForm,
}: TabPaneProps) => {
  const {
    web: {
      dataGovernance: { providerProfile },
    },
  } = useTranslation();

  const {
    providerGeneralInfoAndLocation: { personalInformation },
    currentPayload,
    setCurrentPayload,
  } = useProviderContext();

  const {
    generalInformation: {
      personalInformation: { firstName, middleName, lastName, suffix, gender, npi, degrees },
    },
  } = providerProfile;

  const [selectedLanguages, setSelectedLanguages] = useState(personalInformation?.languages || []);
  const [selectedDegrees, setSelectedDegrees] = useState(personalInformation?.degrees || []);

  const [formData, setFormData] = useState({
    ...personalInformation,
  });

  const handleChange = (key: string, e: any) => {
    const newValue = e?.target?.value;

    setFormData({
      ...formData,
      [key]: newValue,
    });

    if (newValue !== undefined && newValue !== personalInformation[key]) {
      setCurrentPayload((prev: ProviderGeneralInfoAndLocation) => ({
        ...(prev || {}),
        [key]: newValue,
      }));
    }
  };

  const handleLanguageChange = (event: any, newValue: string[]) => {
    setSelectedLanguages(newValue);

    setCurrentPayload((prev: any) => ({
      ...prev,
      languages: newValue,
    }));
  };
  const availableLanguages = languages.filter((language) => !selectedLanguages.includes(language));

  const handleDegreesChange = (event: any, newValue: string[]) => {
    setSelectedDegrees(newValue);
    setCurrentPayload((prev: any) => ({
      ...prev,
      degrees: newValue,
    }));
  };

  useEffect(() => {
    if (currentPayload === null) {
      setFormData({ ...personalInformation });
      setSelectedDegrees(personalInformation?.degrees || []);
      setSelectedLanguages(personalInformation?.languages || []);
    }
  }, [currentPayload]);

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      justifyContent="center"
      style={{ padding: "24px 0 32px 0", width: "100%" }}
      data-testid="personal-information-section"
    >
      <Grid item xs={12} md={4}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            error={false}
            id="firstName"
            name="firstName"
            size="medium"
            value={
              formData?.firstName !== undefined
                ? formData.firstName
                : personalInformation.firstName || ""
            }
            variant="outlined"
            label={firstName}
            onChange={(e) => handleChange("firstName", e)}
            disabled={shouldDisableForm}
            helperText={false}
            data-cy="PersonalInformation-FirstNameInput"
            data-testid="PersonalInformation-FirstNameInput"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            error={false}
            id="middleName"
            name="middleName"
            size="medium"
            value={
              formData?.middleName !== undefined
                ? formData.middleName
                : personalInformation.middleName || ""
            }
            variant="outlined"
            label={middleName}
            onChange={(e) => handleChange("middleName", e)}
            disabled={shouldDisableForm}
            helperText={false}
            data-cy="PersonalInformation-MiddleNameInput"
            data-testid="PersonalInformation-MiddleNameInput"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            error={false}
            id="lastName"
            name="lastName"
            size="medium"
            value={
              formData?.lastName !== undefined
                ? formData.lastName
                : personalInformation.lastName || ""
            }
            variant="outlined"
            onChange={(e) => handleChange("lastName", e)}
            disabled={shouldDisableForm}
            label={lastName}
            helperText={false}
            data-cy="PersonalInformation-LastNameInput"
            data-testid="PersonalInformation-LastNameInput"
          />
        </FormControl>
      </Grid>
      <Box width="100%" />
      {/* Section 2 start */}
      <Grid item xs={12} md={4}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            error={false}
            id="suffix"
            name="suffix"
            size="medium"
            value={formData?.suffix ?? personalInformation.suffix ?? ""}
            variant="outlined"
            label={suffix}
            onChange={(e) => handleChange("suffix", e)}
            helperText={false}
            disabled={shouldDisableForm}
            data-cy="PersonalInformation-SuffixInput"
            data-testid="PersonalInformation-SuffixInput"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4} style={{ textAlign: "left" }}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel htmlFor="gender" id="gender-label" disabled={shouldDisableForm}>
            {gender}
          </InputLabel>
          <Select
            error={false}
            id="gender"
            name="gender"
            size="medium"
            variant="outlined"
            labelId="gender-label"
            label={gender}
            value={formData.gender || personalInformation.gender}
            onChange={(e) => handleChange("gender", e)}
            disabled={shouldDisableForm}
            data-cy="PersonalInformation-GenderSelect"
            data-testid="PersonalInformation-GenderSelect"
          >
            <MenuItem value="F">Female</MenuItem>
            <MenuItem value="M">Male</MenuItem>
            <MenuItem value="X">Other</MenuItem>
            <MenuItem value="U">Unknown</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl variant="outlined" fullWidth>
          <TextField
            error={false}
            id="npi"
            name="npi"
            size="medium"
            value={formData?.NPI !== undefined ? formData.NPI : personalInformation.NPI || ""}
            variant="outlined"
            label={npi}
            onChange={(e) => handleChange("NPI", e)}
            helperText={false}
            disabled
            data-cy="PersonalInformation-NpiInput"
            data-testid="PersonalInformation-NpiInput"
          />
        </FormControl>
      </Grid>
      {/* Section 3 start */}
      <Grid item xs={12}>
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            multiple
            id="degrees-autocomplete"
            disabled={shouldDisableForm}
            options={personalInformation?.degrees || []}
            onChange={handleDegreesChange}
            value={selectedDegrees}
            data-testid="personalInformation-degrees-autocomplete"
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={degrees}
                placeholder="Select Degree"
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="outlined" fullWidth>
          <Autocomplete
            multiple
            id="languages-autocomplete"
            disabled={shouldDisableForm}
            options={availableLanguages}
            value={selectedLanguages}
            onChange={handleLanguageChange}
            data-testid="personalInformation-lang-autocomplete"
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Languages"
                placeholder="Select Languages"
              />
            )}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default PersonalInformationSection;
