import { useQuery } from "react-query";
import useTranslation from "hooks/useTranslation";
import { useApp } from "util/AppContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import {
  LocationData,
  UseGetLocationByIdResult,
} from "views/DataCuration/LocationEditPage/utils/types";

export const useGetLocationById = (locationId: string | undefined): UseGetLocationByIdResult => {
  const app = useApp();

  const {
    web: {
      common: { errorMsg },
    },
  } = useTranslation();

  const fetchLocationData = async (): Promise<LocationData> => {
    const {
      generalInfo = {},
      additionalLinks = {},
      hoursAndSchedule = {},
      identifierInfo = {},
      locationFinder = false,
      providerFinder = true,
      waitTime = false,
    } = (await axiosClient.get(`${API_ENDPOINTS.dataGovernanceLocations}/${locationId}`)).data;

    const {
      name: {
        webpageUrl = "",
        nameFull = "",
        nameShort = "",
        departmentName = "",
        buildingPictureUrl = "",
        type = "",
        id = "",
        ahsId = "",
        // eslint-disable-next-line @typescript-eslint/naming-convention
        parent_location = {},
        name = "",
        networkAffiliations = "",
      } = {},
      address: {
        buildingName = "",
        city = "",
        googleMapUrl = "",
        latitude = "",
        longitude = "",
        state = "",
        streetAddress1 = "",
        streetAddress2 = "",
        suite = "",
        zip = "",
      } = {},
      contactInfo: { email = "", fax = "", phone = "", webUrl = "" } = {},
      otherInfo: {
        alertMessage = "",
        billingTin = "",
        commercialEntity = "",
        facilityFee = "",
        insuranceAccepted = "",
        shortDescription = "",
        synonyms = "",
      } = {},
      locationProviders = [],
    } = generalInfo;

    return {
      generalInfo: {
        name: {
          webpageUrl,
          nameFull,
          nameShort,
          departmentName,
          buildingPictureUrl,
          type,
          id,
          ahsId,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          parent_location,
          name,
          networkAffiliations,
        },
        address: {
          buildingName,
          city,
          googleMapUrl,
          latitude,
          longitude,
          state,
          streetAddress1,
          streetAddress2,
          suite,
          zip,
        },
        contactInfo: { email, fax, phone, webUrl },
        otherInfo: {
          alertMessage,
          billingTin,
          commercialEntity,
          facilityFee,
          insuranceAccepted,
          shortDescription,
          synonyms,
        },
        locationProviders,
      },
      additionalLinks: {
        aboutOurServices: additionalLinks.aboutOurServices ?? "",
        onMyWayLink: additionalLinks.onMyWayLink ?? "",
        requestCallbackLink: additionalLinks.requestCallbackLink ?? "",
        scheduleMyAppointmentLink: additionalLinks.scheduleMyAppointmentLink ?? "",
        virtualVisitsLink: additionalLinks.virtualVisitsLink ?? "",
      },
      hoursAndSchedule: {
        adjustedHours: hoursAndSchedule.adjustedHours ?? [],
        overrideMessage: hoursAndSchedule.overrideMessage ?? "",
        open24Hours: hoursAndSchedule.open24Hours ?? false,
        temporarilyClosed: hoursAndSchedule.temporarilyClosed ?? false,
        friday: hoursAndSchedule.friday ?? {},
        monday: hoursAndSchedule.monday ?? {},
        saturday: hoursAndSchedule.saturday ?? {},
        sunday: hoursAndSchedule.sunday ?? {},
        thursday: hoursAndSchedule.thursday ?? {},
        tuesday: hoursAndSchedule.tuesday ?? {},
        wednesday: hoursAndSchedule.wednesday ?? {},
      },
      identifierInfo: {
        ehrLocationId: identifierInfo.ehrLocationId ?? "",
        locationId: identifierInfo.locationId ?? "",
        organizationId: identifierInfo.organizationId ?? "",
        sourceSystemName: identifierInfo.sourceSystemName ?? "",
        sourceSystemType: identifierInfo.sourceSytemType ?? "",
        pressGaneyId: identifierInfo.pressGaneyId ?? "",
        timezone: identifierInfo.timezone ?? "",
        uuid: identifierInfo?.uuid ?? "",
      },
      locationFinder,
      providerFinder,
      waitTime,
    };
  };

  const {
    data: locationData,
    isLoading,
    isFetching,
    refetch,
  } = useQuery<LocationData>({
    queryKey: ["locationData", locationId],
    queryFn: fetchLocationData,
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: true,
    onError: () => app.addError(errorMsg),
  });

  return {
    locationData: locationData || null,
    refetch,
    isLoading,
    isFetching,
  };
};
