import React from "react";
import { Box, Button, Checkbox, FormControlLabel, Modal, Typography } from "@mui/material";
import { localeTranslationsEn } from "locale/en";

type Insurance = {
  id: number;
  name: string;
};

interface InsuranceAcceptedSectionModalProps {
  open: boolean;
  onClose: () => void;
  onUpdateInsurances: () => void;
  copiedProviderInsurance: Insurance[];
  checkedInsurances: Record<number, boolean>;
  handleSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleToggleInsurance: (id: number) => void;
  providerName: string;
  copyProviderFullName: string;
}

const InsuranceAcceptedSectionModal: React.FC<InsuranceAcceptedSectionModalProps> = ({
  open,
  onClose,
  onUpdateInsurances,
  copiedProviderInsurance,
  checkedInsurances,
  handleSelectAll,
  handleToggleInsurance,
  providerName,
  copyProviderFullName,
}) => {
  const children = (
    <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
      {copiedProviderInsurance.map((insurance) => (
        <FormControlLabel
          key={insurance.id}
          label={insurance.name}
          control={
            <Checkbox
              checked={checkedInsurances[insurance.id] || false}
              onChange={() => handleToggleInsurance(insurance.id)}
            />
          }
        />
      ))}
    </Box>
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-title" variant="h6" component="h2">
          {
            localeTranslationsEn.web.dataGovernance.providerProfile.generalInformation
              .InsurancesAccepted.insuranceAccepted
          }
        </Typography>
        <Typography id="modal-description" sx={{ mt: 2 }}>
          {
            localeTranslationsEn.web.dataGovernance.providerProfile.generalInformation
              .InsurancesAccepted.modalHeadingText
          }{" "}
          <b>{copyProviderFullName}</b> to <b>{providerName}</b>?
        </Typography>

        <Box style={{ height: "400px", overflow: "scroll" }}>
          <FormControlLabel
            label="Select All"
            control={
              <Checkbox
                checked={Object.values(checkedInsurances).every((value) => value)}
                indeterminate={
                  Object.values(checkedInsurances).some((value) => value) &&
                  !Object.values(checkedInsurances).every((value) => value)
                }
                onChange={handleSelectAll}
              />
            }
          />
          {children}
        </Box>
        <Typography sx={{ mt: 2 }}>
          {
            localeTranslationsEn.web.dataGovernance.providerProfile.generalInformation
              .InsurancesAccepted.modalConfirmationText
          }
          <b>{providerName}</b>.
        </Typography>
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <Button onClick={onClose} variant="contained" color="primary">
            {
              localeTranslationsEn.web.dataGovernance.providerProfile.generalInformation
                .InsurancesAccepted.cancelButton
            }
          </Button>
          <Button onClick={onUpdateInsurances} variant="contained" color="primary">
            {
              localeTranslationsEn.web.dataGovernance.providerProfile.generalInformation
                .InsurancesAccepted.updatedInsurance
            }
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default InsuranceAcceptedSectionModal;
