import React, { FC } from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import HealthHistoryStep, { GetStepContent } from "../../steps/HealthHistoryStep";
import PersonalInformationStep from "../../steps/PersonalInformationStep";
import { MedicationsTable } from "./MedicationsTable";
import { makeStyles } from "tss-react/mui";
import RelationshipToDriverStep from "views/DOTForm/steps/RelationshipToDriverStep";
import { QUESTIONS_DATA, QuestionsData, StepsProps } from "views/DOTForm/types/constants";
import useTranslation from "hooks/useTranslation";
import dl_placeholder from "static/svg/dl_placeholder.svg";

const useStyles = makeStyles()({
  root: {
    minWidth: 275,
    minHeight: 275,
    backgroundColor: "#F18F28",
    marginTop: "20px",
    margin: "10px",
    padding: "5px",
    "& > div": {
      padding: "2px",
    },
    "& > div:last-child": {
      paddingBottom: "2px",
    },
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  licensePic: {
    width: "100%",
    aspectRatio: "1/1 auto",
    objectFit: "cover",
  },
  cardHeader: {
    "& p": {
      fontSize: 20,
      color: "white",
      fontWeight: "bold",
    },
  },
  cardBody: {
    backgroundColor: "white",
    padding: "5px",
    height: "275px",
    borderBottomLeftRadius: "3px",
    borderBottomRightRadius: "3px",
    fontFamily: "Alegreya Sans",
    textAlign: "initial",
    "overflow-y": "auto",
    "&::-webkit-scrollbar": {
      width: "10px" /* Width of the scrollbar */,
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1" /* Color of the scrollbar track */,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888" /* Width of the scrollbar */,
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555" /* Width of the scrollbar */,
    },
  },
  dotDashboard: {
    maxWidth: "1600px",
    width: "100%",
    "& .disclaimer": {
      display: "none",
    },
    "& [data-testid='have-you-ever-had']": {
      display: "none",
    },
  },
});

const noop = () => undefined;

export const Dashboard: FC<StepsProps> = ({
  formData,
  medicalRecords,
  handleChange = noop,
  handleStepChange,
  formRef,
  isLoading,
}) => {
  const { classes } = useStyles();
  const {
    web: {
      dotForm: {
        staff: {
          dashboard: {
            highlitedResponsesCard: { title },
            medicationsCard: { title: medicationsCardTitle, noMedications },
          },
        },
      },
    },
  } = useTranslation();

  let sortedMedications: string[] = [noMedications];
  const licenseImage = formData?.personalInformation?.driverLicense?.image;
  const imageSrc = licenseImage && licenseImage.trim() !== "" ? licenseImage : dl_placeholder;
  const { healthHistory: currentHealthHistory } = formData;
  const healthHistoryDriverPositiveAnswers = currentHealthHistory?.healthHistory;
  const positiveEntries = Object.entries(formData?.healthHistory?.healthHistory || {})
    // @ts-ignore
    .filter(([_, v]) => v?.answer === "YES");

  const positiveAnswerIds: string[] = positiveEntries.map(([qid]) => qid);
  const allQuestions: QuestionsData[] = QUESTIONS_DATA.filter((q) =>
    positiveAnswerIds.includes(q.id),
  );

  if (positiveEntries.length > 0) {
    const historyQuestions = Object.keys(healthHistoryDriverPositiveAnswers)
      .map((key) => {
        return {
          key,
          value: healthHistoryDriverPositiveAnswers[key]?.answer,
          text: healthHistoryDriverPositiveAnswers[key]?.text,
        };
      })
      .filter((question) => question.value === "YES");

    const meds: string = historyQuestions.find((q) => q.key === "Q_CURRENT_MEDICATIONS")?.text;
    sortedMedications = (() => {
      const splitRegex = /[\n,]/;
      if (!meds) {
        return [noMedications];
      }
      if (!splitRegex.test(meds)) {
        return [meds];
      }
      return meds
        .split(splitRegex) // split on newlines or commas
        .filter(Boolean) // exclude empty strings
        .map((x) => x.trim()) // trim whitespace
        .sort((a, b) => a.localeCompare(b, "en", { sensitivity: "base" })); // sort alphabetically
    })();
  }

  return (
    <Grid container alignItems="center" justifyContent="center" className={classes.dotDashboard}>
      <Grid container spacing={1} style={{ marginTop: "30px", width: "100%" }}>
        <Grid item xs={12} sm={12} md={4} lg={3}>
          <img src={imageSrc} className={classes.licensePic} alt="drivers license" />
          <Card className={classes.root} data-testid="highlighted-responses">
            <CardContent>
              <div className={classes.cardHeader}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  {title}
                </Typography>
              </div>
              <div className={classes.cardBody}>
                <GetStepContent
                  stepQuestions={allQuestions}
                  data={healthHistoryDriverPositiveAnswers}
                  handleQuestionChange={noop}
                  classes={classes}
                  shouldDisableForm
                />
              </div>
            </CardContent>
          </Card>
          <Card className={classes.root} data-testid="medications">
            <CardContent>
              <div className={classes.cardHeader}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  {medicationsCardTitle}
                </Typography>
              </div>

              {!isLoading ? (
                <MedicationsTable
                  medicalRecord={medicalRecords}
                  patientReported={sortedMedications}
                />
              ) : (
                ""
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={9}>
          <PersonalInformationStep
            formData={formData}
            showTitle
            isFormDisabled
            handleChange={handleChange}
            handleStepChange={handleStepChange}
          />
          <RelationshipToDriverStep
            formData={formData}
            showTitle
            isFormDisabled
            handleChange={handleChange}
            handleStepChange={handleStepChange}
          />
          ,
          <HealthHistoryStep
            formData={formData}
            isFullPage
            shouldShowDisplayOptions
            isFormDisabled
            showTitle
            handleChange={handleChange}
            handleStepChange={handleStepChange}
            isStaff
            formRef={formRef}
            isLoading={isLoading}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
