import React, { useEffect, useState } from "react";
import { format, isValid, parse } from "date-fns";
import SearchToolbar from "views/SpecimenTracking/components/SearchToolbar";
import { filterOptions } from "../../utils/filterOptions";

type FilterProps = {
  searchBy: string;
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  setSearchBy: React.Dispatch<React.SetStateAction<string>>;
  handleSearch: () => void;
  clearFilters: () => void;
};

const SpecimenTrackingFilters = ({
  searchBy,
  searchValue,
  setSearchValue,
  setSearchBy,
  handleSearch,
  clearFilters,
}: FilterProps) => {
  const currentDate = format(new Date(), "MM/dd/yyyy");
  const initialSearchValue = sessionStorage.getItem("searchValue") ?? currentDate;
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    parse(initialSearchValue, "MM/dd/yyyy", new Date()),
  );

  const handleClearFilters = () => {
    if (searchBy === "collectedDatetime") {
      setSelectedDate(null);
    }
    clearFilters();
  };

  useEffect(() => {
    if (searchBy === "collectedDatetime" && selectedDate && isValid(selectedDate)) {
      const formattedDate = format(selectedDate, "MM/dd/yyyy");
      setSearchValue(formattedDate);
    }
  }, [selectedDate, searchBy, setSearchValue]);

  useEffect(() => {
    if (searchBy === "collectedDatetime" && !searchValue) {
      setSelectedDate(null);
    }
  }, [searchBy, searchValue]);

  return (
    <SearchToolbar
      searchOptions={filterOptions}
      searchBy={searchBy}
      searchText={searchValue}
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
      setSearchBy={setSearchBy}
      setSearchText={setSearchValue}
      handleSearch={handleSearch}
      handleCleanSearch={handleClearFilters}
    />
  );
};

export default SpecimenTrackingFilters;
