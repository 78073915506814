import { v4 as randomUUID } from "uuid";
import { isAfter, isBefore, parse } from "date-fns";
import { AdjustedHour, AdjustedHoursType } from "./types";

// addNewHoursEntry
// makeDatesFromStrings
// makeHoursFromList

/** @description Adjusted hours come from backend as a list; we make it a map. */
export const makeHoursFromList = (
  adjustedHours: AdjustedHour[],
): Record<string, AdjustedHoursType> =>
  Object.fromEntries(adjustedHours.map((item) => [item.uuid, item]));

/** @description Convert the values in our adjusted hours map from strings to Date | undefined. */
export const makeDatesFromStrings = (input: AdjustedHoursType) => ({
  ...input,
  ...{
    adjustedHoursEffectiveStart: parse(
      input.adjustedHoursEffectiveStart ?? "",
      "yyyy-MM-dd",
      new Date(),
    ),
    adjustedHoursEffectiveEnd: parse(
      input.adjustedHoursEffectiveEnd ?? "",
      "yyyy-MM-dd",
      new Date(),
    ),
    adjustStart: parse(input.adjustStart, "HH:mm", new Date()),
    adjustEnd: parse(input.adjustEnd, "HH:mm", new Date()),
    adjustLunchStart: parse(input.adjustLunchStart ?? "", "HH:mm", new Date()),
    adjustLunchEnd: parse(input.adjustLunchEnd ?? "", "HH:mm", new Date()),
  },
});

/** @description Make a new adjusted hours map with no values and a random UUID. */
export const makeNewHoursEntry = (): AdjustedHoursType => {
  const itsUuid = randomUUID();
  return {
    uuid: itsUuid,
    adjustedHoursMessaging: "",
    adjustClosed: false,
    adjustedHoursEffectiveStart: "",
    adjustedHoursEffectiveEnd: "",
    adjustStart: "09:00",
    adjustEnd: "17:00",
    adjustLunchStart: "12:00",
    adjustLunchEnd: "12:30",
  };
};

// validation:
// - has opening < closing
// - no lunch OR:
//   lunchStart < lunchEnd

/** @description Short synonym to check whether a date is an Invalid Date (with value NaN) */
const bad = (input: Date): boolean => {
  if (Number.isNaN(input.valueOf())) {
    return true;
  }
  return false;
};
const good = (input: Date): boolean => !bad(input);

/** @description Validates the adjusted hours entry against possible errors. */
export const validateEntry = (input: AdjustedHoursType): [boolean, string?] => {
  const {
    adjustedHoursEffectiveStart: startDate,
    adjustStart: open,
    adjustEnd: close,
    adjustLunchStart: lunchStart,
    adjustLunchEnd: lunchEnd,
  } = makeDatesFromStrings(input);

  const hasLunch = good(lunchStart) && good(lunchEnd);

  if (bad(startDate)) {
    return [false, "Please choose a date"];
  }
  if (bad(open)) {
    return [false, "Please choose an opening time"];
  }
  if (good(open) && bad(close)) {
    return [false, "Please choose a closing time"];
  }
  if (good(lunchStart) && bad(lunchEnd)) {
    return [false, "Please choose a lunch ending time"];
  }
  if (isAfter(open, close)) {
    return [false, "Closing time must come after opening time!"];
  }
  if (hasLunch && isAfter(lunchStart, lunchEnd)) {
    return [false, "Lunch can't end before it starts!"];
  }
  if (hasLunch && (isBefore(lunchStart, open) || isAfter(lunchStart, close))) {
    return [false, "Lunch can't start before opening or after closing!"];
  }
  if (hasLunch && (isBefore(lunchEnd, open) || isAfter(lunchEnd, close))) {
    return [false, "Lunch can't end before opening or after closing!"];
  }
  return [true, undefined];
};
