import React, { useState } from "react";
import { Business, Person } from "@mui/icons-material";
import { Box, Checkbox, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
import AssignDepartmentModal from "./AssignDepartmentModal";
import AssignToModal from "./AssignToModal";
import { makeStyles } from "tss-react/mui";
import { VirtualSessionQueue } from "util/constants";
import { usePrograms } from "util/ProgramsContext";

const useStyles = makeStyles<{ isSomethingChecked: boolean }>()(
  (theme, { isSomethingChecked }) => ({
    root: {
      minHeight: "56px",
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      backgroundColor: isSomethingChecked ? alpha(theme.palette.primary.main, 0.1) : "unset",
    },
    selectedText: {
      margin: 0,
      fontSize: "16px",
    },
    indeterminateCheckbox: {
      "& svg": {
        color: theme.palette.primary.main,
      },
    },
  }),
);

type SelectListItemProps = {
  selectedVirtualVisitIds: number[];
  setSelectedVirtualVisitIds: (selectedVirtualVisitIds: number[]) => void;
  amountOfVirtualVisits: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  virtualSessoinsList: any[];
  queue: VirtualSessionQueue;
};

const SelectListItem: React.FC<SelectListItemProps> = ({
  selectedVirtualVisitIds,
  setSelectedVirtualVisitIds,
  amountOfVirtualVisits,
  virtualSessoinsList,
  queue,
}) => {
  const isIndeterminate =
    selectedVirtualVisitIds.length > 0 && selectedVirtualVisitIds.length < amountOfVirtualVisits;
  const isEverythingChecked = selectedVirtualVisitIds.length === amountOfVirtualVisits;
  const isSomethingChecked = isEverythingChecked || isIndeterminate;

  const { departmentList } = usePrograms();

  const [isAssignToModalOpen, setIsAssignToModalOpen] = useState(false);
  const [isAssignDepartmentModalOpen, setIsAssignDepartmentModalOpen] = useState(false);

  const { classes } = useStyles({ isSomethingChecked });

  const handleClickCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    if (!checked || isIndeterminate) {
      setSelectedVirtualVisitIds([]);
    } else {
      setSelectedVirtualVisitIds(virtualSessoinsList.map(({ id }) => id));
    }
  };

  return (
    <Grid container alignItems="center" justifyContent="space-between" className={classes.root}>
      <Box display="flex" alignItems="center">
        <Checkbox
          color="primary"
          classes={{ indeterminate: classes.indeterminateCheckbox }}
          indeterminate={isIndeterminate}
          checked={isEverythingChecked}
          onChange={handleClickCheckbox}
        />
        {selectedVirtualVisitIds.length > 0 && (
          <Typography
            variant="body1"
            className={classes.selectedText}
          >{`${selectedVirtualVisitIds.length} Selected`}</Typography>
        )}
      </Box>
      <Box marginRight="8px">
        <Tooltip title="Assign to">
          <Box component="span">
            <IconButton
              data-cy="assignToBtn"
              disabled={!isSomethingChecked}
              onClick={() => setIsAssignToModalOpen(true)}
              size="large"
            >
              <Person color={`${isSomethingChecked ? "primary" : "disabled"}`} />
            </IconButton>
          </Box>
        </Tooltip>
        {departmentList.length > 0 && (
          <Tooltip title="Assign Department">
            <Box component="span">
              <IconButton
                data-cy="assignToBtnDepartment"
                disabled={!isSomethingChecked}
                onClick={() => setIsAssignDepartmentModalOpen(true)}
                size="large"
              >
                <Business color={`${isSomethingChecked ? "primary" : "disabled"}`} />
              </IconButton>
            </Box>
          </Tooltip>
        )}
      </Box>
      {isAssignDepartmentModalOpen && (
        <AssignDepartmentModal
          isOpen={isAssignDepartmentModalOpen}
          handleClose={() => setIsAssignDepartmentModalOpen(false)}
          selectedVirtualVisitIds={selectedVirtualVisitIds}
          setSelectedVirtualVisitIds={setSelectedVirtualVisitIds}
        />
      )}
      {isAssignToModalOpen && (
        <AssignToModal
          isOpen={isAssignToModalOpen}
          handleClose={() => setIsAssignToModalOpen(false)}
          selectedVirtualVisitIds={selectedVirtualVisitIds}
          setSelectedVirtualVisitIds={setSelectedVirtualVisitIds}
          queue={queue}
        />
      )}
    </Grid>
  );
};

export default SelectListItem;
