import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { usePatientProfileStyles } from "../waitingUserTableStyles";
import {
  CLEAR_VIRTUAL_VISIT,
  CONNECT_VIRTUAL_VISIT,
  TOGGLE_QUEUE_TAB,
  UPDATE_AND_SELECT_VIRTUAL_VISIT,
} from "./Actions";
import AssignPatientDepartment from "./AssignPatientDepartment";
import VirtualVisitDetails from "./VirtualVisitDetails";
import { WaitingRoomTab } from "util/constants";
import useCheckOnlineStatus from "./hooks/useCheckOnlineStatus";
import { useApp } from "util/AppContext";
import { usePrograms } from "util/ProgramsContext";
import { axiosClient } from "util/api_helper";

const ProfileStatus = () => {
  const { classes } = usePatientProfileStyles();
  const { syncMap, isOnline } = useCheckOnlineStatus();

  return (
    <div className={classes.profileStatusContainer}>
      <div
        className={`${classes.statusCircle} ${
          isOnline && syncMap ? classes.online : classes.offline
        }`}
      />
      {syncMap && <div>{isOnline ? "Waiting in the room" : "Not in the room"}</div>}
      {!syncMap && <div>Unknown</div>}
    </div>
  );
};

const PatientDetails = () => {
  const { classes } = usePatientProfileStyles();
  const location = useLocation();
  const [vsId, setVsId] = useState<string | null>(null);

  const app = useApp();
  const { programId, departmentList } = usePrograms();
  const [profile, setProfile] = useState(null);
  const [urgency, setUrgency] = useState("");
  const [assignUsersList, setAssignUsersList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const currentVirtualVisit = {
    ...app?.vs_visits_state?.currentVirtualVisit,
    id: params.get("vsId"),
    queue: params.get("queue") ?? "all",
  };

  const getPatientProfile = async () => {
    setIsLoading(true);
    try {
      const reqUrl = "/api/s/console/virtualvisitconsole/patient-profile-info";
      const { data } = await axiosClient.get(`${reqUrl}?virtual_session_id=${vsId}`);
      setUrgency(data?.data?.virtual_session_info?.urgency || "");
      setProfile(data?.data);
      const assignedDep = departmentList.find(
        (dep) => dep.id === data.data.virtual_session_info.department_id,
      );
      app.dispatch({
        type: UPDATE_AND_SELECT_VIRTUAL_VISIT,
        payload: {
          ...currentVirtualVisit,
          urgency: data?.data?.virtual_session_info?.urgency || "",
          department_id: assignedDep?.id || "",
          patient_id: data?.data?.virtual_session_info?.patient_id || "",
          currently_assigned_staff_user_id:
            data?.data?.virtual_session_info?.currently_assigned_staff_user_id || "",
        },
      });
    } catch (error) {
      app.addError("Something went wrong. Please contact support.");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (vsId) {
      getPatientProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vsId]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const vsIdParam = params.get("vsId");
    setVsId(vsIdParam);
  }, [location]);

  const getAssignUsersList = async () => {
    try {
      const url = `/api/s/console/virtualvisitconsole/get-users?programId=${programId}`;
      const {
        data: { data },
      } = await axiosClient.get(url);
      setAssignUsersList(data);
    } catch (e) {
      app.addError("Something went wrong. Please contact support.");
    }
  };

  useEffect(() => {
    if (currentVirtualVisit?.queue) {
      getAssignUsersList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVirtualVisit?.queue]);

  const onConfirmMatchSuccess = (vsId) => {
    // SHOW DEFAULT VIEW

    app.dispatch({
      type: CLEAR_VIRTUAL_VISIT,
    });
    app.dispatch({
      type: CONNECT_VIRTUAL_VISIT,
      payload: vsId,
    });
    app.dispatch({
      type: TOGGLE_QUEUE_TAB,
      payload: WaitingRoomTab.PATIENT,
    });
  };

  if (!vsId) {
    return <div className={classes.selectVisitTitle}>Please select a virtual visit...</div>;
  }
  if (isLoading) {
    return <CircularProgress />;
  }

  if (!profile) {
    return null;
  }

  return (
    <Grid className={classes.patientDemographics} item data-testid="patientDetails">
      <Grid container direction="column">
        <Typography variant="h5" className={classes.profileTitle}>
          Patient profile
        </Typography>
        <ProfileStatus />
      </Grid>
      <>
        <AssignPatientDepartment
          departmentList={departmentList}
          currentVirtualVisit={currentVirtualVisit}
          programId={programId}
          setProfile={setProfile}
          profile={profile}
          urgency={urgency}
          setUrgency={setUrgency}
          assignUsersList={assignUsersList}
        />
        <VirtualVisitDetails
          profile={profile}
          showUserDetails
          currentVirtualVisit={currentVirtualVisit}
          getPatientProfile={getPatientProfile}
          onConfirmMatchSuccess={onConfirmMatchSuccess}
          showEvents={undefined}
        />
      </>
    </Grid>
  );
};

export default PatientDetails;
