import React, { useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { useLocationContext } from "../../LocationContext";
import useTranslation from "hooks/useTranslation";

const ToggleProviderFinderDialog = (props) => {
  // toggleFromState is the initial state. If true, we are disabling. If false, we are enabling.
  const { toggleFromState, open, onClose, onConfirm, locationName = "" } = props;
  const {
    web: {
      common: { cancel, confirm },
    },
  } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm {toggleFromState ? "Disable" : "Enable"} Practice Location</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to {toggleFromState ? "disable" : "enable"} <b>{locationName}</b> as
          a practice location?
          <br />
          <br />
          {toggleFromState ? (
            <span>
              This will <b>remove</b> the location from any providers who have it listed as a
              practice location. It will no longer appear on their provider profiles.
            </span>
          ) : (
            <span>
              This will make the location <b>available</b> for listing on provider profiles.
            </span>
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {cancel}
        </Button>
        <Button onClick={onConfirm} color="primary" data-testid="confirm-toggle-provider-finder">
          {confirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ProviderFinderToggle = ({ shouldDisableForm }) => {
  const {
    web: {
      dataGovernance: {
        location: {
          providersAtThisLocation: { enableAsPracticeLocation, enableAsPracticeLocationTooltip },
        },
      },
    },
  } = useTranslation();
  const {
    locationData: {
      generalInfo: {
        name: { nameShort = "", nameFull = "" },
      },
      providerFinder,
    },
    currentPayload,
    setCurrentPayload,
  } = useLocationContext();

  const [dialogOpen, setDialogOpen] = useState(false);
  const availableInProviderFinder = currentPayload?.providerFinder ?? providerFinder;
  const setProviderFinder = (value: boolean) => {
    setCurrentPayload({
      ...(currentPayload ?? {}),
      providerFinder: value,
    });
  };

  return (
    <>
      <FormControl component="fieldset" fullWidth sx={{ paddingBlockStart: 2 }}>
        <FormControlLabel
          label={
            <div>
              {enableAsPracticeLocation}{" "}
              <Tooltip title={enableAsPracticeLocationTooltip} arrow>
                <InfoIcon fontSize="small" />
              </Tooltip>
            </div>
          }
          labelPlacement="end"
          control={
            <Switch
              checked={availableInProviderFinder}
              onChange={() => setDialogOpen(true)}
              disabled={shouldDisableForm}
            />
          }
        />
      </FormControl>
      <ToggleProviderFinderDialog
        toggleFromState={availableInProviderFinder}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onConfirm={() => {
          setProviderFinder(!availableInProviderFinder);
          setDialogOpen(false);
        }}
        locationName={nameFull || nameShort || ""}
      />
    </>
  );
};

export { ProviderFinderToggle };
