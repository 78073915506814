import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { CalendarToday, Cancel, VerifiedUser } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { FETCH_NEW_VIRTUAL_VISITS } from "../PatientDetails/Actions";
import PatientCard from "./PatientCard";
import { Department, Maybe } from "components/Video/utils/types";
import CellPhone from "components/shared/CellPhone";
import { Mask, OTHER_VISIT_REASON, PHONE_MAX_LENGTH, VirtualSessionQueue } from "util/constants";
import { useQuery } from "hooks/useQuery";
import { generateSechedule } from "../CreateVirtualSessionModal/utils";
import { useApp } from "util/AppContext";
import { usePrograms } from "util/ProgramsContext";
import { API_ENDPOINTS, axiosClient } from "util/api_helper";
import { useProgramType } from "util/programsHelpers";
import { useCreateVsFormStyles } from "./styles";

type Props = {
  patient: {
    MRN: Maybe<string>;
    address: Maybe<string>;
    cellPhone: Maybe<string>;
    dob: Maybe<string>;
    email: Maybe<string>;
    firstName: Maybe<string>;
    gender: Maybe<string>;
    lastName: Maybe<string>;
    patientId: Maybe<string>;
    zip: Maybe<string>;
  };
  handleClose: () => void;
};

const CreateVsVisit: React.FC<Props> = ({ patient, handleClose }) => {
  const { classes } = useCreateVsFormStyles();
  const app = useApp();
  const {
    departmentId,
    departmentList,
    setDepartmentId,
    programId,
  }: {
    departmentId: number;
    departmentList: Department[];
    setDepartmentId: (departmentId: number) => void;
    programId: number;
  } = usePrograms();
  const { isConsultation, isVUC } = useProgramType();
  const queryClient = useQueryClient();
  const query = useQuery();
  const navigate = useNavigate();

  const [visitReasons, setVisitReasons] = useState<string[]>([]);

  const getVisitReasons = async () => {
    try {
      const url = `/api/s/console/config/programs/${programId}/visit-reasons`;
      const { data } = await axiosClient.get(url);
      setVisitReasons([...(data.data ?? []), OTHER_VISIT_REASON]);
    } catch (error) {
      app.addError("Something went wrong. Please, contact support.");
    }
  };

  useEffect(() => {
    if (programId) {
      getVisitReasons();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programId]);

  const checkIsCellPhoneVerified = async (cellPhone) => {
    if (cellPhone?.length === PHONE_MAX_LENGTH) {
      try {
        const url = API_ENDPOINTS.verify_cellphone;
        await axiosClient.post(url, {
          patient_id: patient.patientId,
          cell_phone: cellPhone,
        });
        return true;
      } catch (e) {
        // @ts-ignore
        if (e.response.status === 409) {
          return false;
        }
        return true;
      }
    }
  };

  type FormInitialValues = {
    cellPhone: string;
    departmentId: number | "";
    zip: string;
    notes: string;
    sendInvite: true;
    visitReason: string | undefined;
    otherVisitReason: string | undefined;
    schedule: Date | undefined;
  };

  const initialValues: FormInitialValues = {
    cellPhone: patient?.cellPhone || "",
    departmentId: departmentId || "",
    zip: patient?.zip || "",
    notes: "",
    sendInvite: true,
    visitReason: isVUC ? "" : undefined,
    otherVisitReason: isVUC ? "" : undefined,
    schedule: isConsultation ? new Date() : undefined,
  };

  const validationSchema = Yup.object({
    cellPhone: Yup.string().min(10, "Phone number must be 10 digit"),
    zip: Yup.string().min(5, "Zip code must be 5 digit"),
    ...(isVUC && {
      visitReason: Yup.string().required("Visit reason is required"),
    }),
  });

  const onSubmit = async (
    {
      visitReason,
      otherVisitReason,
      cellPhone,
      departmentId,
      zip,
      notes,
      sendInvite,
      schedule,
    }: FormInitialValues,
    { setSubmitting }: FormikHelpers<FormInitialValues>,
  ) => {
    type RequestBody = {
      cellPhone: string;
      send_invite: boolean;
      queue: string;
      patientUserId: number;
      programId: number;
      MRN?: string;
      notes?: string;
      departmentId?: number;
      zip_code?: string;
      visitReason?: string;
      requested_start_beg?: string;
      requested_start_end?: string;
    };

    const virtualSession: RequestBody = {
      cellPhone,
      send_invite: sendInvite,
      // @ts-ignore  next line
      queue: VirtualSessionQueue.REGISTERING,
      patientUserId: Number(patient.patientId),
      programId,
      ...(patient.MRN && { MRN: patient.MRN }),
      ...(notes && { notes }),
      ...(departmentId && { departmentId }),
      ...(zip && { zip_code: zip }),
    };

    if (!isConsultation) {
      if (visitReason !== OTHER_VISIT_REASON) {
        virtualSession.visitReason = visitReason;
      } else {
        virtualSession.visitReason = otherVisitReason;
      }
    }

    if (isConsultation) {
      const [requestedStartBeg, requestedStartEnd] = generateSechedule({
        start_date: schedule,
      });
      virtualSession.requested_start_beg = requestedStartBeg;
      virtualSession.requested_start_end = requestedStartEnd;
    }

    try {
      const url = API_ENDPOINTS.create_vs_by_staff;
      const { data } = await axiosClient.post(url, virtualSession);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await queryClient.invalidateQueries("fetch-awaiting-vs-visit", programId as any);

      app.dispatch({ type: FETCH_NEW_VIRTUAL_VISITS });
      app.addInfoMsg("Virtual Session successfully created");
      setDepartmentId(departmentId || 0);

      query.set("vsId", data.data.id);
      navigate({ search: query.toString() });

      setSubmitting(false);
      handleClose();
    } catch (e) {
      // @ts-ignore
      app.addError(e.response.data.description);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        handleBlur,
        setFieldError,
        isSubmitting,
      }) => (
        <Form className={classes.formContainer}>
          <PatientCard patient={patient} />
          <FormControlLabel
            className={classes.sendInvite}
            control={
              <Checkbox
                name="sendInvite"
                checked={values.sendInvite}
                onChange={handleChange}
                color="primary"
              />
            }
            label="Send Patient Invitation"
          />
          <Grid container className={classes.inputsContainer}>
            {isVUC && (
              <>
                <Autocomplete
                  fullWidth
                  options={visitReasons}
                  getOptionLabel={(option) => option}
                  onChange={(_, value) => setFieldValue("visitReason", value)}
                  onBlur={handleBlur}
                  renderInput={(params) => (
                    <TextField
                      label="Visit Reason"
                      variant="outlined"
                      name="visitReason"
                      value={values.visitReason}
                      error={touched.visitReason && !!errors.visitReason}
                      helperText={touched.visitReason && errors.visitReason}
                      {...params}
                    />
                  )}
                />
                {values?.visitReason === OTHER_VISIT_REASON && (
                  <TextField
                    label="Other Visit Reason"
                    variant="outlined"
                    multiline
                    minRows={4}
                    fullWidth
                    name="otherVisitReason"
                    value={values.otherVisitReason}
                    onChange={handleChange}
                  />
                )}
              </>
            )}
            <div className={classes.formControl}>
              <CellPhone
                cellPhone={values.cellPhone}
                setCellPhone={(value) => setFieldValue("cellPhone", value)}
                error={Boolean(errors?.cellPhone)}
                helperText={errors?.cellPhone}
                onBlur={async (e) => {
                  handleBlur(e);
                  const isValid = await checkIsCellPhoneVerified(values.cellPhone);
                  if (!isValid) {
                    setFieldError("cellPhone", "Someone else has the same number");
                  }
                }}
                CustomStartIcon={
                  <InputAdornment position="start">
                    {(!errors.cellPhone || !values.cellPhone) && (
                      <VerifiedUser classes={{ root: classes.verifiedUserIcon }} />
                    )}
                    {errors.cellPhone && <Cancel classes={{ root: classes.cancelIcon }} />}
                  </InputAdornment>
                }
              />
            </div>
            {!!departmentList?.length && (
              <TextField
                label="Department"
                id="Department"
                variant="outlined"
                select
                fullWidth
                name="departmentId"
                value={values.departmentId}
                onChange={handleChange}
              >
                {departmentList?.map((department) => (
                  <MenuItem key={department.id} value={department.id}>
                    {department.name}
                  </MenuItem>
                ))}
              </TextField>
            )}

            {!patient?.zip && (
              <InputMask
                alwaysShowMask={false}
                mask={Mask.ZIP_CODE}
                name="zip"
                onChange={(e) => setFieldValue("zip", e.target.value.replace(/\D/g, ""))}
                onBlur={handleBlur}
                value={values.zip}
              >
                {/* @ts-ignore */}
                {(inputProps) => (
                  <TextField
                    label="Zip Code"
                    id="ZipCode"
                    variant="outlined"
                    fullWidth
                    error={touched.zip && !!errors.zip}
                    helperText={touched.zip && errors.zip}
                    {...inputProps}
                  />
                )}
              </InputMask>
            )}

            {isConsultation && (
              <DateTimePicker
                label="Virtual Session Date & Time"
                value={values.schedule}
                onChange={handleChange}
                name="schedule"
                slotProps={{
                  textField: {
                    id: "DateTimePicker",
                    fullWidth: true,
                  },
                  inputAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="large">
                        <CalendarToday />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}

            <TextField
              label="Notes"
              variant="outlined"
              fullWidth
              multiline
              minRows={4}
              value={values.notes}
              onChange={handleChange}
              name="notes"
              id="Notes"
            />
          </Grid>
          <div className={classes.btnsContainer}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              disabled={isSubmitting}
              startIcon={isSubmitting && <CircularProgress color="primary" size={25} />}
            >
              Create VS Visit
            </Button>
            <Button fullWidth variant="contained" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateVsVisit;
